import React, { useState, useEffect } from "react";
import "./Web.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";
import Loader from "../../Loader/Loader";

function Web() {
  const navigate = useNavigate();
  const [slider, setSlider] = useState([]);
  const [error, setError] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [transitionClass, setTransitionClass] = useState("");
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchSlider = async () => {
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readSlider`);
        setSlider(response.data);
      } catch (error) {
        console.error("Failed to fetch slider data.", error);
        setError("Failed to fetch slider data.");
      }
    };

    fetchSlider();
  }, []);

  useEffect(() => {
    const autoplay = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(autoplay);
  }, [currentSlide, slider]);

  const handlePrev = () => {
    if (slider.length > 1) {
      setTransitionClass("carousel-item-right");
      setTimeout(() => {
        setCurrentSlide((prev) => (prev === 0 ? slider.length - 1 : prev - 1));
        setTransitionClass("");
      }, 500);
    }
  };

  const handleNext = () => {
    if (slider.length > 1) {
      setTransitionClass("carousel-item-left");
      setTimeout(() => {
        setCurrentSlide((prev) => (prev === slider.length - 1 ? 0 : prev + 1));
        setTransitionClass("");
      }, 500);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="web-slider">
          <div id="carouselExample" className="carousel slide">
            <div className="carousel-inner">
              {error && <div>{error}</div>}
              {Array.isArray(slider) && !error && slider.length > 0 && (
                <>
                  {slider.map((slide, index) => (
                    <div
                      key={slide.id}
                      className={`carousel-item ${
                        index === currentSlide ? "active" : ""
                      } ${transitionClass}`}
                      style={{
                        // backgroundImage: `url(${slide.photo_url})`,
                        backgroundImage: `url(${
                          window.innerWidth <= 768
                            ? slide.photo_mobile_url
                            : slide.photo_url
                        })`,
                        backgroundRepeat: "no-repeat",
                        width: `${windowWidth}px`,
                        height: window.innerWidth <= 768 ? "340px" : "630px",
                      }}
                    >
                      {slide.heading_first &&
                      slide.heading_second &&
                      slide.button_text &&
                      slide.button_link ? (
                        <div className="slider-niva owl-carousel owl-loaded owl-drag">
                          <div className="owl-stage-outer">
                            <div className="owl-stage">
                              <div
                                className="owl-item active"
                                style={{ width: "100%", marginRight: "10px" }}
                              >
                                <div className="slider-inner-niva">
                                  <div className="container">
                                    <div
                                      className="row align-items-center justify-content-center"
                                      style={{ marginTop: "75px" }}
                                    >
                                      <div className="col-md-6 col-xl-5">
                                        <div className="slider-content">
                                          <h1>
                                            {slide.heading_first
                                              .split(" ")
                                              .slice(0, 2)
                                              .join(" ")}
                                            <span>
                                              {slide.heading_first
                                                .split(" ")
                                                .slice(2)
                                                .join(" ")}
                                            </span>
                                          </h1>
                                          <h2>{slide.heading_second}</h2>
                                        </div>
                                        {/* <a
                                          className="btn btn-slider"
                                          onClick={() =>
                                            window.open(
                                              slide.button_link,
                                              "_blank"
                                            )
                                          }
                                          id={`web-btn-${slide.id}`}
                                        >
                                          <span style={{ color: "white" }}>
                                            {slide.button_text}
                                          </span>
                                        </a> */}
                                        <a
                                          href={`${slide.button_link}`}
                                          className="btn btn-style1 "
                                          target=""
                                        >
                                          <span style={{ color: "white" }}>
                                            {slide.button_text}
                                          </span>
                                        </a>
                                      </div>
                                      <div className="col-md-6"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </>
              )}
            </div>

            <div className="owl-nav">
              <button
                type="button"
                role="presentation"
                className="owl-prev"
                onClick={handlePrev}
              >
                <i className="fas fa-angle-double-left"></i>
              </button>
              <button
                type="button"
                role="presentation"
                className="owl-next"
                onClick={handleNext}
              >
                <i className="fas fa-angle-double-right"></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Web;
