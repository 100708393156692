import React, { useState, useEffect } from "react";
import "./Blog.css";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";

function Blog() {
  const [blog, setBlog] = useState({});
  const [postData, setPostData] = useState({});
  const [loadingBlog, setLoadingBlog] = useState(false);
  const [errorBlog, setErrorBlog] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const BlogValues = async () => {
      setLoadingBlog(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readBlogId/1`);
        setBlog(response.data);
        setLoadingBlog(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorBlog("Failed to fetch default values.");
        setLoadingBlog(false);
      }
    };
    BlogValues();
  }, []);

  useEffect(() => {
    const postValues = async () => {
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readAllPosts`);

        setPostData(response.data);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
      }
    };

    postValues();
  }, []);

  const tableData = Array.isArray(postData) ? postData : [];

  const formattedDate = new Date(blog.created_at).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div>
      <div class="blog-section">
        <div class="container">
          <h3 class="blog-section-subtitle">{blog.subtitle}</h3>
          <h3 class="blog-section-title">{blog.title}</h3>

          <div className="row">
            {tableData.map((blog) => (
              <div className="col-md-4" key={blog.id}>
                <article className="blog-single-post">
                  <div className="blog_custom">
                    <div className="post-thumbnail">
                      <a className="relative" href={blog.link}>
                        {/* <div className="featured_image_blog">
                          <img
                            className="lazy blog_post_image img-fluid"
                            style={{ maxWidth: "350px", minHeight: "250px" }}
                            src={blog.photo_url}
                            alt={blog.title}
                          />
                          <div className="flex-icon">
                            <div className="flex-icon-inside">
                              <i className="fas fa-link"></i>
                            </div>
                          </div>
                        </div> */}
                        <div className="featured_image_blog aspect-ratio-container">
                          <img
                            className="lazy blog_post_image img-fluid"
                            src={blog.photo_url}
                            alt={blog.title}
                          />
                          <div className="flex-icon">
                            <div className="flex-icon-inside">
                              <i className="fas fa-link"></i>
                            </div>
                          </div>
                        </div>
                      </a>
                      <div
                        className="post-categories"
                        style={{ marginLeft: "22px" }}
                      >
                        <p>{blog.categories}</p>
                      </div>
                    </div>
                    <div className="post-details">
                      <div className="post-category-comment-date">
                        <span className="post-date">
                          <i className="far fa-clock"></i> {formattedDate}
                        </span>
                        <span className="post-author">
                          <i className="far fa-user"></i>
                          <a href="#0">Elisabeth Doe</a>
                        </span>
                      </div>
                      <h3 className="post-name">
                        <a href={blog.link}>{blog.title}</a>
                      </h3>
                      <div className="post-excerpt">
                        <p style={{ textWrap: "pretty" }}>
                          {blog.meta_description}
                        </p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Blog;
