import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

function CustomTemplate() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null));
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null));
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  useEffect(() => {
    const fetchDefaultValues = async () => {
      setLoading(true);
      try {
        // const token = localStorage.getItem("token");
        const response = await axios.get(
          `${ngrokUrl.url}/api/readCustomTemplate`
          // {
          //   headers: {
          //     Authorization: `Bearer ${token}`,
          //     "ngrok-skip-browser-warning": "69420",
          //   },
          // }
        );
        console.log("response", response);
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    fetchDefaultValues();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">All pages</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6
              className="m-0 font-weight-bold text-primary "
              style={{ fontSize: "large" }}
            >
              All pages
            </h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="row align-items-center mb-3">
                <div className="col-md-6 mt-3">
                  <Link to="/dashboard/createPage" className="dashboardBtn">
                    Create page
                  </Link>
                </div>
              </div>

              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th scope="col" className="header_heady">
                      Title
                    </th>
                    <th scope="col" className="header_heady">
                      Link
                    </th>
                    <th scope="col" className="header_heady">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th scope="col" className="header_heady">
                      Title
                    </th>
                    <th scope="col" className="header_heady">
                      Link
                    </th>
                    <th scope="col" className="header_heady">
                      Actions
                    </th>
                  </tr>
                </tfoot>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="3">Loading...</td>
                    </tr>
                  ) : error ? (
                    <tr>
                      <td colSpan="3">Error: {error}</td>
                    </tr>
                  ) : data.length === 0 ? (
                    <tr>
                      <td colSpan="3">No data found.</td>
                    </tr>
                  ) : (
                    Array.isArray(data) &&
                    data.map((item) => (
                      <tr key={item.id}>
                        <td className="page-title header_heady">
                          {item.title}{" "}
                        </td>
                        <td className="body-url" data-label="BODY">
                          <p>
                            <a
                              target="_blank"
                              href={item.link}
                              className="anchor_font"
                            >
                              {item.link}
                            </a>
                          </p>
                        </td>
                        <td className="body-url" data-label="BODY">
                          <Link
                            to={`/dashboard/customtemplate/${item.id}/edit?&language=en`}
                            className="action-button"
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomTemplate;
