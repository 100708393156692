import React from "react";
import { useState, useEffect } from "react";
import BlogArea from "../Components/Blog/BlogArea";
import NavBar from "../Utility/NavBar";
import Topbar from "../Topbar/Topbar";
import Whatsapp from "../Components/TopupButtons/Whatsapp";
import Loader from "../Components/Loader/Loader";

function Blog() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Topbar />
          <Whatsapp />
          <NavBar />
          <BlogArea />
        </>
      )}
    </div>
  );
}
export default Blog;
