import React from "react";
import { NavLink, Link, Outlet } from "react-router-dom";

function LogoSeo() {
  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Google Analytics</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <NavLink
              to="/dashboard/logoSeo"
              end
              className="m-0 font-weight-bold text-primary anchor_font mr-4"
            >
              Logo & Favicon
            </NavLink>
            <NavLink
              to="/dashboard/logoSeo/meta"
              className="m-0 font-weight-bold text-primary anchor_font"
            >
              SEO & META Keywords
            </NavLink>
          </div>
          <div className="card-body">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogoSeo;
