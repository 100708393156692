import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";

function ManageServices() {
  const [services, setServices] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 2000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 2000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  useEffect(() => {
    const servicesValues = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${ngrokUrl.url}/api/readSectionServices`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        setServices(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    servicesValues();
  }, []);

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      await axios.delete(`${ngrokUrl.url}/api/deleteSectionServices/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      });

      const response = await axios.get(
        `${ngrokUrl.url}/api/readSectionServices`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      setServices(response.data);
      setSuccess("Item deleted successfully.");
      setLoading(false);
    } catch (error) {
      console.error("Failed to delete item.", error);
      setError("Failed to delete item.");
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Section - Services</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              Section - Services
            </h6>
          </div>
          <div className="card-body mt-3">
            <div className="table-responsive">
              <div className="row">
                <div className="col-lg-6">
                  <Link
                    to="/dashboard/customtemplate/1/edit"
                    className="dashboardBtn"
                  >
                    Back to home
                  </Link>
                  <Link
                    to="/dashboard/createServices"
                    className="dashboardBtn ml-3"
                  >
                    Create service
                  </Link>
                </div>
              </div>

              <form className="form-inline">
                <input
                  type="hidden"
                  name="_token"
                  value="9kr4A0f0neruHdqd4e2UADuzDOw4VyxcaJSUzoO3"
                />{" "}
                <input type="hidden" name="_method" value="DELETE" />{" "}
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th className="header_heady">Photo</th>
                      <th className="header_heady">Box icon</th>
                      <th className="header_heady">Title</th>
                      <th className="header_heady">Description</th>
                      <th
                        scope="header_heady"
                        className="header_heady"
                        colSpan={2}
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th className="header_heady">Photo</th>
                      <th className="header_heady">Box icon</th>
                      <th className="header_heady">Title</th>
                      <th className="header_heady">Description</th>
                      <th
                        scope="header_heady"
                        className="header_heady"
                        colSpan={2}
                      >
                        Actions
                      </th>
                    </tr>
                  </tfoot>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="3">Loading...</td>
                      </tr>
                    ) : error ? (
                      <tr>
                        <td colSpan="3">Error: {error}</td>
                      </tr>
                    ) : services.length === 0 ? (
                      <tr>
                        <td colSpan="3">No data found.</td>
                      </tr>
                    ) : (
                      Array.isArray(services) &&
                      services.map((service) => (
                        <tr key={service.id}>
                          <td>
                            <img height="100" src={service.photo_url} alt="" />
                          </td>
                          <td data-label="link">
                            <i className={service.box_icon}></i>
                          </td>
                          <td data-label="link" className="label_dimension">
                            {service.title}
                          </td>
                          <td data-label="link" className="label_dimension">
                            {service.description}
                          </td>
                          <td className="body-url" data-label="BODY">
                            <Link
                              to={`/dashboard/editServices/${service.id}/edit?language=en`}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Link>

                            <FontAwesomeIcon
                              icon={faTrash}
                              className="action-button"
                              onClick={() => handleDelete(service.id)}
                              style={{ marginLeft: "25px" }}
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                {error && (
                  <div style={{ color: "red", fontSize: "14px" }}>{error}</div>
                )}
                {success && (
                  <div style={{ color: "green", fontSize: "14px" }}>
                    {success}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageServices;
