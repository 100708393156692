import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function CreateMenu() {
  const [menu, setMenu] = useState({
    name: "",
    link: "",
    order: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMenu((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  console.log("menu values", menu);

  const handlesubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(
        "https://6b4e-2406-7400-51-33a1-95fc-a3f0-5669-6a37.ngrok-free.app/api/saveMainmenu",
        menu,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("response from db", response);
      setSuccess("Menu created successfully.");
      e.target.reset();
    } catch (error) {
      console.log("failed to create menu", error);
      setLoading(false);
      setError("failed to create menu");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Create menu link</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              Create menu link
            </h6>
          </div>
          <div className="card-body">
            <Link
              to="/dashboard/websitenavigation"
              className="btn btn-primary btn-back anchor_font "
            >
              Back to Menu links
            </Link>

            <div className="row form-menu mt-4">
              <div className="col-md-12">
                <form onSubmit={handlesubmit}>
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group">
                        <strong className="header_heady">Name</strong>
                        <input
                          type="text"
                          name="name"
                          className="form-control label_dimension"
                          placeholder="Name"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <strong className="header_heady">Link</strong>
                        <input
                          type="text"
                          name="link"
                          className="form-control label_dimension"
                          placeholder="Link"
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group">
                        <strong className="header_heady">
                          Enable or disable sub-menu
                        </strong>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="on_off_submenu"
                            id="on_off_submenu1"
                            value="1"
                          />
                          <label
                            className="form-check-label"
                            for="on_off_submenu1"
                          >
                            {" "}
                            On{" "}
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="on_off_submenu"
                            id="on_off_submenu0"
                            value="0"
                          />
                          <label
                            className="form-check-label"
                            for="on_off_submenu0"
                          >
                            {" "}
                            Off{" "}
                          </label>
                        </div>
                      </div>

                      <div className="form-group submeniu-code hide">
                        <strong className="header_heady">HTML Submenu</strong>
                        <textarea
                          name="submenu"
                          className="form-control"
                          id="submenu"
                          rows="6"
                        ></textarea>
                        <div className="alert alert-success">
                          <strong className="header_heady">
                            HTML code - submenu example
                          </strong>
                          <pre>
                            <div className="dropdown-menu">
                              <a className="dropdown-item" href="#">
                                Submenu test
                              </a>
                              <a className="dropdown-item" href="#">
                                Submenu test
                              </a>
                              <a className="dropdown-item" href="#">
                                Submenu test
                              </a>
                            </div>
                          </pre>
                        </div>
                      </div>

                      <div className="form-group">
                        <strong className="header_heady">Order</strong>
                        <input
                          type="text"
                          name="order"
                          className="form-control label_dimension"
                          placeholder="Order"
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group">
                        <strong className="header_heady">
                          Select a language
                        </strong>
                        <select
                          name="language_id"
                          id="language_id"
                          className="form-control label_dimension input_height"
                        >
                          <option value="1">English</option>
                          <option value="2">Portuguese</option>
                          <option value="3">عربى</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary anchor_font"
                        >
                          Create
                        </button>
                      )}
                    </div>
                  </div>
                </form>
                {error && <p style={{ color: "red" }}>Error: {error}</p>}
                {success && (
                  <p style={{ color: "green" }}>Success: {success}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateMenu;
