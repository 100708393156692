import React, { useEffect, useState } from "react";
import TopArea from "../Components/AboutUs/TopArea/TopArea";
import Members from "../Components/AboutUs/Members/Members";
import Footer from "../Components/Home/Footer/Footer";
import Client from "../Components/AboutUs/ClientTestimonialSection/Client";
import Testimonial from "../Components/Home/Testimonial/Testimonial";
import NavBar from "../Utility/NavBar";
import Topbar from "../Topbar/Topbar";
import Whatsapp from "../Components/TopupButtons/Whatsapp";
import OurMission from "../Components/AboutUs/OurMission/OurMission";
import Loader from "../Components/Loader/Loader";

function AboutUs() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    });
    return () => clearTimeout(timer);
  }, []);
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Topbar />
          <NavBar />
          <TopArea />
          <OurMission />
          <Members />
          <Testimonial />

          <Client />
          <Footer />
          <Whatsapp />
        </>
      )}
    </div>
  );
}
export default AboutUs;
