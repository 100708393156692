import React from "react";
import { useParams } from "react-router-dom";
import { routes2 } from "../../../Utility/data";

const NavigationDynamicRoutes = () => {
  const { id } = useParams();

  const Component = routes2[id];
  return Component ? <Component /> : <div>Page Not Found</div>;
};

export default NavigationDynamicRoutes;
