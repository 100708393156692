import React, { useState, useEffect } from "react";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import "../Pages/EditPage/editpage.css";

function Favicon() {
  const [faviconData, setFaviconData] = useState({
    main_logo: null,
    footer_logo: null,
    favicon: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const fetchDefaultValues = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readLogo/1`);
        setFaviconData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    fetchDefaultValues();
  }, []);

  const handleChange = (e) => {
    const { name, type, files } = e.target;
    if (type === "file" && files && files[0]) {
      const file = files[0];
      setFaviconData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("main_logo", faviconData.main_logo);
      formData.append("footer_logo", faviconData.footer_logo);
      formData.append("favicon", faviconData.favicon);

      const response = await axios.post(
        `${ngrokUrl.url}/api/updateLogo/1`,
        formData,
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("favicon in handlesubmit", response.data);

      setSuccess("Updated successfully.");
    } catch (error) {
      console.error("Failed to update website settings.", error);
      setError("Failed to update website settings.");
    } finally {
      setLoading(false);
    }
  };

  console.log("favicondata", faviconData);

  return (
    <>
      <form onSubmit={handlesubmit}>
        <div className="d-flex justify-content-between">
          <div>
            <p className="header_heady">Main Logo</p>
            <div className="outer_div">
              <div>
                <img
                  className="inter_div"
                  src={faviconData.main_logo_url}
                  style={{ boxSizing: "border-box" }}
                />
                <input
                  type="file"
                  name="main_logo"
                  id=""
                  className="fily_file"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div>
            <p className="header_heady">Footer Logo</p>
            <div className="outer_div">
              <div>
                <img
                  className="inter_div"
                  src={faviconData.footer_logo_url}
                  style={{ boxSizing: "border-box" }}
                />
                <input
                  type="file"
                  name="footer_logo"
                  id=""
                  className="fily_file"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div>
            <p className="header_heady">Favicon</p>
            <div className="outer_div">
              <div>
                <img
                  className="inter_div"
                  src={faviconData.favicon_url}
                  style={{ boxSizing: "border-box" }}
                />
                <input
                  type="file"
                  name="favicon"
                  id=""
                  className="fily_file"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 text-right pr-0 mt-4">
          <button type="submit" className="cardBtn">
            Update
          </button>
        </div>
      </form>
      {error && <p style={{ color: "red" }}>Error: {error}</p>}
      {success && <p style={{ color: "green" }}>Success: {success}</p>}
    </>
  );
}

export default Favicon;
