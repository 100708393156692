import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";

function WebsiteTracking() {
  const [TrackingData, setTrackingData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const navigate = useNavigate();
  const [google, setGoogle] = useState("id");

  const handleCheck = (e) => {
    setGoogle(e.target.value);
    if (google === "id") {
      navigate("/dashboard/websiteTracking/googleCode");
    } else {
      navigate("/dashboard/websiteTracking");
    }
  };

  const trackvalues = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${ngrokUrl.url}/api/readWebsiteTracking/5`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      setTrackingData(response.data);
    } catch (error) {
      console.error("Failed to fetch default values.", error);
      setError("Failed to fetch default values.");
    }
  };

  useEffect(() => {
    trackvalues();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Website Tracking</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              Website Tracking
            </h6>
          </div>
          <div className="card-body">
            <div className="d-flex gap-5">
              <div className="form-check">
                <input
                  className="form-check-input roundy_round"
                  type="radio"
                  name="google_tag_id"
                  id="id"
                  value="id"
                  checked={google === "id"}
                  onChange={handleCheck}
                />
                <label
                  className="form-check-label label_dimension ml-2"
                  htmlFor="flexRadioDefault2"
                >
                  Google tag ID
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input roundy_round"
                  type="radio"
                  name="google_tag_code"
                  id="code"
                  value="code"
                  checked={google === "code"}
                  onChange={handleCheck}
                />
                <label
                  className="form-check-label label_dimension ml-2"
                  htmlFor="flexRadioDefault1"
                >
                  Google tag code
                </label>
              </div>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default WebsiteTracking;
