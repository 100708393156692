import React from "react";
import { Link } from "react-router-dom";
function GoogleId() {
  return (
    <div className="card-body" style={{ paddingTop: "0" }}>
      <p className="mb-4 mt-4 label_dimension">Google Tag ID</p>
      <input
        type="text"
        className="form-control label_dimension"
        value="G-76NX8HY29D"
      />
      <Link className="linky_link anchor_font">
        https://support.google.com/analytics/answer/9539598#find-G-ID
      </Link>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 text-right">
          <button type="submit" className="btn btn-primary anchor_font">
            Save Settings
          </button>
        </div>
      </div>
    </div>
  );
}

export default GoogleId;
