import React, { useState, useEffect } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader/Loader";
import axios from "axios";
import { ngrokUrl } from "../Utility/data";

function Login() {
  const [credentials, setCredentials] = useState({
    email: "contact@lucian.host",
    password: "123456",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    });
    return () => clearTimeout(timer);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;

    try {
      const response = await axios.post(`${ngrokUrl.url}/api/login`, {
        email: email,
        password: password,
      });

      localStorage.setItem("token", response.data.access_token);
      sessionStorage.setItem("token", response.data.access_token);

      setSuccess("Login successful! Redirecting to dashboard...");
      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError("Login failed. Please check your credentials and try again.");
      }
    }
  };

  const backgroundstyleimage = {
    backgroundImage: `url(https://images.unsplash.com/photo-1617042375876-a13e36732a04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1170&amp;q=80)`,
    backgroundSize: "cover",
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="bg-light" style={{ height: "100dvh" }}>
            <div
              className="mx-auto "
              style={{ height: "100%", width: "980px" }}
            >
              {/* <!-- Outer Row --> */}
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                  <div
                    className="card o-hidden border-0 shadow-lg my-5"
                    style={{ height: "520px" }}
                  >
                    <div className="card-body p-0">
                      {/* <!-- Nested Row within Card Body --> */}
                      <div className="row" style={{ height: "100% " }}>
                        <div
                          className="col-lg-6 d-none d-lg-block bg-login-image"
                          style={backgroundstyleimage}
                        ></div>
                        <div className="col-lg-6">
                          <div className="p-5" style={{ height: "100%" }}>
                            <div className="text-center">
                              <h1 className="h4 text-gray-900 mb-4">
                                Welcome Back!
                              </h1>
                            </div>
                            <form
                              // method="POST"
                              className="user"
                              onSubmit={handleSubmit}
                            >
                              <input
                                type="hidden"
                                name="_token"
                                value="dr8fTzqOL3A3engc9hxALtrauA6fAzf34NlsUxmM"
                              />{" "}
                              <div className="form-group">
                                <input
                                  id="email"
                                  type="email"
                                  className="form-control form-control-user "
                                  name="email"
                                  value={credentials.email}
                                  required=""
                                  autocomplete="email"
                                  aria-describedby="emailHelp"
                                  placeholder="Enter Email Address..."
                                  autofocus=""
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  id="password"
                                  placeholder="Password"
                                  type="password"
                                  className="form-control form-control-user "
                                  name="password"
                                  value={credentials.password}
                                  required=""
                                  autocomplete="current-password"
                                  onChange={handleChange}
                                />
                              </div>
                              {/* <div className="form-group my-4"> */}
                              <div className="custom-control  remember-checkbox">
                                <input
                                  className="mt-0"
                                  type="checkbox"
                                  name="remember"
                                  id="remember"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="remember"
                                >
                                  Remember Me
                                </label>
                              </div>
                              {/* </div> */}
                              <button
                                type="submit"
                                className="btn loginButton btn-primary btn-user btn-block mb-4"
                                style={{ backgroundColor: "#4e73df" }}
                              >
                                Login
                              </button>
                              <hr />
                            </form>
                            {error && (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {error}
                              </div>
                            )}
                            {success && (
                              <div style={{ color: "green", fontSize: "14px" }}>
                                {success}
                              </div>
                            )}
                            <hr />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Login;
