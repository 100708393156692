import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

function ManageSlider() {
  const [slider, setSlider] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 2000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 2000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  useEffect(() => {
    const sliderValues = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${ngrokUrl.url}/api/readSlider`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        });
        setSlider(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    sliderValues();
  }, []);

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      await axios.delete(`${ngrokUrl.url}/api/deleteSlider/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      });

      const response = await axios.get(`${ngrokUrl.url}/api/readSlider`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      });
      setSlider(response.data);
      setSuccess("slider deleted successfully.");
      setLoading(false);
      // window.location.reload();
    } catch (error) {
      console.error("Failed to delete selected items.", error);
      setError("Failed to delete selected items.");
      setLoading(false);
    }
  };

  useEffect(() => {});

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">All sliders</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              All sliders
            </h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="row align-items-center mb-3">
                <div className="col-md-6 mt-3">
                  <Link
                    to="/dashboard/customtemplate/1/edit"
                    className="dashboardBtn "
                  >
                    Back to Home page
                  </Link>
                  <Link
                    to="/dashboard/createSlider"
                    className="dashboardBtn ml-4"
                  >
                    Create slider
                  </Link>
                </div>
              </div>

              <form>
                <input
                  type="hidden"
                  name="_token"
                  value="9kr4A0f0neruHdqd4e2UADuzDOw4VyxcaJSUzoO3"
                />{" "}
                <input type="hidden" name="_method" value="DELETE" />{" "}
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th className="header_heady" style={{ width: "50px" }}>
                        Desktop View
                      </th>
                      <th className="header_heady" style={{ width: "50px" }}>
                        mobile View
                      </th>
                      <th className="header_heady">Heading 1</th>
                      <th className="header_heady">Heading 2</th>
                      <th className="header_heady">Button text</th>
                      <th className="header_heady">Button link</th>
                      <th scope="col" className="header_heady" colSpan={2}>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th className="header_heady" style={{ width: "50px" }}>
                        Desktop View
                      </th>
                      <th className="header_heady" style={{ width: "50px" }}>
                        mobile View
                      </th>
                      <th className="header_heady">Heading 1</th>
                      <th className="header_heady">Heading 2</th>
                      <th className="header_heady">Button text</th>
                      <th className="header_heady">Button link</th>
                      <th scope="col" className="header_heady" colSpan={2}>
                        Actions
                      </th>
                    </tr>
                  </tfoot>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="3">Loading...</td>
                      </tr>
                    ) : error ? (
                      <tr>
                        <td colSpan="3">Error: {error}</td>
                      </tr>
                    ) : slider.length === 0 ? (
                      <tr>
                        <td colSpan="3">No data found.</td>
                      </tr>
                    ) : (
                      Array.isArray(slider) &&
                      slider.map((slider) => (
                        <tr key={slider.id}>
                          <td style={{ width: "50px" }}>
                            <img
                              height="100"
                              src={slider.photo_url}
                              alt="Slide"
                              style={{ width: "200px" }}
                            />
                          </td>
                          <td style={{ width: "50px" }}>
                            <img
                              height="100"
                              src={slider.photo_mobile_url}
                              alt="Slide"
                              style={{ width: "200px" }}
                            />
                          </td>
                          <td data-label="link" className="label_dimension">
                            {slider.heading_first}
                          </td>
                          <td data-label="link" className="label_dimension">
                            {slider.heading_second}
                          </td>
                          <td data-label="link" className="label_dimension">
                            {slider.button_text}
                          </td>
                          <td data-label="link" className="label_dimension">
                            {slider.button_link}
                          </td>
                          <td className="body-url" data-label="BODY">
                            <Link
                              to={`/dashboard/editslider/${slider.id}/edit?language=en`}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Link>

                            <FontAwesomeIcon
                              icon={faTrash}
                              className="action-button"
                              onClick={() => handleDelete(slider.id)}
                              style={{ marginLeft: "25px" }}
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                {error && (
                  <div style={{ color: "red", fontSize: "15px" }}>{error}</div>
                )}
                {success && (
                  <div style={{ color: "green", fontSize: "15px" }}>
                    {success}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageSlider;
