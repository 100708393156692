import React, { useState, useEffect } from "react";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";

function WebisteHeaderFooter() {
  const [headerFooter, setHeaderFooter] = useState({
    title_contactus: " ",
    header_desc: " ",
    typed_title: "",
    typed_text: " ",
    button_text: "  ",
    button_link: "",
    subtitle: " ",
    title: " ",
    button_text_contact: " ",
    button_link_fot: " ",
    title_quicklinks: " ",
    title_sayhello: "",
    desc_foot: " ",
    desc_footer: " ",
    copyright: " ",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const HeaderFooterData = async () => {
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readHeaderFooter/3`
        );

        const data = await response.data;

        setHeaderFooter(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    HeaderFooterData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHeaderFooter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateHeaderFooter/3`,
        headerFooter,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setHeaderFooter(response.data);
      setSuccess("updated successfully.");
      window.location.reload();
    } catch (error) {
      console.log("failed to  update HeaderFooter form");
      setError("failed to  update HeaderFooter form");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Header Footer settings</h1>

        <div className="row">
          <div className="col-md-12">
            {/* <!-- Typed text section --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  Footer Typed text section
                </h6>
              </div>
              <div className="card-body">
                <form
                  // action="https://niva4.lucian.host/admin/header-footer-settings/1/update"
                  // method="POST"
                  // enctype="multipart/form-data"
                  onSubmit={handlesubmit}
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Typed title</strong>
                        <input
                          type="text"
                          name="typed_title"
                          className="form-control label_dimension"
                          value={headerFooter.typed_title}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Typed text</strong>
                        <input
                          type="text"
                          name="typed_text"
                          className="form-control label_dimension"
                          // value="[&#039;Web Design?&#039;, &#039;Social Media?&#039;, &#039;Print Design?&#039;, &#039;Digital Design?&#039;, &#039;Print Design?&#039;]"
                          value={headerFooter.typed_text}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Button text</strong>
                        <input
                          type="text"
                          name="button_text"
                          className="form-control label_dimension"
                          value={headerFooter.button_text}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Button link</strong>
                        <input
                          type="text"
                          name="button_link"
                          className="form-control label_dimension"
                          // value="https://niva4.lucian.host/contact"
                          value={headerFooter.button_link}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary anchor_font"
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </form>

                {error && <p style={{ color: "red" }}>Error: {error}</p>}
                {success && (
                  <p style={{ color: "green" }}>Success: {success}</p>
                )}
              </div>
            </div>
            {/* <!-- Typed text section --> */}

            {/* <!-- Footer column 1 --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  Footer column 1
                </h6>
              </div>
              <div className="card-body">
                <form
                  // action="https://niva4.lucian.host/admin/header-footer-settings/1/update"
                  // method="POST"
                  // enctype="multipart/form-data"
                  onSubmit={handlesubmit}
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Subtitle</strong>
                        <input
                          type="text"
                          name="subtitle"
                          className="form-control label_dimension"
                          // value="READY TO DO THIS"
                          value={headerFooter.subtitle}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Title</strong>
                        <input
                          type="text"
                          name="title_contactus"
                          className="form-control label_dimension"
                          value={headerFooter.title_contactus}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Button text</strong>
                        <input
                          type="text"
                          name="button_text_contact"
                          className="form-control label_dimension"
                          value={headerFooter.button_text_contact}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Button link</strong>
                        <input
                          type="text"
                          name="button_link"
                          className="form-control label_dimension"
                          // value="https://niva4.lucian.host/contact"
                          value={headerFooter.button_link}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary anchor_font"
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </form>
                {error && <p style={{ color: "red" }}>Error: {error}</p>}
                {success && (
                  <p style={{ color: "green" }}>Success: {success}</p>
                )}
              </div>
            </div>
            {/* <!-- Footer column 1 --> */}

            {/* <!-- Footer column 2 --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  Footer column 2
                </h6>
              </div>
              <div className="card-body">
                <form
                  // action="https://niva4.lucian.host/admin/header-footer-settings/1/update"
                  // method="POST"
                  // enctype="multipart/form-data"
                  onSubmit={handlesubmit}
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Title</strong>
                        <input
                          type="text"
                          name="title_quicklinks"
                          className="form-control label_dimension"
                          value={headerFooter.title_quicklinks}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Title</strong>
                        <input
                          type="text"
                          name="title_sayhello"
                          className="form-control label_dimension"
                          value={headerFooter.title_sayhello}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Description</strong>
                        <textarea
                          name="desc_foot"
                          className="form-control label_dimension"
                          rows="6"
                          value={headerFooter.desc_foot}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Description</strong>
                        <textarea
                          name="desc_footer"
                          className="form-control label_dimension"
                          rows="6"
                          value={headerFooter.desc_footer}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary anchor_font"
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </form>
                {error && <p style={{ color: "red" }}>Error: {error}</p>}
                {success && (
                  <p style={{ color: "green" }}>Success: {success}</p>
                )}
              </div>
            </div>
            {/* <!-- Footer column 2 --> */}

            {/* <!-- Copyright text --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  Copyright text
                </h6>
              </div>
              <div className="card-body">
                <form onSubmit={handlesubmit}>
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="form-group">
                    <strong className="label_dimension">Copyright text</strong>
                    <textarea
                      name="copyright"
                      className="form-control label_dimension"
                      rows="6"
                      value={headerFooter.copyright}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary anchor_font"
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </form>
                {error && <p style={{ color: "red" }}>Error: {error}</p>}
                {success && (
                  <p style={{ color: "green" }}>Success: {success}</p>
                )}
              </div>
            </div>
            {/* <!-- Copyright text --> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebisteHeaderFooter;
