import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import { useNavigate } from "react-router-dom";

function CreateTestimonials() {
  const [formData, setFormData] = useState({
    name: "",
    position: "",
    subtitle: "",
    title: "",
    desc: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 3000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 3000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${ngrokUrl.url}/api/saveTestimonial`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setSuccess("Testimonial added successfully!");
        setLoading(false);
        setFormData({
          name: "",
          position: "",
          subtitle: "",
          title: "",
          desc: "",
        });
      } else {
        throw new Error("Failed to create testimonials");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to create page");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        navigate("/dashboard/managetestimonials");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [success]);

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Create testimonial</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              Create testimonial
            </h6>
          </div>
          <div className="card-body mt-3">
            <Link
              to="/dashboard/managetestimonials"
              href="https://niva4.lucian.host/admin/testimonial?language=en"
              className="dashboardBtn"
            >
              Back to testimonials
            </Link>

            <div className="row">
              <div className="col-md-12">
                <form
                  // action="https://niva4.lucian.host/admin/testimonial"
                  // method="POST"
                  // enctype="multipart/form-data"
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />
                  <div className="row mt-4">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="header_heady">Name</strong>
                            <input
                              type="text"
                              name="name"
                              className="form-control label_dimension"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="header_heady">Position</strong>
                            <input
                              type="text"
                              name="position"
                              className="form-control label_dimension"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="header_heady">Subtitle</strong>
                            <input
                              type="text"
                              name="subtitle"
                              className="form-control label_dimension"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="header_heady">Title</strong>
                            <input
                              type="text"
                              name="title"
                              className="form-control label_dimension"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                      {/* <div className="form-group">
                        <strong className="header_heady">
                          Select a language
                        </strong>
                        <select
                          name="language_id"
                          id="language_id"
                          className="form-control label_dimension input_height"
                        >
                          <option value="1">English</option>
                          <option value="2">Portuguese</option>
                          <option value="3">عربى</option>
                        </select>
                      </div> */}

                      <div className="form-group">
                        <strong className="header_heady">Description</strong>
                        <textarea
                          name="desc"
                          className="form-control label_dimension"
                          rows="6"
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <button type="submit" className="cardBtn">
                          Create Testimonials
                        </button>
                      )}
                    </div>
                  </div>
                </form>
                {error && (
                  <div style={{ color: "red", fontSize: "14px" }}>{error}</div>
                )}
                {success && (
                  <div style={{ color: "green", fontSize: "14px" }}>
                    {success}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateTestimonials;
