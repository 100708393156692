import { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Sidebar.css";
// import google from "../../Images/google-adense.png";
import "../../Utility/NavBar.css";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";

export default function Sidebar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loadingFormInfo, setLoadingFormInfo] = useState(false);
  const [errorFormInfo, setErrorFormInfo] = useState(null);
  const [formInfo, setFormInfo] = useState({
    name: "",
    email: "",
    phone_number: "",
    budget: "",
    message: "",
  });

  const details = formInfo.box_desc_one
    ? formInfo.box_desc_one.split(/[,\n]/).map((detail) => detail.trim())
    : [];

  const emails = formInfo.box_desc_two
    ? formInfo.box_desc_two.split(/[,\n]/).map((email) => email.trim())
    : [];

  useEffect(() => {
    const FormInformation = async () => {
      setLoadingFormInfo(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readContactUs/1`);
        setFormInfo(response.data);
        setLoadingFormInfo(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorFormInfo("Failed to fetch default values.");
        setLoadingFormInfo(false);
      }
    };
    FormInformation();
  }, []);

  return (
    <>
      <div
        variant="primary"
        onClick={handleShow}
        className="me-2"
        style={{ backgroundColor: "none" }}
      >
        <div class="side_panel_sidebar_parent">
          <div class="side_panel_sidebar">
            <span class="side_panel_toggle">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <h5 style={{ textAlign: "center" }} class="widget-title">
            Contact Us
          </h5>

          <p className="num">
            {details.map((detail, index) => (
              <p key={index}>
                <img
                  width="48"
                  height="48"
                  src="https://img.icons8.com/nolan/64/apple-phone.png"
                  alt="apple-phone"
                />
                <a className="pl-4 textHover" href={`mailto:${detail}`}>
                  {detail}
                </a>
              </p>
            ))}
          </p>
          <p className="num">
            {emails.map((email, index) => (
              <p key={index}>
                <img
                  width="48"
                  height="48"
                  src="https://img.icons8.com/nolan/64/new-post.png"
                  alt="new-post"
                />
                <a className="pl-4" href={`mailto:${email}`}>
                  {email}
                </a>
              </p>
            ))}
          </p>

          <p className="num">
            <p style={{ display: "flex", gap: "2rem" }}>
              <img
                width="48"
                height="48"
                src="https://img.icons8.com/nolan/64/address.png"
                alt="address"
              />
              <a href="">{formInfo.box_desc_three}</a>
            </p>
          </p>

          <div className="social-link12">
            <a
              href="https://www.instagram.com/yrpitsolutions?igsh=MW95ZzV1YW1mdWN3Yg=="
              target="_blank"
              rel="noopener"
            >
              <img
                width="48"
                height="48"
                src="https://img.icons8.com/3d-fluency/94/instagram-new.png"
                alt="instagram-new"
              />
            </a>
            <a
              className="textHover"
              href="https://in.linkedin.com/company/ypritsolutions"
              target="_blank"
              rel="noopener"
              style={{ marginLeft: "18px" }}
            >
              <img
                width="48"
                height="48"
                src="https://img.icons8.com/color/48/linkedin.png"
                alt="linkedin"
              />
            </a>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
