import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import "../Pages/EditPage/editpage.css";
import { useNavigate } from "react-router-dom";

function CreateProject() {
  const [projectData, setProjectData] = useState({
    body: "",
    button_link: "",
    button_text: "",
    categories: "",
    client: "",
    duration_project: "",
    link: "",
    meta_description: "",
    meta_title: "",
    photo_file: null,
    photo_url: "",
    photo_four: "",
    photo_one: "",
    photo_three: "",
    photo_two: "",
    title: "",
  });
  const [categories, setCategories] = useState({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 3000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 3000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setProjectData({
      ...projectData,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const formDataObj = new FormData();
      formDataObj.append("body", projectData.body);
      formDataObj.append("title", projectData.title);
      formDataObj.append("button_link", projectData.button_link);
      formDataObj.append("button_text", projectData.button_text);
      formDataObj.append("categories", projectData.categories);
      formDataObj.append("client", projectData.client);
      formDataObj.append("duration_project", projectData.duration_project);
      formDataObj.append("link", projectData.link);
      formDataObj.append("meta_description", projectData.meta_description);
      formDataObj.append("meta_title", projectData.meta_title);
      formDataObj.append("photo_four", projectData.photo_four);
      formDataObj.append("photo_three", projectData.photo_three);
      formDataObj.append("photo_two", projectData.photo_two);
      formDataObj.append("photo_one", projectData.photo_one);
      if (projectData.photo_url) {
        formDataObj.append("photo_url", projectData.photo_url);
      } else {
        if (typeof projectData.photo_file !== "string") {
          formDataObj.append("photo_file", projectData.photo_file);
        }
      }

      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${ngrokUrl.url}/api/saveSectionProject`,
        formDataObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setSuccess("Project Create Successfully!");
      setLoading(false);

      setProjectData(response.data);
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to create page");
      setLoading(false);
    }
  };

  console.log("project", projectData);

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        navigate("/dashboard/all-project");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [success]);

  useEffect(() => {
    const categoriesValues = async () => {
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readCategories`);

        setCategories(response.data);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
      }
    };

    categoriesValues();
  }, []);
  const tableData = Array.isArray(categories) ? categories : [];

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Create project</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6
              className="m-0 font-weight-bold text-primary"
              style={{ fontSize: "large" }}
            >
              Create project
            </h6>
          </div>
          <div className="card-body mt-3">
            <div className="row mb-4">
              <div className="col-md-6">
                <Link to="/dashboard/all-project" className="dashboardBtn">
                  Back to Project page
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <form
                  // action="https://niva4.lucian.host/admin/project"
                  // method="POST"
                  // enctype="multipart/form-data"
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="aSLHPav3ag6Vh5WENIifMMlE2BKxxgIYhqvWs11V"
                  />
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <input type="hidden" name="language_id" value="1" />

                      <div className="row">
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">Title</strong>
                            <input
                              type="text"
                              name="title"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">Link</strong>
                            <div className="slug-container  d-flex flex-column flex-lg-row">
                              <span className="label_dimension">
                                https://niva-website.vercel.app/
                              </span>
                              <input
                                type="text"
                                name="link"
                                className="formControl label_dimension mobile_width w-100"
                                placeholder=""
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">Photo</strong>
                            <input
                              type="file"
                              name="photo_file"
                              className="formControl-file label_dimension input_height"
                              // id="photo_id"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">
                              Photo{" "}
                              <span>
                                Upload image{" "}
                                <a
                                  target="_blank"
                                  href="https://niva4.lucian.host/admin/media/create"
                                >
                                  {" "}
                                  here{" "}
                                </a>{" "}
                                then copy the URL from{" "}
                                <a
                                  target="_blank"
                                  href="https://niva4.lucian.host/admin/media"
                                >
                                  {" "}
                                  here{" "}
                                </a>
                              </span>
                            </strong>
                            <input
                              type="text"
                              name="photo_url"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="formgroup">
                        <strong className="header_heady">Categories</strong>
                        <select
                          name="categories"
                          id="project_category_id"
                          className="form-control label_dimension input_height"
                          onChange={handleChange}
                          value={categories.categories}
                        >
                          <option>Choose Category</option>
                          {tableData.map((category) => (
                            <option
                              key={category.categories}
                              value={category.categories}
                            >
                              {category.categories}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="formgroup">
                        <strong className="header_heady">Body</strong>
                        <textarea
                          name="body"
                          className="formControl"
                          id="body"
                          rows="5"
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">Photo 1</strong>
                            <input
                              type="text"
                              name="photo_one"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">Photo 2</strong>
                            <input
                              type="text"
                              name="photo_two"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">Photo 3 </strong>
                            <input
                              type="text"
                              name="photo_three"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">Photo4 </strong>
                            <input
                              type="text"
                              name="photo_four"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">
                              Duration project
                            </strong>
                            <input
                              type="text"
                              name="duration_project"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">Client</strong>
                            <input
                              type="text"
                              name="client"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">
                              Button text
                            </strong>
                            <input
                              type="text"
                              name="button_text"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">
                              Button link
                            </strong>
                            <input
                              type="text"
                              name="button_link"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">meta title</strong>
                            <input
                              type="text"
                              name="meta_title"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">
                              meta description
                            </strong>
                            <input
                              type="text"
                              name="meta_description"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <button type="submit" className="cardBtn">
                          Create
                        </button>
                      )}
                    </div>
                  </div>
                </form>
                {error && (
                  <div style={{ color: "red", fontSize: "14px" }}>{error}</div>
                )}
                {success && (
                  <div style={{ color: "green", fontSize: "14px" }}>
                    {success}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateProject;
