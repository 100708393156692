import React, { useState, useEffect } from "react";
import "../../Pages/EditPage/editpage.css";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const EditTestimonial = () => {
  const navigate = useNavigate();
  const [testimonial, setTestimonial] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 3000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 3000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  const val = useParams();
  const id = val.id;

  useEffect(() => {
    const TestimonialValues = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${ngrokUrl.url}/api/readTestimonialId/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        setTestimonial(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    if (id) {
      TestimonialValues();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTestimonial({
      ...testimonial,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateTestimonial/${id}`,
        testimonial,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setTestimonial(response.data);
        setSuccess("testimonials  updated successfully!");
        setLoading(false);
      } else {
        throw new Error("Failed to updated testimonials");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to update testimonials");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        navigate("/dashboard/managetestimonials");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [success]);

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Edit testimonial</h1>
      <div className="card shadow  templete mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            Edit testimonial
          </h6>
        </div>
        <a
          href="/dashboard/managetestimonials?language=en"
          className="btn-back cardBtn m-4 mb-2"
          style={{ width: "182px" }}
        >
          Back to testimonials
        </a>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="bQa1IU2NI1tyRO907URYzQIyqDY6fe83pMqncSHh"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Name</h5>
                          <input
                            type="text"
                            name="name"
                            className="formControl"
                            value={testimonial.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Position</h5>
                          <input
                            type="text"
                            name="position"
                            className="formControl"
                            value={testimonial.position}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Subtitle</h5>
                          <input
                            type="text"
                            name="subtitle"
                            className="formControl"
                            value={testimonial.subtitle}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Title</h5>
                          <input
                            type="text"
                            name="title"
                            className="formControl"
                            value={testimonial.title}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="formgroup">
                      <h5>Description</h5>
                      <textarea
                        name="desc"
                        className="formControl"
                        rows="6"
                        value={testimonial.desc}
                        onChange={handleChange}
                      >
                        {/* It’s the perfect solution for our business. Niva is the
                        most valuable business resource we have EVER purchased.
                        We’ve seen amazing results already.{" "} */}
                      </textarea>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button type="submit" className="cardBtn">
                      Update
                    </button>
                  </div>
                </div>
              </form>
              {error && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {error}
                </div>
              )}
              {success && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {success}{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTestimonial;
