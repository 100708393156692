import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import "../Pages/EditPage/editpage.css";
import { useNavigate } from "react-router-dom";

function Upload() {
  const [media, setMedia] = useState({
    name: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setMedia({
      ...media,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${ngrokUrl.url}/api/saveAllMedia`,
        media,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setSuccess("Image added successfully!");
        setLoading(false);
      } else {
        throw new Error("Failed to add Image");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to add Image");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        navigate("/dashboard/allMedia");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [success]);

  console.log("media", media);
  return (
    <div>
      <div class="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 class="h3 mb-2 text-gray-800">Upload image</h1>

        {/* <!-- DataTales Example --> */}
        <div class="card shadow mb-4">
          <div class="card-header py-3"></div>
          <a className="btn-back cardBtn ml-3  mt-3" href="/dashboard/allMedia">
            Back to Media
          </a>
          <div class="card-body">
            <div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="formgroup">
                      <strong className="header_heady">Photo</strong>
                      <input
                        type="file"
                        name="name"
                        accept=".jpeg, .jpg, .png, .gif, .webp"
                        className="formControl-file label_dimension input_height"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: "16rem" }}>
                    <p className="label_dimension ml-3">
                      AcceptedFiles: .jpeg, .jpg, .png, .gif, .webp
                    </p>
                    <button type="submit" className="cardBtn">
                      Create
                    </button>
                  </div>
                </div>
              </form>
              {error && (
                <div style={{ color: "red", fontSize: "14px" }}>{error}</div>
              )}
              {success && (
                <div style={{ color: "green", fontSize: "14px" }}>
                  {success}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Upload;
