import React from "react";
import "../../Pages/EditPage/editpage.css";

const EditPricing = () => {
  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Edit pricing</h1>
      <div className="card shadow mb-4">
        <div className="card-header templete py-3">
          <h6 className="m-0 font-weight-bold text-primary">Edit pricing</h6>
        </div>
        <a
          href="https://niva4.lucian.host/admin/pricing?language=en"
          className="btn-back cardBtn m-4 mb-2"
        >
          Back to Pricing page
        </a>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <form method="POST" enctype="multipart/form-data">
                <input
                  type="hidden"
                  name="_token"
                  value="bQa1IU2NI1tyRO907URYzQIyqDY6fe83pMqncSHh"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="formgroup">
                      <h5>Title</h5>
                      <input
                        type="text"
                        name="title"
                        className="formControl"
                        value="<h3><h5>Advanced Plan</h5> <span>No coding skills required to create unique sites. Customize your site in real-time and see the results instantly.</span></h3>"
                      />
                    </div>
                    <div className="formgroup">
                      <h5>Description</h5>
                      <textarea
                        name="description"
                        className="formControl"
                        id="description"
                        rows="6"
                      >
                        &lt;ul&gt; &lt;li&gt;&lt;h5&gt;10GB&lt;/h5&gt; Disk
                        Space&lt;/li&gt; &lt;li&gt;&lt;h5&gt;100GB&lt;/h5&gt;
                        Monthly Bandwith&lt;/li&gt;
                        &lt;li&gt;&lt;h5&gt;20&lt;/h5&gt; Email
                        Accounts&lt;/li&gt; &lt;li&gt;Unlimited
                        Subdomains&lt;/li&gt; &lt;/ul&gt;
                      </textarea>
                    </div>
                    <div className="formgroup">
                      <h5>Button text</h5>
                      <input
                        type="text"
                        name="button_text"
                        className="formControl"
                        value="Get the offer"
                      />
                    </div>
                    <div className="formgroup">
                      <h5>Button link</h5>
                      <input
                        type="text"
                        name="button_link"
                        className="formControl"
                        value="https://niva4.lucian.host/contact"
                      />
                    </div>

                    <div className="formgroup">
                      <h5>Is featured?</h5>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="pricing_switch"
                          id="pricing_switch1"
                          value="1"
                        />
                        <label
                          className="form-check-label"
                          for="pricing_switch1"
                        >
                          {" "}
                          Yes{" "}
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="pricing_switch"
                          id="pricing_switch0"
                          value="0"
                          checked=""
                        />
                        <label
                          className="form-check-label"
                          for="pricing_switch0"
                        >
                          {" "}
                          No{" "}
                        </label>
                      </div>
                    </div>

                    <div className="formgroup">
                      <h5>Is featured - text badge</h5>
                      <input
                        type="text"
                        name="popular_text"
                        className="formControl"
                        value=""
                      />
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button
                      type="submit"
                      className="btn btn-primary cardBtn"
                      disabled=""
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPricing;
