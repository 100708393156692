import React, { useRef } from "react";
import "./ourservice.css";
import { useNavigate } from "react-router-dom";
import Whatsapp from "../TopupButtons/Whatsapp";
import cardImg1 from "../../Images/cardImg1.jpg";
import cardImg2 from "../../Images/cardImg2.jpg";
import cardImg3 from "../../Images/cardImg3.jpg";
import cardImg4 from "../../Images/cardImg4.jpg";

const OurService = () => {
  const navigate = useNavigate();

  const cmsRef = useRef(null);
  const venorRef = useRef(null);
  const rentzoneRef = useRef(null);
  const nivawordpressRef = useRef(null);

  const handleMouseMove = (ev, ref) => {
    if (!ref.current) return;

    const cardRect = ref.current.getBoundingClientRect();
    const cardCenterX = cardRect.left + cardRect.width / 2;
    const cardCenterY = cardRect.top + cardRect.height / 2;
    const deltaX = ev.clientX - cardCenterX;
    const deltaY = ev.clientY - cardCenterY;

    const maxRotation = 15;
    const movementScale = 3;
    const xRotation = (deltaY / cardRect.height) * maxRotation * movementScale;
    const yRotation = -(deltaX / cardRect.width) * maxRotation * movementScale;

    const yPercentageTop = deltaY / (cardRect.height / 2);
    const yPercentageBottom = 1 - deltaY / (cardRect.height / 2);
    const xPercentageLeft = (ev.clientX - cardRect.left) / cardRect.width;
    const xPercentageRight = 1 - xPercentageLeft;
    let opacity = 1;

    if (
      yPercentageTop > 0.5 ||
      yPercentageBottom < 0.5 ||
      xPercentageLeft < 0.5 ||
      xPercentageRight < 0.5
    ) {
      opacity = Math.max(0.5, 1 - Math.abs(yPercentageTop - 0.5) * 2);
    }

    ref.current.style.setProperty("--x-rotation", `${xRotation}deg`);
    ref.current.style.setProperty("--y-rotation", `${yRotation}deg`);
    ref.current.style.setProperty("--opacity", opacity);
  };

  const handleMouseLeave = (ref) => {
    if (!ref.current) return;

    setTimeout(() => {
      ref.current.style.transition = "transform 0.9s ease-out";
      ref.current.style.setProperty("--x-rotation", "0deg");
      ref.current.style.setProperty("--y-rotation", "0deg");
    });
  };

  return (
    <>
      <div className="breadcrumb-area">
        <h1 className="breadcrumb-title"> Services</h1>
        <ul className="page-list">
          <li className="item-home">
            <a
              className="bread-link"
              onClick={() => navigate("/")}
              title="Home"
            >
              Home
            </a>
          </li>
          <li className="separator separator-home"></li>
          <li className="item-current"> Services</li>
        </ul>
      </div>
      <div className="service_Container portfolio-section-filters ">
        <div className="row">
          <div className="col-md-6">
            <div
              className="cms_container card "
              ref={cmsRef}
              onMouseMove={(ev) => handleMouseMove(ev, cmsRef)}
              onMouseLeave={() => handleMouseLeave(cmsRef)}
            >
              <div className="text_container">
                <div className="container_meta">
                  <div className="Cardtitle">
                    <span className="meta_title">Website Design & Development</span>
                  </div>
                  <div className="Divider"></div>
                  <div className="Meta_category">
                    <span className="block_text"></span>
                  </div>
                </div>
              </div>
              <img src={cardImg1} alt="niva cms" className="img" />
            </div>

            <div
              className="venor card  "
              ref={venorRef}
              onMouseMove={(ev) => handleMouseMove(ev, venorRef)}
              onMouseLeave={() => handleMouseLeave(venorRef)}
            >
              <div className="text_container ">
                <div className="container_meta ">
                  <div className="Cardtitle">
                    <span className="meta_title">
                    Artificial Intelligence
                    </span>
                  </div>
                  <div className="Divider"></div>
                  <div className="Meta_category">
                    <span className="block_text"></span>
                  </div>
                </div>
              </div>
              <img src={cardImg2} alt="venor wordpress theme" className="img" />
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="RentZone card"
              ref={rentzoneRef}
              onMouseMove={(ev) => handleMouseMove(ev, rentzoneRef)}
              onMouseLeave={() => handleMouseLeave(rentzoneRef)}
            >
              <div className="text_container ">
                <div className="container_meta  ">
                  <div className="Cardtitle">
                    <span className="meta_title">
                    Application Design & Development
                    </span>
                  </div>
                  <div className="Divider"></div>
                  <div className="Meta_category">
                    <span className="block_text"></span>
                  </div>
                </div>
              </div>
              <img src={cardImg3} alt="venor wordpress theme" className="img" />
            </div>
            <div
              className="NivaWorkPress card"
              ref={nivawordpressRef}
              onMouseMove={(ev) => handleMouseMove(ev, nivawordpressRef)}
              onMouseLeave={() => handleMouseLeave(nivawordpressRef)}
              // style={{
              //   left: "67px",
              //   position: "relative",
              // }}
            >
              <div className="text_container">
                <div className="container_meta">
                  <div className="Cardtitle">
                    <span className="meta_title">
                      Digital Marketing
                    </span>
                  </div>
                  <div className="Divider"></div>
                  <div className="Meta_category">
                    <span className="block_text"></span>
                  </div>
                </div>
              </div>
              <img src={cardImg4} alt="venor wordpress theme" className="img" />
            </div>
          </div>
        </div>
      </div>
      <Whatsapp />
    </>
  );
};

export default OurService;
