import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import { useNavigate } from "react-router-dom";
import "../Pages/EditPage/editpage.css";

function CreateClient() {
  const [formData, setFormData] = useState({
    photo: null,
    company_name: "",
    company_link: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 3000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 3000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const data = new FormData();
      data.append("photo", formData.photo);
      data.append("company_name", formData.company_name);
      data.append("company_link", formData.company_link);

      const response = await axios.post(
        `${ngrokUrl.url}/api/saveClient`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setSuccess("clients added successfully!");
        setLoading(false);

        setFormData({
          photo: "",
          company_name: "",
          company_link: "",
        });
      } else {
        throw new Error("Failed to create clients");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to create page");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        navigate("/dashboard/manageclients");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [success]);
  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Create client</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              Create client
            </h6>
          </div>
          <div className="card-body mt-3">
            <Link to="/dashboard/manageclients" className="dashboardBtn">
              Back to Clients page
            </Link>

            <div className="row mt-4">
              <div className="col-md-12">
                <form
                  // action="https://niva4.lucian.host/admin/client"
                  // method="POST"
                  // enctype="multipart/form-data"

                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group">
                        <strong className="header_heady">Company name</strong>
                        <input
                          type="text"
                          name="company_name"
                          className="form-control label_dimension"
                          placeholder=""
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <strong className="header_heady">Company link</strong>
                        <input
                          type="text"
                          name="company_link"
                          className="form-control label_dimension"
                          placeholder=""
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group">
                        <strong className="header_heady">Photo</strong>
                        <input
                          type="file"
                          name="photo"
                          className="form-control-file label_dimension"
                          id="photo_id"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <button type="submit" className="cardBtn">
                          Create
                        </button>
                      )}
                    </div>
                  </div>
                </form>
                {error && (
                  <div style={{ color: "red", fontSize: "14px" }}>{error}</div>
                )}
                {success && (
                  <div style={{ color: "green", fontSize: "14px" }}>
                    {success}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateClient;
