import React from "react";
import { useParams } from "react-router-dom";
import { routes } from "../../Utility/data";

const DynamicRoute = () => {
  const { id } = useParams();

  const Component = routes[id];
  return Component ? <Component /> : <div>Page Not Found</div>;
};

export default DynamicRoute;
