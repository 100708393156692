import React from "react";

import "./Portfolio.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Loader from "../../Loader/Loader";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";

function Portfolio() {
  const [projectData, setProjectData] = useState({});
  const [portfolio, SetPortfolio] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [loadingPortfolio, setLoadingPortfolio] = useState(false);
  const [errorPortfolio, setErrorPortfolio] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const portfolioValues = async () => {
      setLoadingPortfolio(true);
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readSectionPortfolioId/1`
        );
        SetPortfolio(response.data);
        setLoadingPortfolio(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorPortfolio("Failed to fetch default values.");
        setLoadingPortfolio(false);
      }
    };
    portfolioValues();
  }, []);

  useEffect(() => {
    const projectValues = async () => {
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readSectionProject`
        );

        setProjectData(response.data);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
      }
    };

    projectValues();
  }, []);

  const tableData = Array.isArray(projectData) ? projectData : [];

  const navigate = useNavigate();
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div class="portfolio-section">
            <div class="container">
              <h4 className="text-start">{portfolio.title}</h4>
              <h3>{portfolio.subtitle}</h3>
              <div className="row">
                {tableData.map((project) => (
                  <div key={project.id} className="col-md-4">
                    <div className="col-project-big">
                      <div className="col-project-inner">
                        <a
                          href={project.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={project.photo_url}
                            style={{ width: 500, height: 280 }}
                            className="lazy img-fluid"
                            alt={project.title}
                          />
                        </a>

                        <div className="project-popup">
                          <a
                            href={`/project/${project.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="project-popup-inner">
                              <h6 className="project-categories">
                                {project.categories}
                              </h6>
                              <h5 className="project-title">{project.title}</h5>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default Portfolio;
