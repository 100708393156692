import React, { useState, useEffect } from "react";
import "./editproject.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ngrokUrl } from "../../../Utility/data";
import { useNavigate } from "react-router-dom";

const Editproject = () => {
  const navigate = useNavigate();
  const [project, setProject] = useState({
    body: "",
    button_link: "",
    button_text: "",
    categories: "",
    client: "",
    duration_project: "",
    link: "",
    meta_description: "",
    meta_title: "",
    photo: null,
    photo_four: "",
    photo_one: "",
    photo_three: "",
    photo_two: "",
    photo_uploadUrl: "",
    title: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const val = useParams();
  const id = val.id;

  useEffect(() => {
    let errorTimeout, successTimeout;
    if (error) {
      errorTimeout = setTimeout(() => setError(null), 3000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 3000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 3000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 3000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  useEffect(() => {
    const projectValues = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${ngrokUrl.url}/api/readSectionProjectById/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        setProject(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch members Details.", error);
        setError("Failed to fetch members Details");
        setLoading(false);
      }
    };

    if (id) {
      projectValues();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setProject({
      ...project,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const formDataObj = new FormData();
      formDataObj.append("_method", "PUT");
      formDataObj.append("body", project.body);
      formDataObj.append("button_link", project.button_link);
      formDataObj.append("button_text", project.button_text);
      formDataObj.append("categories", project.categories);
      formDataObj.append("client", project.client);
      formDataObj.append("photo_uploadUrl", project.photo_uploadUrl);
      formDataObj.append("duration_project", project.duration_project);
      formDataObj.append("link", project.link);
      formDataObj.append("meta_description", project.meta_description);
      formDataObj.append("meta_title", project.meta_title);
      formDataObj.append("photo_four", project.photo_four);
      formDataObj.append("photo_three", project.photo_three);
      formDataObj.append("photo_two", project.photo_two);
      formDataObj.append("photo_one", project.photo_one);
      formDataObj.append("title", project.title);
      if (typeof project.photo != "string")
        formDataObj.append("photo", project.photo);

      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${ngrokUrl.url}/api/updateSectionProject/${id}`,
        formDataObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setProject(response.data);
        setSuccess("project Updated Successfully!");
        setLoading(false);
      } else {
        throw new Error("Failed to updated project");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to update project");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        navigate("/dashboard/all-project");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [success]);

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Edit project</h1>
      <div className="card shadow mb-4">
        <div className="card-header templete py-3">
          <h6 className="m-0 font-weight-bold text-primary">Edit project</h6>
        </div>
        <a
          href="/dashboard/all-project?language=en"
          className="  btn-back cardBtn m-4 mb-2 "
        >
          Back to Project page
        </a>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="E4pBwjaWwuNPwgVs4YuelK0JQjB33VUagBimTf3n"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Title</h5>
                          <input
                            type="text"
                            name="title"
                            className="formControl"
                            placeholder=""
                            value={project.title}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Link</h5>
                          <div className="slug-container">
                            <span style={{ fontSize: "15px" }}>
                              https://niva-website.vercel.app/dashboard/all-project
                            </span>
                            <input
                              type="text"
                              name="link"
                              className="formControl"
                              placeholder=""
                              value={project.link}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <img
                            className="img-fluid pb-4"
                            width="100"
                            height="100"
                            src={project.photo_url}
                            alt="photo"
                          />
                          <p>
                            <h5>Photo</h5>
                          </p>
                          <input
                            type="file"
                            name="photo"
                            className="formControl-file"
                            id="photo_id"
                            style={{ fontSize: "15px" }}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>
                            Photo{" "}
                            <span style={{ fontSize: "16px" }}>
                              Upload image{" "}
                              <a
                                target="_blank"
                                href="https://niva4.lucian.host/admin/media/create"
                              >
                                {" "}
                                here{" "}
                              </a>{" "}
                              then copy the URL from{" "}
                              <a
                                target="_blank"
                                href="https://niva4.lucian.host/admin/media"
                                style={{ fontSize: "16px" }}
                              >
                                {" "}
                                here{" "}
                              </a>
                            </span>
                          </h5>
                          <input
                            type="text"
                            name="photo_uploadUrl"
                            className="formControl"
                            placeholder=""
                            value={project.photo_uploadUrl}
                            style={{ fontSize: "16px" }}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="formgroup">
                      <h5>Categories</h5>
                      <select
                        name="categories"
                        id="project_category_id"
                        className="form-control"
                        style={{ height: "38px", fontSize: "15px" }}
                        onChange={handleChange}
                      >
                        <option value="1">WordPress</option>
                        <option value="2">Laravel</option>
                        <option value="3">SEO</option>
                        <option value="8">Advertise</option>
                      </select>
                    </div>

                    <div className="formgroup">
                      <h5>Body</h5>
                      <textarea
                        name="body"
                        className="formControl"
                        id="body"
                        rows="8"
                        aria-hidden="true"
                        value={project.body}
                        onChange={handleChange}
                      ></textarea>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Photo 1</h5>
                          <img
                            className="img-fluid pb-4"
                            width="100"
                            height="100"
                            src={project.photo_one}
                            alt="photoOne"
                          />
                          <input
                            type="text"
                            name="photo_one"
                            className="formControl"
                            placeholder=""
                            value={project.photo_one}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Photo 2</h5>
                          <img
                            className="img-fluid pb-4"
                            width="100"
                            height="100"
                            src={project.photo_two}
                            alt="photoTwo"
                          />
                          <input
                            type="text"
                            name="photo_two"
                            className="formControl"
                            placeholder=""
                            value={project.photo_two}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Photo 3</h5>
                          <img
                            className="img-fluid pb-4"
                            width="100"
                            height="100"
                            src={project.photo_three}
                            alt="photoThree"
                          />
                          <input
                            type="text"
                            name="photo_three"
                            className="formControl"
                            placeholder=""
                            value={project.photo_three}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Photo 4</h5>
                          <img
                            className="img-fluid pb-4"
                            width="100"
                            height="100"
                            src={project.photo_four}
                            alt="photoFour"
                          />
                          <input
                            type="text"
                            name="photo_four"
                            className="formControl"
                            placeholder=""
                            value={project.photo_four}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Duration project</h5>
                          <input
                            type="text"
                            name="duration_project"
                            className="formControl"
                            placeholder=""
                            value={project.duration_project}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Client</h5>
                          <input
                            type="text"
                            name="client"
                            className="formControl"
                            placeholder=""
                            value={project.client}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Button text</h5>
                          <input
                            type="text"
                            name="button_text"
                            className="formControl"
                            placeholder=""
                            value={project.button_text}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Button link</h5>
                          <input
                            type="text"
                            name="button_link"
                            className="formControl"
                            placeholder=""
                            value={project.button_link}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>meta title</h5>
                          <input
                            type="text"
                            name="meta_title"
                            className="formControl"
                            placeholder=""
                            value={project.meta_title}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>meta description</h5>
                          <input
                            type="text"
                            name="meta_description"
                            className="formControl"
                            placeholder=""
                            value={project.meta_description}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button
                      type="submit"
                      className="btn btn-primary cardBtn"
                      disabled=""
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
              {error && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {error}
                </div>
              )}
              {success && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {success}{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editproject;
