import React from "react";
import { Link } from "react-router-dom";
import CodeMirror from "@uiw/react-codemirror";

function GoogleCode() {
  const handleChange = React.useCallback((value, viewUpdate) => {
    console.log("value:", value);
  }, []);
  return (
    <div className="card-body" style={{ paddingTop: "0" }}>
      <p className="mb-4 mt-4 label_dimension">Google Tag Code</p>
      <CodeMirror
        value=""
        height="200px"
        theme="dark"
        onChange={handleChange}
        className="mb-3"
      />
      <Link className="linky_link anchor_font">
        https://developers.google.com/tag-platform/gtagjs/install
      </Link>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 text-right">
          <button type="submit" className="btn btn-primary anchor_font">
            Save Settings
          </button>
        </div>
      </div>
    </div>
  );
}

export default GoogleCode;
