import React from "react";
import { Link } from "react-router-dom";

function PricingPage() {
  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Pricing Settings</h1>

        <div className="pb-2 text-right">
          <select
            name="language"
            className="form-control label_dimension language-control"
            onchange="window.location='https://niva4.lucian.host/admin/pricing-settings?language='+this.value"
          >
            <option value="" selected disabled>
              Select a language
            </option>
            <option value="en" selected>
              English
            </option>
            <option value="pt">Portuguese</option>
            <option value="عربى">عربى</option>
          </select>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">Section 1</h6>
              </div>
              <div className="card-body">
                <form
                  action="https://niva4.lucian.host/admin/pricing-settings/1/update"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Title</strong>
                        <input
                          type="text"
                          name="title"
                          className="form-control label_dimension"
                          value="Pricing Plans"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Description</strong>
                        <input
                          type="text"
                          name="description"
                          className="form-control label_dimension"
                          value="Whether you need a new logo, website, video, marketing campaign, or ebook created for your business, the key to making the project a success starts with having a well-thought-out creative brief."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button type="submit" className="btn btn-primary anchor_font">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
                <div className="mb-3">
                  <Link
                  to="/dashboard/managepricing"
                    className="btn btn-primary anchor_font mr-3"
                  >
                    View All
                  </Link>
                  <Link
                  to="/dashboard/createPricing"
                    className="btn btn-primary anchor_font"
                  >
                    Create
                  </Link>
                </div>
              </div>
            </div>
            {/* <!-- SEO --> */}

            {/* <!-- SEO --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">SEO</h6>
              </div>
              <div className="card-body">
                <form
                  action="https://niva4.lucian.host/admin/pricing-settings/1/update"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">meta title</strong>
                        <input
                          type="text"
                          name="meta_title"
                          className="form-control label_dimension"
                          value="Pricing"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">meta description</strong>
                        <input
                          type="text"
                          name="meta_description"
                          className="form-control label_dimension"
                          value="Our recent pricing"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Slug</strong>
                        <input
                          type="text"
                          name="slug"
                          className="form-control label_dimension"
                          value="pricing"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Anchor text</strong>
                        <input
                          type="text"
                          name="breadcrumbs_anchor"
                          className="form-control label_dimension"
                          value="Home"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button type="submit" className="btn btn-primary anchor_font">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- SEO --> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricingPage;
