import React, { useEffect } from "react";
import NavBar from "../../Utility/NavBar";
import Footer from "../Home/Footer/Footer";
import education from "../../Images/education.jpg";
import finance from "../../Images/finance&banking.jpg";
import ecommerce from "../../Images/ecommerce.jpg";
import realestate from "../../Images/realestate.jpg";
import software from "../../Images/software.jpg";
import healthfitness from "../../Images/health&fitness.jpg";
import travel from "../../Images/travel.jpg";
import automotive from "../../Images/automotive.jpg";
import pharmaceutical from "../../Images/pharmaceutical.jpg";
import medical from "../../Images/medical.jpg";
import music from "../../Images/music.jpg";
import fooddrinks from "../../Images/food&drink.jpg";
import { useState } from "react";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import Topbar from "../../Topbar/Topbar";
import Whatsapp from "../TopupButtons/Whatsapp";
function Industries() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Topbar />
          <NavBar />
          <div>
            <div class="breadcrumb-area">
              <h1 class="breadcrumb-title">Industries</h1>
              <ul class="page-list">
                <li class="item-home">
                  <a
                    class="bread-link"
                    onClick={() => navigate("/")}
                    title="Home"
                  >
                    Home
                  </a>
                </li>
                <li class="separator separator-home"></li>
                <li class="item-current">Industries</li>
              </ul>
            </div>
            <div
              className="services-section "
              style={{
                borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                paddingTop: "50px",
                paddingBottom: "50px",
              }}
            >
              <div className="container">
                <h3>Industries we serve</h3>
                <p style={{ fontSize: "16px" }}>
                Businesses are in a perpetual state of evolution, driven by a
                persistent quest to enhance operational efficiency and minimize
                costs. In the contemporary landscape, technology stands as a
                cornerstone in facilitating this evolution. YRP is uniquely
                poised to assist global businesses in attaining their digital
                transformation objectives by harnessing the power of Website, Application design & development, Augmented
                Reality (AR), Virtual Reality (VR), Artificial Intelligence
                (AI), and other digital technologies across diverse sectors.
                </p>
                <div className="row" style={{ rowGap: "25px" }}>
                  <div className="item overview-section col-md-4">
                    <div className="service-box-slide">
                      <div
                        className="service-box center-alignment"
                        style={{ backgroundImage: `url(${finance})` }}
                      >
                        <h5>Finance & Banking</h5>
                      </div>
                    </div>
                  </div>
                  <div className="item overview-section col-md-4">
                    <div className="service-box-slide">
                      <div
                        className="service-box center-alignment"
                        style={{ backgroundImage: `url(${ecommerce})` }}
                      >
                        <h5>E-commerce</h5>
                      </div>
                    </div>
                  </div>
                  <div className="item overview-section col-md-4">
                    <div className="service-box-slide">
                      <div
                        className="service-box center-alignment"
                        style={{ backgroundImage: `url(${realestate})` }}
                      >
                        <h5>Real Estate</h5>
                      </div>
                    </div>
                  </div>
                  <div className="item overview-section col-md-4">
                    <div className="service-box-slide">
                      <div
                        className="service-box center-alignment"
                        style={{ backgroundImage: `url(${software})` }}
                      >
                        <h5>Software</h5>
                      </div>
                    </div>
                  </div>
                  <div className="item overview-section col-md-4">
                    <div className="service-box-slide">
                      <div
                        className="service-box center-alignment"
                        style={{ backgroundImage: `url(${healthfitness})` }}
                      >
                        <h5>Health & Fitness</h5>
                      </div>
                    </div>
                  </div>
                  <div className="item overview-section col-md-4">
                    <div className="service-box-slide">
                      <div
                        className="service-box center-alignment"
                        style={{ backgroundImage: `url(${education})` }}
                      >
                        <h5>Education</h5>
                      </div>
                    </div>
                  </div>
                  <div className="item overview-section col-md-4">
                    <div className="service-box-slide">
                      <div
                        className="service-box center-alignment"
                        style={{ backgroundImage: `url(${travel})` }}
                      >
                        <h5>Travel</h5>
                      </div>
                    </div>
                  </div>
                  <div className="item overview-section col-md-4">
                    <div className="service-box-slide">
                      <div
                        className="service-box center-alignment"
                        style={{ backgroundImage: `url(${automotive})` }}
                      >
                        <h5>Automotive</h5>
                      </div>
                    </div>
                  </div>
                  <div className="item overview-section col-md-4">
                    <div className="service-box-slide">
                      <div
                        className="service-box center-alignment"
                        style={{ backgroundImage: `url(${pharmaceutical})` }}
                      >
                        <h5>Pharmaceutical</h5>
                      </div>
                    </div>
                  </div>
                  <div className="item overview-section col-md-4">
                    <div className="service-box-slide">
                      <div
                        className="service-box center-alignment"
                        style={{ backgroundImage: `url(${medical})` }}
                      >
                        <h5>Medical</h5>
                      </div>
                    </div>
                  </div>
                  <div className="item overview-section col-md-4">
                    <div className="service-box-slide">
                      <div
                        className="service-box center-alignment"
                        style={{ backgroundImage: `url(${music})` }}
                      >
                        <h5>Music</h5>
                      </div>
                    </div>
                  </div>
                  <div className="item overview-section col-md-4">
                    <div className="service-box-slide">
                      <div
                        className="service-box center-alignment"
                        style={{ backgroundImage: `url(${fooddrinks})` }}
                      >
                        <h5>Food & Drink</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Whatsapp />
          <Footer />
        </div>
      )}
    </>
  );
}

export default Industries;
