import React from "react";
import "./chooseUs.css";
function ChooseUs() {
  return (
    <div className="services-section main_container">
      <div>
        <h5
          className="title_text"
          style={{
            marginBottom: "0px !important",

            textWrap: "pretty",
          }}
        >
          Why Choose Us
        </h5>
        <h3
          style={{
            marginBottom: "0px !important",

            textWrap: "pretty",
          }}
        >
          We Design. We Create. We Advise.
        </h3>

        <div className="grid_facilities ">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
              <div className="facilities_box">
                <div className="facility_background">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-users"
                  >
                    <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                  </svg>
                </div>
                <div className="facility_content">
                  <h4 className="mt-2">Professional Team</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
              <div className="facilities_box">
                <div className="facility_background">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokelinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-lightbulb"
                  >
                    <path d="M15 14c.2-1 .7-1.7 1.5-2.5 1-.9 1.5-2.2 1.5-3.5A6 6 0 0 0 6 8c0 1 .2 2.2 1.5 3.5.7.7 1.3 1.5 1.5 2.5" />
                    <path d="M9 18h6" />
                    <path d="M10 22h4" />
                  </svg>
                </div>
                <div className="facility_content">
                  <h4 className="mt-2">Provide creative ideas</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
              <div className="facilities_box">
                <div className="facility_background">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-laptop"
                  >
                    <path d="M20 16V7a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v9m16 0H4m16 0 1.28 2.55a1 1 0 0 1-.9 1.45H3.62a1 1 0 0 1-.9-1.45L4 16" />
                  </svg>
                </div>
                <div className="facility_content">
                  <h4 className="mt-2">Custom Web Design</h4>
                  {/* <p className="facility_description">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Doloremque.
                  </p> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
              <div className="facilities_box">
                <div className="facility_background">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-smartphone"
                  >
                    <rect width="14" height="20" x="5" y="2" rx="2" ry="2" />
                    <path d="M12 18h.01" />
                  </svg>
                </div>
                <div className="facility_content">
                  <h4 className="mt-2">Responsive Web Design</h4>
                  {/* <p className="facility_description">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Doloremque.
                  </p> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="facilities_box">
                <div className="facility_background">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-calendar"
                  >
                    <path d="M8 2v4" />
                    <path d="M16 2v4" />
                    <rect width="18" height="18" x="3" y="4" rx="2" />
                    <path d="M3 10h18" />
                  </svg>
                </div>
                <div className="facility_content">
                  <h4 className="mt-2">Meet Deadlines</h4>
                  {/* <p className="facility_description">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Doloremque.
                  </p> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
              <div className="facilities_box">
                <div className="facility_background">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-phone"
                  >
                    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
                  </svg>
                </div>
                <div className="facility_content">
                  <h4 className="mt-2">Great Support</h4>
                  {/* <p className="facility_description">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Doloremque.
                  </p> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
              <div className="facilities_box">
                <div className="facility_background">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-tag"
                  >
                    <path d="M12.586 2.586A2 2 0 0 0 11.172 2H4a2 2 0 0 0-2 2v7.172a2 2 0 0 0 .586 1.414l8.704 8.704a2.426 2.426 0 0 0 3.42 0l6.58-6.58a2.426 2.426 0 0 0 0-3.42z" />
                    <circle cx="7.5" cy="7.5" r=".5" fill="currentColor" />
                  </svg>
                </div>
                <div className="facility_content">
                  <h4 className="mt-2">Reasonable Price</h4>
                  {/* <p className="facility_description">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Doloremque.
                  </p> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
              <div className="facilities_box">
                <div className="facility_background">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-baggage-claim"
                  >
                    <path d="M22 18H6a2 2 0 0 1-2-2V7a2 2 0 0 0-2-2" />
                    <path d="M17 14V4a2 2 0 0 0-2-2h-1a2 2 0 0 0-2 2v10" />
                    <rect width="13" height="8" x="8" y="6" rx="1" />
                    <circle cx="18" cy="20" r="2" />
                    <circle cx="9" cy="20" r="2" />
                  </svg>
                </div>
                <div className="facility_content">
                  <h4 className="mt-2">Friendly Team</h4>
                  {/* <p className="facility_description">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Doloremque.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseUs;
