import React, { useState, useEffect, useRef } from "react";
import "../Pages/EditPage/editpage.css";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import { useParams } from "react-router-dom";

const EditMembers = () => {
  const [members, setMembers] = useState({
    name: "",
    position: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    photo: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const val = useParams();
  const id = val.id;

  useEffect(() => {
    const membersValues = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readMemberById/${id}`
        );
        setMembers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch members Details.", error);
        setError("Failed to fetch members Details");
        setLoading(false);
      }
    };

    if (id) {
      membersValues();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setMembers({
      ...members,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const formDataObj = new FormData();
      formDataObj.append("_method", "PUT");
      formDataObj.append("name", members.name);
      formDataObj.append("position", members.position);
      formDataObj.append("facebook", members.facebook);
      formDataObj.append("twitter", members.twitter);
      formDataObj.append("linkedin", members.linkedin);
      if (typeof members.photo != "string")
        formDataObj.append("photo", members.photo);

      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${ngrokUrl.url}/api/updateMember/${id}`,
        formDataObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
            // "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setMembers(response.data);
        setSuccess("Member Details Updated Successfully!");
        setLoading(false);

        window.location.reload();
      } else {
        throw new Error("Failed to updated page");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to update page");
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <h1 class="h3 mb-2 text-gray-800">Edit members</h1>
      <div className="card shadow mb-4">
        <div class="card-header templete py-3">
          <h6 class="m-0 font-weight-bold text-primary">Edit members</h6>
        </div>
        <a href="/dashboard/members" class="btn-back cardBtn m-4 mb-2">
          Back to Members
        </a>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <form onSubmit={handleSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="mbJ1Ajj1Qp4fude5SJ4QADQlgn8AnE0HRL9aJzVf"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="formgroup">
                          <h5>Name</h5>
                          <input
                            type="text"
                            name="name"
                            class="formControl"
                            value={members.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="formgroup">
                          <h5>Position</h5>
                          <input
                            type="text"
                            name="position"
                            class="formControl"
                            value={members.position}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <div class="formgroup">
                          <h5>facebook</h5>
                          <input
                            type="text"
                            name="facebook"
                            class="formControl"
                            value={members.facebook}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="formgroup">
                          <h5>twitter</h5>
                          <input
                            type="text"
                            name="twitter"
                            class="formControl"
                            value={members.twitter}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="formgroup">
                          <h5>linkedin</h5>
                          <input
                            type="text"
                            name="linkedin"
                            class="formControl"
                            value={members.linkedin}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="formgroup">
                      <img
                        class="img-fluid pb-4"
                        width="100"
                        height="100"
                        src={members.photo_url}
                        onChange={handleChange}
                      />
                      <p>
                        <h5>Photo</h5>
                      </p>
                      <input
                        type="file"
                        name="photo"
                        class="form-control-file"
                        id="photo_id"
                        style={{ fontSize: "15px" }}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-md-12 text-right">
                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary anchor_font"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </form>
              {error && (
                <div style={{ color: "red", fontSize: "14px" }}>{error}</div>
              )}
              {success && (
                <div style={{ color: "green", fontSize: "14px" }}>
                  {success}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMembers;
