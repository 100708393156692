import React from "react";
import "./OurMission.css";

const OurMission = () => {
  return (
    <div className="mission_container">
      <div className="title">
        <h3>Our Mission and vision</h3>
      </div>
      <div className="container-section" style={{ marginBottom: "35px" }}>
        <div className="container-img mb-4 mt-4">
          {/* <img
            src="https://images.pexels.com/photos/4342493/pexels-photo-4342493.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="missionImg"
            className="img_overlay"
          /> */}
        </div>
        <div className="container-content">
          {/* <h3>Why choose us</h3> */}
          <h1>Highly experienced people with us</h1>
          <div className="container-content_text">
            <p>
              This is the main factor that sets us apart from our competition
              and allows us to deliver a specialist business consultancy
              service.our team applies it's wide-ranging experience to
              determining.Through our years of experience,we've also learned
              that while each channel .This is the main factor that sets us
              apart from our competition and allows us to deliver a specialist
              business consultancy service .Our team applies it's wide-ranging
              experience to determining
            </p>
          </div>
          <div className="d-flex justify-content-left align-items-left mt-2">
            {/* <div className="container_line"></div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMission;
