import React from "react";

const Footer = () => {
  return (
    <div>
      <footer className="sticky-footer bg-white shadow-lg mt-5">
        {/* <div className="container my-auto">
          <div className="copyright text-center my-auto">
            <span style={{ fontSize: "larger" }}>
              Copyright © Sweet-Themes 2021. Please visit the documentation of
              this awesome project.
            </span>
          </div>
        </div> */}
      </footer>
    </div>
  );
};

export default Footer;
