import React, { useState, useEffect } from "react";
import $ from "jquery";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel";
import "./Client.css";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";

function Client() {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readClient`);
        setClients(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  const tableData = Array.isArray(clients) ? clients : [];

  return (
    <div className="container">
      <div id="autoplayClient">
        {tableData.map((client) => (
          <div key={client.id} className="clien">
            <div className="clients-side">
              <a
                title={client.company_name}
                target="_blank"
                rel="noopener noreferrer"
                href={client.company_link}
                className="focus_stop"
              >
                <img
                  className="client_image"
                  src={client.photo_url}
                  alt={client.company_name}
                />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Client;
