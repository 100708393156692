import React, { useState, useEffect } from "react";
import member from "../../Images/blog-project-memberpic.jpg";
import post1 from "../../Images/blog-project-post1.jpg";
// import google from "../../Images/google-adense.png";
// import googleH from "../../Images/google-adense-horizontal.jpg";
import Footer from "../Home/Footer/Footer";
import NavBar from "../../Utility/NavBar";
import Whatsapp from "../TopupButtons/Whatsapp";
import Topbar from "../../Topbar/Topbar";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import Loader from "../Loader/Loader";

function Blog6Articles() {
  const [topSix, setTopSix] = useState({});
  const [sidebar, setSidebar] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loadingSidebar, setLoadingSidebar] = useState(false);
  const [errorSidebar, setErrorSidebar] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const topsixValues = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${ngrokUrl.url}/api/editAllPostsById/${21}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        setTopSix(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    topsixValues();
  }, []);

  const formattedDate2 = new Date(topSix.created_at).toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "long",
      day: "numeric",
    }
  );

  useEffect(() => {
    const SideBarValues = async () => {
      setLoadingSidebar(true);
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readOurRecentNewsById/1`
        );
        setSidebar(response.data);
        setLoadingSidebar(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorSidebar("Failed to fetch default values.");
        setLoadingSidebar(false);
      }
    };
    SideBarValues();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="scrollable-container">
          <Topbar />
          <Whatsapp />
          <NavBar />
          <div class="breadcrumb-area">
            <h1 class="breadcrumb-title">{topSix.title}</h1>
          </div>

          <div class="post-content blog-page-section">
            <div class="container">
              <div class="row">
                <div class="col-md-8">
                  <article class="single-post blogloop-v2">
                    <div class="blog_custom">
                      <div class="post-thumbnail">
                        <a href={topSix.link}>
                          <img
                            class="blog_post_image img-fluid lazy"
                            style={{ width: "717px", height: "380px" }}
                            src={topSix.photo_url}
                          />
                        </a>
                      </div>
                      <span class="post-date">{formattedDate2}</span>
                      {/* <!-- POST DETAILS --> */}
                      <div class="post-details">
                        <div class="post-details-holder">
                          <div class="post-author-avatar">
                            <img
                              alt=""
                              src={member}
                              class="avatar img-fluid lazy"
                              height="120"
                              width="120"
                            />
                          </div>

                          <h2 class="post-name">{topSix.title}</h2>

                          <div class="post-category-comment-date">
                            <span class="post-tags">
                              <i class="fa fa-tag"></i> {topSix.categories}
                            </span>
                          </div>

                          <div class="post-body">
                            <p>{topSix.body}</p>
                            {/* <p>
                              <a
                                title="adsense"
                                href="https://www.google.com/adsense/start/"
                                target="_blank"
                                rel="noopener"
                              >
                                <img class="img-fluid img-ad" src={googleH} />
                              </a>
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>

                <div class="col-md-4">
                  <div class="widget_element">
                    <h3 style={{ textAlign: "center" }} class="widget-title">
                      About us
                    </h3>
                    <div class="textwidget" style={{ marginLeft: "0%" }}>
                      <a href="/about-us">
                        <img
                          class="html-widget-image img-fluid"
                          src={post1}
                          alt=""
                        />
                      </a>
                      <p class="html-widget-paragraph">
                        {sidebar.sidebar_html_one}
                      </p>
                      <a
                        class="btn btn-style1 "
                        style={{ position: "relative", left: "60px" }}
                        href="/about-us"
                      >
                        Read More{" "}
                      </a>
                    </div>
                  </div>

                  {/* <div class="widget_element">
                    <h3 class="widget-title">{sidebar.sidebar_html_two}</h3>
                    <div class="textwidget" style={{ marginLeft: "0%" }}>
                      <a
                        title="adsense"
                        href="https://www.google.com/adsense/start/"
                        target="_blank"
                        rel="noopener"
                      >
                        <img
                          class="html-widget-image img-ad img-fluid"
                          src={google}
                          alt=""
                        />
                      </a>
                      &nbsp;
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </>
  );
}
export default Blog6Articles;
