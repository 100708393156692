import React from "react";
import { Link } from "react-router-dom";

function HomePage() {
  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Home Settings</h1>

        <div className="row">
          <div className="col-md-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  Section - Main Slider
                </h6>
              </div>
              <div className="card-body">
                <Link
                  to="/dashboard/manageslider"
                  className="btn btn-primary anchor_font mr-3"
                >
                  View All
                </Link>
                <Link
                  to="/dashboard/createSlider"
                  className="btn btn-primary anchor_font"
                >
                  Create
                </Link>
              </div>
            </div>

            {/* <!-- fun facts --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  Section - Fun facts
                </h6>
              </div>
              <div className="card-body">
                <form
                  action="https://niva4.lucian.host/admin/home-settings/1/update"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="label_dimension">Title</strong>
                            <input
                              type="text"
                              name="fun_title"
                              className="form-control label_dimension"
                              value="Fun Facts"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="label_dimension">
                              Description
                            </strong>
                            <input
                              type="text"
                              name="fun_description"
                              className="form-control label_dimension"
                              value="Over the years we have done many things that we are proud of. This motivates us to continue looking for new challenges in order to improve our services."
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <strong className="label_dimension">
                              count number 1
                            </strong>
                            <input
                              type="text"
                              name="count_number1"
                              className="form-control label_dimension"
                              value="425"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <strong className="label_dimension">
                              count number 2
                            </strong>
                            <input
                              type="text"
                              name="count_number2"
                              className="form-control label_dimension"
                              value="12"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <strong className="label_dimension">
                              count number 3
                            </strong>
                            <input
                              type="text"
                              name="count_number3"
                              className="form-control label_dimension"
                              value="1320"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <strong className="label_dimension">
                              count number 4
                            </strong>
                            <input
                              type="text"
                              name="count_number4"
                              className="form-control label_dimension"
                              value="860"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <strong className="label_dimension">
                              count text 1
                            </strong>
                            <input
                              type="text"
                              name="count_description1"
                              className="form-control label_dimension"
                              value="Happy Customers"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <strong className="label_dimension">
                              count text 2
                            </strong>
                            <input
                              type="text"
                              name="count_description2"
                              className="form-control label_dimension"
                              value="Cups of Coffee"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <strong className="label_dimension">
                              count text 3
                            </strong>
                            <input
                              type="text"
                              name="count_description3"
                              className="form-control label_dimension"
                              value="Innovations"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <strong className="label_dimension">
                              count text 4
                            </strong>
                            <input
                              type="text"
                              name="count_description4"
                              className="form-control label_dimension"
                              value="Great Projects"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary anchor_font"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- fun facts --> */}

            {/* <!-- about --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark">
                  Section - About
                </h6>
              </div>
              <div className="card-body">
                <form
                  action="https://niva4.lucian.host/admin/home-settings/1/update"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="label_dimension">Title</strong>
                            <input
                              type="text"
                              name="about_title"
                              className="form-control label_dimension"
                              value="Unlimited Skills for Super Projects."
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="label_dimension">
                              Subtitle
                            </strong>
                            <input
                              type="text"
                              name="about_subtitle"
                              className="form-control label_dimension"
                              value="ABOUT AGENCY"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <strong className="label_dimension">Description</strong>
                        <textarea
                          name="about_description"
                          className="form-control label_dimension"
                          rows="6"
                        >
                          &lt;p&gt;Whether you need a new logo, website, video,
                          marketing campaign, or ebook created for your
                          business, the key to making the project a success
                          starts with having a well-thought-out creative brief.
                          No coding skills required to create unique sites.
                          Customize your site in real-time and see the results
                          &lt;strong&gt;instantly&lt;/strong&gt;.&lt;/p&gt;
                        </textarea>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="label_dimension">
                              Button text
                            </strong>
                            <input
                              type="text"
                              name="about_buttontext"
                              className="form-control label_dimension"
                              value="Get the offer"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="label_dimension">
                              Button link
                            </strong>
                            <input
                              type="text"
                              name="about_buttonlink"
                              className="form-control label_dimension"
                              value="https://niva4.lucian.host/pricing"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <p>
                              <strong className="label_dimension">Photo</strong>{" "}
                              <span>
                                Upload image{" "}
                                <a
                                  target="_blank"
                                  href="https://niva4.lucian.host/admin/media/create?language=en"
                                >
                                  {" "}
                                  here{" "}
                                </a>{" "}
                                then copy the URL from{" "}
                                <a
                                  target="_blank"
                                  href="https://niva4.lucian.host/admin/media?language=en"
                                >
                                  {" "}
                                  here{" "}
                                </a>
                              </span>
                            </p>

                            <input
                              type="text"
                              name="about_image1"
                              className="form-control label_dimension"
                              value="https://niva4.lucian.host/public/images/media/1615636710about-s1.jpg"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p>
                              {" "}
                              <strong className="label_dimension">Photo</strong>
                            </p>
                            <input
                              type="text"
                              name="about_image2"
                              className="form-control label_dimension"
                              value="https://niva4.lucian.host/public/images/media/1615636710about-s2.jpg"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="label_dimension">
                              Years experience - number
                            </strong>
                            <input
                              type="text"
                              name="about_yearstitle"
                              className="form-control label_dimension"
                              value="12"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="label_dimension">
                              Years experience - text
                            </strong>
                            <input
                              type="text"
                              name="about_yearstext"
                              className="form-control label_dimension"
                              value="YEARS OF EXPERIENCE"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary anchor_font"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- about --> */}

            {/* <!-- services --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  Section - Services
                </h6>
              </div>
              <div className="card-body">
                <div className="mb-3">
                  <a
                    className="btn btn-primary anchor_font mr-3"
                    href="https://niva4.lucian.host/admin/service?language=en"
                  >
                    View All
                  </a>
                  <a
                    className="btn btn-primary anchor_font"
                    href="https://niva4.lucian.host/admin/service/create?language=en"
                  >
                    Create
                  </a>
                </div>
                <form
                  action="https://niva4.lucian.host/admin/home-settings/1/update"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group">
                        <strong className="label_dimension">Title</strong>
                        <input
                          type="text"
                          name="services_title"
                          className="form-control label_dimension"
                          value="How we can help take your next project to new heights"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary anchor_font"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- services --> */}

            {/* <!-- portfolio --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  Section - Portfolio
                </h6>
              </div>
              <div className="card-body">
                <div className="mb-3">
                  <a
                    className="btn btn-primary anchor_font mr-3"
                    href="https://niva4.lucian.host/admin/project?language=en"
                  >
                    View All
                  </a>
                  <a
                    className="btn btn-primary anchor_font "
                    href="https://niva4.lucian.host/admin/project/create?language=en"
                  >
                    Create
                  </a>
                </div>
                <form
                  action="https://niva4.lucian.host/admin/home-settings/1/update"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Title</strong>
                        <input
                          type="text"
                          name="projects_title"
                          className="form-control label_dimension"
                          value="Our premium projects."
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Subtitle</strong>
                        <input
                          type="text"
                          name="projects_subtitle"
                          className="form-control label_dimension"
                          value="SELECTED WORKS"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary anchor_font"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- portfolio --> */}

            {/* <!-- testimonial --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  Section - Testimonials
                </h6>
              </div>
              <div className="card-body">
                <div className="mb-3">
                  <a
                    className="btn btn-primary anchor_font mr-3"
                    href="https://niva4.lucian.host/admin/testimonial?language=en"
                  >
                    View All
                  </a>
                  <a
                    className="btn btn-primary anchor_font "
                    href="https://niva4.lucian.host/admin/testimonial/create?language=en"
                  >
                    Create
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- testimonial --> */}

            {/* <!-- blog --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  Section - Blog
                </h6>
              </div>
              <div className="card-body">
                <div className="mb-3">
                  <a
                    className="btn btn-primary anchor_font mr-3"
                    href="https://niva4.lucian.host/admin/post?language=en"
                  >
                    View All
                  </a>
                  <a
                    className="btn btn-primary anchor_font "
                    href="https://niva4.lucian.host/admin/post/create?language=en"
                  >
                    Create
                  </a>
                </div>
                <form
                  action="https://niva4.lucian.host/admin/home-settings/1/update"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Title</strong>
                        <input
                          type="text"
                          name="blog_title"
                          className="form-control label_dimension"
                          value="Our Last News"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Subtitle</strong>
                        <input
                          type="text"
                          name="blog_subtitle"
                          className="form-control label_dimension"
                          value="BLOG POSTS"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary anchor_font"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- blog --> */}

            {/* <!-- SEO --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  SEO
                </h6>
              </div>
              <div className="card-body">
                <form
                  action="https://niva4.lucian.host/admin/home-settings/1/update"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">meta title</strong>
                        <input
                          type="text"
                          name="meta_title"
                          className="form-control label_dimension"
                          value="Niva CMS | Creative Agency"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">
                          meta description
                        </strong>
                        <input
                          type="text"
                          name="meta_description"
                          className="form-control label_dimension"
                          value="Whether you need a new logo, website, video, marketing campaign, or ebook created for your business, the key to making the project a success starts with having a well-thought-out creative brief."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary anchor_font"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- SEO --> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
