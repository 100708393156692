import React, { useState, useEffect } from "react";
import "../../Pages/EditPage/editpage.css";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const EditClient = () => {
  const navigate = useNavigate();
  const [client, setClient] = useState({
    company_link: "",
    company_name: "",
    photo: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 3000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 3000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  const val = useParams();
  const id = val.id;

  useEffect(() => {
    const ClientValues = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${ngrokUrl.url}/api/editClients/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        setClient(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    if (id) {
      ClientValues();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setClient({
      ...client,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const formDataObj = new FormData();
      formDataObj.append("_method", "PUT");
      formDataObj.append("company_name", client.company_name);
      formDataObj.append("company_link", client.company_link);
      if (typeof client.photo != "string")
        formDataObj.append("photo", client.photo);

      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${ngrokUrl.url}/api/updateClient/${id}`,
        formDataObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setClient(response.data);
        setSuccess("Client  updated successfully!");
        setLoading(false);
      } else {
        throw new Error("Failed to updated Client");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to update Client");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        navigate("/dashboard/manageclients");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [success]);

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Edit client</h1>
      <div className="card shadow  mb-4">
        <div className="card-header  templete py-3">
          <h6 className="m-0 font-weight-bold text-primary">Edit client</h6>
        </div>
        <a
          href="/dashboard/manageclients"
          className="btn-back cardBtn m-4 mb-2"
        >
          Back to Clients page
        </a>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="bQa1IU2NI1tyRO907URYzQIyqDY6fe83pMqncSHh"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="formgroup">
                      <h5>Company name</h5>
                      <input
                        type="text"
                        name="company_name"
                        className="formControl"
                        value={client.company_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="formgroup">
                      <h5>Company link</h5>
                      <input
                        type="text"
                        name="company_link"
                        className="formControl"
                        value={client.company_link}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="formgroup">
                      <img
                        className="img-fluid pb-4"
                        width="100"
                        height="100"
                        src={client.photo_url}
                      />
                      <p>
                        <h5>Photo</h5>
                      </p>
                      <input
                        type="file"
                        name="photo"
                        className="formControl-file"
                        id="photo_id"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button type="submit" className="cardBtn">
                      Update
                    </button>
                  </div>
                </div>
              </form>
              {error && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {error}
                </div>
              )}
              {success && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {success}{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditClient;
