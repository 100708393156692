import React, { useState, useEffect } from "react";
// import profile from "../../Images/blog-project-memberpic.jpg";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import { useParams } from "react-router-dom";

function EditUser() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const val = useParams();
  const id = val.id;

  useEffect(() => {
    const userValues = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${ngrokUrl.url}/api/readUserById/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        setUser(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch members Details.", error);
        setError("Failed to fetch members Details");
        setLoading(false);
      }
    };

    if (id) {
      userValues();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setUser({
      ...user,
      [name]: type === "file" ? files[0] : value,
    });
  };

  console.log("user", user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const formDataObj = new FormData();
      formDataObj.append("_method", "PUT");
      formDataObj.append("name", user.name);
      formDataObj.append("email", user.email);
      formDataObj.append("roles", user.roles);
      formDataObj.append("address", user.address);
      formDataObj.append("city", user.city);
      formDataObj.append("password", user.password);
      formDataObj.append("phone_whatsapp", user.phone_whatsapp);
      if (typeof user.photo != "string")
        formDataObj.append("photo", user.photo);

      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${ngrokUrl.url}/api/updateUser/${id}`,
        formDataObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
            // "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setUser(response.data);
        setSuccess("User Details Updated Successfully!");
        setLoading(false);

        window.location.reload();
      } else {
        throw new Error("Failed to updated User");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to update User");
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Edit user</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              Elisabeth Doe
            </h6>
          </div>
          <a href="/dashboard/allUsers" className="btn-back cardBtn m-4 mb-4">
            Back to users
          </a>
          <div className="card-body">
            <div className="row">
              {/* <div className="col-md-3">
                <div className="img-container">
                  <img className="img-fluid" src={user.photo_url} alt="" />
                </div>
              </div> */}
              <div>
                <form onSubmit={handleSubmit}>
                  <input
                    type="hidden"
                    name="_token"
                    value="BR0jO3WLzZsfCd8iBzikmcd0xtiy5sCkoZmZnFP0"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Name</strong>
                        <input
                          type="text"
                          name="name"
                          value={user.name}
                          className="form-control label_dimension"
                          placeholder="Name"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Email</strong>
                        <input
                          type="email"
                          name="email"
                          value={user.email}
                          className="form-control label_dimension"
                          placeholder="Email"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <img
                          className="img-fluid pb-4"
                          width="200"
                          height="400"
                          src={user.photo_url}
                          alt="Preview"
                        />

                        <h5>Photo</h5>

                        <input
                          type="file"
                          name="photo"
                          className="formControl-file"
                          id="photo_id"
                          style={{ fontSize: "15px" }}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="formgroup">
                        <h5>Roles</h5>
                        <select
                          name="roles"
                          id="role_id"
                          className="form-control"
                          value={user.roles}
                          onChange={handleChange}
                          style={{ height: "38px", fontSize: "15px" }}
                        >
                          <option>Choose role</option>
                          <option value="1">Administrator</option>
                          <option value="2">Author</option>
                          <option value="3">Subscriber</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Address</strong>
                        <input
                          type="text"
                          name="address"
                          value={user.address}
                          className="form-control label_dimension"
                          placeholder="Address"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">City</strong>
                        <input
                          type="text"
                          name="city"
                          value={user.city}
                          className="form-control label_dimension"
                          placeholder="City"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">
                          Phone / Whatsapp
                        </strong>
                        <input
                          type="text"
                          name="phone_whatsapp"
                          value={user.phone_whatsapp}
                          className="form-control label_dimension"
                          placeholder="Phone / Whatsapp"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Photo</strong>
                        <input
                          type="file"
                          name="photo"
                          className="form-control-file  label_dimension"
                          id="photo_id"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">
                          Change Password
                        </strong>
                        <input
                          type="password"
                          name="password"
                          className="form-control label_dimension"
                          placeholder="Change Password"
                          value={user.password}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary anchor_font"
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </form>
                {error && (
                  <div
                    className="alert alert-danger"
                    style={{ fontSize: "14px" }}
                  >
                    {error}
                  </div>
                )}
                {success && (
                  <div
                    className="alert alert-success"
                    style={{ fontSize: "14px" }}
                  >
                    {success}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
