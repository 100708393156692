import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";

function GoogleAnalytics() {
  const [analyticData, setAnalyticData] = useState({
    google_analytics_id: "",
    enable_disable_button: "true",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const analyticsValues = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${ngrokUrl.url}/api/readGoogleAnalytics/8`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      setAnalyticData(response.data);
    } catch (error) {
      console.error("Failed to fetch default values.", error);
      setError("Failed to fetch default values.");
    }
  };

  useEffect(() => {
    analyticsValues();
  }, []);

  const handlechange = (e) => {
    const { name, value, type, checked } = e.target;
    setAnalyticData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  console.log("analyticData", analyticData);

  const handlesubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateGoogleAnalytics/8`,
        analyticData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      await analyticsValues();

      setAnalyticData(analyticData);
      setSuccess("updated successfully.");
    } catch (error) {
      console.error("Failed to update website settings.", error);
      setError("Failed to update website settings.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Google Analytics</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              Google Analytics
            </h6>
          </div>
          <form onSubmit={handlesubmit}>
            <div className="card-body">
              {/* <Link className="btn btn-primary btn-back anchor_font">
              Back to Media
            </Link> */}

              {/* <div className="table-responsive">
              <form
                action="https://niva4.lucian.host/admin/media"
                className="dropzone2"
                method="POST"
                enctype="multipart/form-data"
              >
                <input
                  type="hidden"
                  name="_token"
                  value="9kr4A0f0neruHdqd4e2UADuzDOw4VyxcaJSUzoO3"
                />
              </form>
            </div> */}

              <div className="">
                <div
                  className="form-check form-switch d-flex align-items-center"
                  style={{ marginLeft: "12px" }}
                >
                  <input
                    className="form-check-input checky_check mr-2"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    name="enable_disable_button"
                    checked={analyticData.enable_disable_button}
                    value={analyticData.enable_disable_button}
                    onChange={handlechange}
                  />
                  <label
                    className="form-check-label header_heady"
                    for="flexSwitchCheckChecked"
                  >
                    Enable Dashboard Widgets
                  </label>
                </div>

                {/* <p className="label_dimension">Enable Dashboard Widgets</p> */}
              </div>

              <p className="mb-4 mt-4 label_dimension">Property ID for GA4</p>
              <input
                type="text"
                className="form-control label_dimension"
                name="google_analytics_id"
                // value="344382041"
                value={analyticData.google_analytics_id}
                onChange={handlechange}
              />

              <Link className="linky_link anchor_font">
                https://developers.google.com/analytics/devguides/reporting/data/v1/property-id
              </Link>

              <p className="mb-4 mt-4 label_dimension border px-2 py-4 rounded bordery">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-info mr-2"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <path d="M12 16v-4" />
                    <path d="M12 8h.01" />
                  </svg>
                </span>
                If you want to add Google Analytics Tag ID to tracking your
                website, please go to Admin → Settings → Website Tracking.
              </p>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary anchor_font"
                    >
                      Save Changes
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
          {error && <p style={{ color: "red" }}>Error: {error}</p>}
          {success && <p style={{ color: "green" }}>Success: {success}</p>}
        </div>
      </div>
    </div>
  );
}

export default GoogleAnalytics;
