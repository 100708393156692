import React from "react";

function CreateLanguage() {
  return (
    <div>
      <div class="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 class="h3 mb-2 text-gray-800">Create language</h1>

        {/* <!-- DataTales Example --> */}
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary anchor_font">
              Create language
            </h6>
          </div>
          <div class="card-body">
            <div class="row form-language">
              <div class="col-md-12">
                <form
                  action="https://niva4.lucian.host/admin/language"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12">
                      <div class="form-group">
                        <strong className="header_heady">Photo</strong>
                        <input
                          type="file"
                          name="photo_id"
                          class="form-control-file label_dimension"
                          id="photo_id"
                        />
                      </div>

                      <div class="form-group">
                        <strong className="header_heady">Name</strong>
                        <input
                          type="text"
                          name="name"
                          class="form-control label_dimension"
                        />
                      </div>
                      <div class="form-group">
                        <strong className="header_heady">Code language</strong>
                        <input
                          type="text"
                          name="code"
                          class="form-control label_dimension"
                        />
                      </div>

                      <div class="form-group">
                        <strong className="header_heady">Is default?</strong>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="is_default"
                            id="is_default1"
                            value="1"
                          />
                          <label class="form-check-label" for="is_default1">
                            {" "}
                            Yes{" "}
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="is_default"
                            id="is_default2"
                            value="0"
                          />
                          <label class="form-check-label" for="is_default2">
                            {" "}
                            No{" "}
                          </label>
                        </div>
                      </div>

                      <div class="form-group">
                        <strong className="header_heady">LTR or RTL</strong>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="rtl"
                            id="rtl2"
                            value="0"
                          />
                          <label class="form-check-label" for="rtl2">
                            {" "}
                            LTR{" "}
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="rtl"
                            id="rtl1"
                            value="1"
                          />
                          <label class="form-check-label" for="rtl1">
                            {" "}
                            RTL{" "}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button type="submit" class="btn btn-primary anchor_font">
                        Create
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateLanguage;
