import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

const Testimonial = () => {
  const carouselRef = useRef(null);
  const [testimonials, setTestimonials] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const options = {
    items: 3,
    loop: true,
    nav: true,
    dots: false,
    mouseDrag: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
  };

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readTestimonial`);
        setTestimonials(response.data);
      } catch (error) {
        console.error("Failed to fetch testimonials.", error);
      }
    };

    fetchTestimonials();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
    });

    return () => clearInterval(interval);
  }, [testimonials]);

  return (
    <div className="testimonial-section">
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        // autoplay={false}
        pagination={false}
        navigation={true}
        modules={[Navigation, Pagination, Autoplay]}
        breakpoints={{
          300: { slidesPerView: 1 },
          640: { slidesPerView: 1 },
          768: { slidesPerView: 1 },
          1024: { slidesPerView: 3 },
        }}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className="test1">
              <div className="section_title">{testimonial.subtitle}</div>
              <span className="testimonial_slider_title" {...options}>
                {testimonial.title}
              </span>
              <div className="testimonial-area">
                <div className="testimonial-layoutArea">
                  <p className="desc">{testimonial.desc}</p>
                </div>
              </div>
              <div className="testimonials_slider_name">
                {testimonial.name} - {testimonial.position}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Testimonial;
