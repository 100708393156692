import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import "../Pages/EditPage/editpage.css";
import { useNavigate } from "react-router-dom";

const CreatePost = () => {
  const [postData, setPostData] = useState({});
  const [categoriesData, setCategoriesData] = useState({
    categories: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 3000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 3000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  useEffect(() => {
    const categoriesValues = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${ngrokUrl.url}/api/readPostCategories`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );

        setCategoriesData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
      }
    };

    categoriesValues();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setPostData({
      ...postData,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${ngrokUrl.url}/api/saveAllPosts`,

        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setPostData(response.data);
      setSuccess("Post Create Successfully!");
      setLoading(false);
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to create page");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        navigate("/dashboard/allPosts");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [success]);

  const tableData = Array.isArray(categoriesData) ? categoriesData : [];

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Create article</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              Create article
            </h6>
          </div>
          <div className="card-body mt-3">
            <div className="row align-items-center mb-4">
              <div className="col-md-6">
                <Link to="/dashboard/allPosts" className="dashboardBtn">
                  Back to Blog page
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <form onSubmit={handleSubmit}>
                  <input
                    type="hidden"
                    name="_token"
                    value="9kr4A0f0neruHdqd4e2UADuzDOw4VyxcaJSUzoO3"
                  />
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <input
                        type="hidden"
                        name="language_id"
                        value={postData.id}
                      />

                      <div className="row">
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">Title</strong>
                            <input
                              type="text"
                              name="title"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">Link</strong>
                            <div className="slug-container d-flex flex-column flex-lg-row">
                              <span className="label_dimension">
                                https://niva4.lucian.host/Post/
                              </span>
                              <input
                                type="text"
                                name="link"
                                className="formControl input_height mobile_width w-100"
                                placeholder=""
                                style={{ width: "50%" }}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">Photo</strong>
                            <input
                              type="file"
                              name="photo"
                              className="formControl-file anchor_font"
                              id="photo_id"
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="formgroup">
                          <strong className="header_heady">Categories</strong>
                          <select
                            name="categories"
                            id="project_category_id"
                            className="form-control label_dimension input_height"
                            onChange={handleChange}
                            value={categoriesData.categories}
                          >
                            <option>Choose Category</option>
                            {tableData.map((category) => (
                              <option
                                key={category.categories}
                                value={category.categories}
                              >
                                {category.categories}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="formgroup">
                        <strong className="header_heady">Body</strong>
                        <textarea
                          name="body"
                          className="formControl"
                          id="body"
                          rows="20"
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">meta title</strong>
                            <input
                              type="text"
                              name="meta_title"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">
                              meta description
                            </strong>
                            <input
                              type="text"
                              name="meta_description"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <button type="submit" className="cardBtn">
                          Create article
                        </button>
                      )}
                    </div>
                  </div>
                </form>
                {error && (
                  <div style={{ color: "red", fontSize: "14px" }}>{error}</div>
                )}
                {success && (
                  <div style={{ color: "green", fontSize: "14px" }}>
                    {success}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePost;
