import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";

function Captcha() {
  const [captData, setCaptData] = useState({
    recaptcha_site_key: " ",
    recaptcha_secret_key: " ",
    recaptcha_status: " ",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const captchaData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${ngrokUrl.url}/api/readCaptcha/1`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      });

      const data = await response.data;

      setCaptData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    captchaData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCaptData((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateCaptcha/1`,
        captData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      await captchaData();

      setCaptData(captData);
      setSuccess("updated successfully.");
    } catch (error) {
      console.error("Failed to update website settings.", error);
      setError("Failed to update website settings.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Captcha</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              Captcha
            </h6>
          </div>
          <div className="card-body">
            <div className="row form-menu mt-4">
              <div className="col-md-12">
                <form
                  // action="https://niva4.lucian.host/admin/menu"
                  // method="POST"
                  // enctype="multipart/form-data"
                  onSubmit={handlesubmit}
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group d-flex gap-5 align-items-center">
                        <strong className="header_heady col-3">
                          Recaptcha Site Key <sup className="star_drop">*</sup>
                        </strong>
                        <input
                          type="text"
                          name="recaptcha_site_key"
                          // value="asgkneeith23k5n239tiq2eh921i3y2938f92_w9e8rh2"
                          value={captData.recaptcha_site_key}
                          className="form-control label_dimension col-8"
                          placeholder="Name"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group d-flex gap-5 align-items-center">
                        <strong className="header_heady col-3">
                          Recaptcha Secret key{" "}
                          <sup className="star_drop">*</sup>
                        </strong>
                        <input
                          type="text"
                          name="recaptcha_secret_key"
                          // value="asjk22134523klmasbfkbas_"
                          value={captData.recaptcha_secret_key}
                          className="form-control label_dimension col-8"
                          placeholder="Link"
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group submeniu-code hide">
                        <strong className="header_heady">HTML Submenu</strong>
                        <textarea
                          name="submenu"
                          className="form-control"
                          id="submenu"
                          rows="6"
                        ></textarea>
                        <div className="alert alert-success">
                          <strong className="header_heady">
                            HTML code - submenu example
                          </strong>
                          <pre>
                            <div className="dropdown-menu">
                              <a className="dropdown-item" href="#">
                                Submenu test
                              </a>
                              <a className="dropdown-item" href="#">
                                Submenu test
                              </a>
                              <a className="dropdown-item" href="#">
                                Submenu test
                              </a>
                            </div>
                          </pre>
                        </div>
                      </div>

                      <div className="form-group d-flex gap-5 align-items-center">
                        <strong className="header_heady col-3">
                          Recaptcha Status <sup className="star_drop">*</sup>
                        </strong>
                        <select
                          name="recaptcha_status"
                          id="language_id"
                          className="form-control label_dimension input_height col-8"
                        >
                          <option value="1">Hide</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary anchor_font"
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </form>
                {error && <p style={{ color: "red" }}>Error: {error}</p>}
                {success && (
                  <p style={{ color: "green" }}>Success: {success}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Captcha;
