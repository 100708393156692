import React, { useState, useEffect } from "react";
import "../Pages/EditPage/editpage.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const AllMembers = () => {
  const [members, setMembers] = useState({});

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 3000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 3000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  useEffect(() => {
    const membersData = async () => {
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readMember`);

        setMembers(response.data);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
      }
    };

    membersData();
  }, []);

  const tableData = Array.isArray(members) ? members : [];

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.delete(
        `${ngrokUrl.url}/api/deleteMember/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      setSuccess("Projects deleted successfully.");
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete projects.", error);
      setError("Failed to delete projects.");
    }
  };

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">All members</h1>
      <div className="card shadow mb-4">
        <div className="card-header templete py-3">
          <h6 className="m-0 font-weight-bold text-primary">All members</h6>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div className="mb-4">
              <Link
                to="/dashboard/customtemplate/2/edit"
                className="dashboardBtn"
              >
                Back to About page
              </Link>
              <Link to="/dashboard/createMember" className="dashboardBtn ml-3">
                Create
              </Link>
            </div>

            <form className="form-inline">
              <table
                className="table table-bordered m-0"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Position</th>
                    <th scope="colgroup" className="header_heady " colSpan="2">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Position</th>
                    <th scope="colgroup" className="header_heady " colSpan="2">
                      Actions
                    </th>
                  </tr>
                </tfoot>
                <tbody>
                  {tableData.map((member) => (
                    <tr key={member.id}>
                      <td>
                        <img
                          height="100"
                          src={member.photo_url}
                          alt={member.name}
                        />
                      </td>
                      <td data-label="link">{member.name}</td>
                      <td data-label="link">{member.position}</td>
                      <td className="body-url" data-label="BODY">
                        <Link
                          to={`/dashboard/Editmember/${member.id}/edit?language=en`}
                          className="action-button"
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Link>

                        <FontAwesomeIcon
                          icon={faTrash}
                          className="action-button"
                          onClick={() => handleDelete(member.id)}
                          style={{ marginLeft: "25px" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {error && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {error}
                </div>
              )}
              {success && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {success}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllMembers;
