import React, { useState } from "react";
import "./worldmap.css";
import { Chart } from "react-google-charts";

export const data = [
  ["Country", "Popularity"],
  ["Germany", 200],
  ["United States", 300],
  ["Brazil", 400],
  ["Canada", 500],
  ["France", 600],
  ["RU", 700],
];

const Worldmap = () => {
  const [chartOptions, setChartOptions] = useState({
    colorAxis: { colors: ["#6022ea", "#4CAF50"] },
  });
  return (
    <div>
      <Chart
        chartEvents={[
          {
            eventName: "select",
            callback: ({ chartWrapper }) => {
              const chart = chartWrapper.getChart();
              const selection = chart.getSelection();
              if (selection.length === 0) return;
              const region = data[selection[0].row + 1];
              console.log("Selected : " + region);
              setChartOptions({
                ...chartOptions,
                region: region[0],
                colorAxis: { colors: ["#4CAF50", "#6022ea"] },
              });
            },
          },
        ]}
        chartType="GeoChart"
        width="100%"
        height="400px"
        data={data}
        options={chartOptions}
      />
    </div>
  );
};

export default Worldmap;
