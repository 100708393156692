import React, { useState, useEffect } from "react";
import "./template.css";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";
import "../../Pages/EditPage/editpage.css";

const NivaCMS = () => {
  const [funfacts, setFunFacts] = useState({});
  const [about, setAbout] = useState({});
  const [sections, setSections] = useState({});
  const [portfolio, SetPortfolio] = useState({});
  const [blog, setBlog] = useState({});
  const [seo, setSeo] = useState({});

  const [loadingfun, setLoadingfun] = useState(false);
  const [errorfun, setErrorfun] = useState(null);
  const [successfun, setSuccessfun] = useState(null);

  const [loadingabout, setLoadingabout] = useState(false);
  const [errorabout, setErrorabout] = useState(null);
  const [successabout, setSuccessabout] = useState(null);

  const [loadingsections, setLoadingsections] = useState(false);
  const [errorsections, setErrorsections] = useState(null);
  const [successsections, setSuccesssections] = useState(null);

  const [loadingPortfolio, setLoadingPortfolio] = useState(false);
  const [errorPortfolio, setErrorPortfolio] = useState(null);
  const [successPortfolio, setSuccessPortfolio] = useState(null);

  const [loadingBlog, setLoadingBlog] = useState(false);
  const [errorBlog, setErrorBlog] = useState(null);
  const [successBlog, setSuccessBlog] = useState(null);

  const [loadingSeo, setLoadingSeo] = useState(false);
  const [errorSeo, setErrorSeo] = useState(null);
  const [successSeo, setSuccessSeo] = useState(null);

  useEffect(() => {
    const timeouts = {};

    const manageTimeout = (state, setter, key) => {
      if (state) {
        timeouts[key] = setTimeout(() => setter(null), 2000);
      }
    };

    manageTimeout(errorfun, setErrorfun, "errorfun");
    manageTimeout(successfun, setSuccessfun, "successfun");
    manageTimeout(errorabout, setErrorabout, "errorabout");
    manageTimeout(successabout, setSuccessabout, "successabout");
    manageTimeout(errorsections, setErrorsections, "errorsections");
    manageTimeout(successsections, setSuccesssections, "successsections");
    manageTimeout(errorPortfolio, setErrorPortfolio, "errorPortfolio");
    manageTimeout(successPortfolio, setSuccessPortfolio, "successPortfolio");
    manageTimeout(errorBlog, setErrorBlog, "errorBlog");
    manageTimeout(successBlog, setSuccessBlog, "successBlog");
    manageTimeout(errorSeo, setErrorSeo, "errorSeo");
    manageTimeout(successSeo, setSuccessSeo, "successSeo");

    return () => {
      Object.values(timeouts).forEach(clearTimeout);
    };
  }, [
    errorfun,
    errorabout,
    errorsections,
    errorPortfolio,
    errorBlog,
    errorSeo,
    successfun,
    successabout,
    successsections,
    successPortfolio,
    successBlog,
    successSeo,
  ]);

  //fecth values
  useEffect(() => {
    const FunFactValues = async () => {
      setLoadingfun(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readFunFacts/1`);
        setFunFacts(response.data);
        setLoadingfun(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorfun("Failed to fetch default values.");
        setLoadingfun(false);
      }
    };
    FunFactValues();
  }, []);

  useEffect(() => {
    const AboutValues = async () => {
      setLoadingabout(true);
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readSectionAbout/1`
        );
        setAbout(response.data);
        setLoadingabout(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorabout("Failed to fetch default values.");
        setLoadingabout(false);
      }
    };
    AboutValues();
  }, []);

  useEffect(() => {
    const SectionValues = async () => {
      setLoadingsections(true);
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readSectionServiceMainTitleById/1`
        );
        setSections(response.data);
        setLoadingsections(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorsections("Failed to fetch default values.");
        setLoadingsections(false);
      }
    };
    SectionValues();
  }, []);

  useEffect(() => {
    const portfolioValues = async () => {
      setLoadingPortfolio(true);
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readSectionPortfolioId/1`
        );
        SetPortfolio(response.data);
        setLoadingPortfolio(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorPortfolio("Failed to fetch default values.");
        setLoadingPortfolio(false);
      }
    };
    portfolioValues();
  }, []);

  useEffect(() => {
    const BlogValues = async () => {
      setLoadingBlog(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readBlogId/1`);
        setBlog(response.data);
        setLoadingBlog(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorBlog("Failed to fetch default values.");
        setLoadingBlog(false);
      }
    };
    BlogValues();
  }, []);

  useEffect(() => {
    const SeoValues = async () => {
      setLoadingSeo(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readNivaCMS/1`);
        setSeo(response.data);
        setLoadingSeo(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorSeo("Failed to fetch default values.");
        setLoadingSeo(false);
      }
    };
    SeoValues();
  }, []);

  //onChange
  const handlefunfactChange = (e) => {
    const { name, value, type } = e.target;
    setFunFacts({
      ...funfacts,
      [name]: value,
    });
  };
  const handleAboutChange = (e) => {
    const { name, value } = e.target;
    setAbout({
      ...about,
      [name]: value,
    });
  };

  const handleSectionsChange = (e) => {
    const { name, value } = e.target;
    setSections({
      ...sections,
      [name]: value,
    });
  };

  const handlePortfolioChange = (e) => {
    const { name, value } = e.target;
    SetPortfolio({
      ...portfolio,
      [name]: value,
    });
  };

  const handleBlogChange = (e) => {
    const { name, value } = e.target;
    setBlog({
      ...blog,
      [name]: value,
    });
  };

  const handleSeoChange = (e) => {
    const { name, value } = e.target;
    setSeo({
      ...seo,
      [name]: value,
    });
  };

  //onSubmit
  const handleFunfactSubmit = async (e) => {
    e.preventDefault();
    setLoadingfun(true);
    setErrorfun(null);
    setSuccessfun(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateFunFacts/1`,
        funfacts,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setFunFacts(response.data);
        setSuccessfun("Fun Facts Updated Successfully!");
        setLoadingfun(false);
        window.location.reload();
      } else {
        throw new Error("Failed to updated Fun Facts ");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setErrorfun("Failed to update Fun Facts ");
      setLoadingfun(false);
    }
  };

  const handleAboutSubmit = async (e) => {
    e.preventDefault();
    setLoadingabout(true);
    setErrorabout(null);
    setSuccessabout(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateSectionAbout/1`,
        about,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setAbout(response.data);
        setSuccessabout("Section About Updated Successfully!");
        setLoadingabout(false);
        window.location.reload();
      } else {
        throw new Error("Failed to updated Section About ");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setErrorabout("Failed to update Section About ");
      setLoadingabout(false);
    }
  };

  const handleSectionSubmit = async (e) => {
    e.preventDefault();
    setLoadingsections(true);
    setErrorsections(null);
    setSuccesssections(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateSectionServiceMainTitle/1`,
        sections,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setSections(response.data);
        setSuccesssections("Section Services Updated Successfully!");
        setLoadingsections(false);
      } else {
        throw new Error("Failed to updated Section Services");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setErrorsections("Failed to update Section Services");
      setLoadingsections(false);
    }
  };

  const handlePortfolioSubmit = async (e) => {
    e.preventDefault();
    setLoadingPortfolio(true);
    setErrorPortfolio(null);
    setSuccessPortfolio(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateSectionPortfolio/1`,
        portfolio,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        SetPortfolio(response.data);
        setSuccessPortfolio("Section Portfolio Updated Successfully!");
        setLoadingPortfolio(false);
        window.location.reload();
      } else {
        throw new Error("Failed to updated Section Portfolio");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setErrorPortfolio("Failed to update Section Portfolio");
      setLoadingPortfolio(false);
    }
  };

  const handleBlogSubmit = async (e) => {
    e.preventDefault();
    setLoadingBlog(true);
    setErrorBlog(null);
    setSuccessBlog(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateBlog/1`,
        blog,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        setBlog(response.data);
        setSuccessBlog("Section Blog Updated Successfully!");
        setLoadingBlog(false);
        window.location.reload();
      } else {
        throw new Error("Failed to updated Section Blog");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);
      setErrorBlog("Failed to update Section Blog");
      setLoadingBlog(false);
    }
  };

  const handleSeoSubmit = async (e) => {
    e.preventDefault();
    setLoadingSeo(true);
    setErrorSeo(null);
    setSuccessSeo(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateNivaCMS/1`,
        seo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        setSeo(response.data);
        setSuccessSeo("Seo Updated Successfully!");
        setLoadingSeo(false);
        window.location.reload();
      } else {
        throw new Error("Failed to updated Seo");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);
      setErrorSeo("Failed to update Seo");
      setLoadingSeo(false);
    }
  };
  console.log("funfacs", funfacts);

  return (
    <>
      <div className="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">Home Settings</h1>

        <div className="col-md-12">
          <div className="card shadow mb-4">
            <div className="card-header templete py-3">
              <h6 className="m-0 font-weight-bold text-primary p-3">
                Section - Main Slider
              </h6>
            </div>
            <div className="card-body pt-4 pb-5 mt-3 mt-3">
              <a
                className="dashboardBtn  mt-3 "
                href="/dashboard/manageslider?language=en"
              >
                View All
              </a>
              <a
                className="dashboardBtn  ml-3 mt-3 "
                href="/dashboard/createSlider?language=en"
              >
                Create
              </a>
            </div>
          </div>

          <div className="card shadow  mb-4">
            <div className="card-header templete py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Section - Fun facts
              </h6>
            </div>
            <div className="card-body">
              <form onSubmit={handleFunfactSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="fs7duLCZoHdpbNbkAAIUpuI58AMy5gQaEGtcx61Z"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Title</h5>
                          <input
                            type="text"
                            name="title"
                            className="formControl"
                            value={funfacts.title}
                            onChange={handlefunfactChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Description</h5>
                          <input
                            type="text"
                            name="description"
                            className="formControl"
                            value={funfacts.description}
                            onChange={handlefunfactChange}
                            style={{ fontSize: "16px" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <div className="formgroup">
                          <h5>count number 1</h5>
                          <input
                            type="text"
                            name="count_num_one"
                            className="formControl"
                            value={funfacts.count_num_one}
                            onChange={handlefunfactChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="formgroup">
                          <h5>count number 2</h5>
                          <input
                            type="text"
                            name="count_num_two"
                            className="formControl"
                            value={funfacts.count_num_two}
                            onChange={handlefunfactChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="formgroup">
                          <h5>count number 3</h5>
                          <input
                            type="text"
                            name="count_num_three"
                            className="formControl"
                            value={funfacts.count_num_three}
                            onChange={handlefunfactChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="formgroup">
                          <h5>count number 4</h5>
                          <input
                            type="text"
                            name="count_num_four"
                            className="formControl"
                            value={funfacts.count_num_four}
                            onChange={handlefunfactChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <div className="formgroup">
                          <h5>count text 1</h5>
                          <input
                            type="text"
                            name="count_text_one"
                            className="formControl"
                            value={funfacts.count_text_one}
                            onChange={handlefunfactChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="formgroup">
                          <h5>count text 2</h5>
                          <input
                            type="text"
                            name="count_text_two"
                            className="formControl"
                            value={funfacts.count_text_two}
                            onChange={handlefunfactChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="formgroup">
                          <h5>count text 3</h5>
                          <input
                            type="text"
                            name="count_text_three"
                            className="formControl"
                            value={funfacts.count_text_three}
                            onChange={handlefunfactChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="formgroup">
                          <h5>count text 4</h5>
                          <input
                            type="text"
                            name="count_text_four"
                            className="formControl"
                            value={funfacts.count_text_four}
                            onChange={handlefunfactChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-12 text-right ">
                    <button type="submit" className=" cardBtn">
                      Update
                    </button>
                  </div>
                </div>
              </form>
              {errorfun && (
                <div style={{ color: "red", fontSize: "14px" }}>{errorfun}</div>
              )}
              {successfun && (
                <div style={{ color: "green", fontSize: "14px" }}>
                  {successfun}
                </div>
              )}
            </div>
          </div>

          {/* section about */}
          <div className="card shadow mb-4">
            <div className="card-header  templete py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Section - About
              </h6>
            </div>
            <div className="card-body">
              <form onSubmit={handleAboutSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="fs7duLCZoHdpbNbkAAIUpuI58AMy5gQaEGtcx61Z"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Title</h5>
                          <input
                            type="text"
                            name="title"
                            className="formControl"
                            value={about.title}
                            onChange={handleAboutChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Subtitle</h5>
                          <input
                            type="text"
                            name="subtitle"
                            className="formControl"
                            value={about.subtitle}
                            onChange={handleAboutChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="formgroup">
                      <h5>Description</h5>
                      <textarea
                        name="description"
                        className="formControl"
                        rows="5"
                        // id="mce_0"
                        // aria-hidden="true"
                        // style="display: none;"
                        style={{
                          borderStyle: "none",
                          fontSize: "16px",
                        }}
                        value={about.description}
                        onChange={handleAboutChange}
                      ></textarea>
                      <div
                        role="application"
                        className="tox tox-tinymce tox-tinymce--disabled"
                        aria-disabled="true"
                        // style="visibility: hidden; height: 200px;"
                        style={{ visibility: "hidden", height: "0" }}
                      >
                        <div className="tox-editor-container">
                          <div
                            data-alloy-vertical-dir="toptobottom"
                            className="tox-editor-header"
                          >
                            <div
                              role="menubar"
                              data-alloy-tabstop="true"
                              className="tox-menubar"
                            >
                              <button
                                aria-haspopup="true"
                                role="menuitem"
                                type="button"
                                tabindex="-1"
                                data-alloy-tabstop="true"
                                unselectable="on"
                                className="tox-mbtn tox-mbtn--select"
                                disabled="disabled"
                                aria-expanded="false"
                                // style="user-select: none;"
                                style={{ userSelect: "none" }}
                              >
                                <span className="tox-mbtn__select-label">
                                  File
                                </span>
                                <div className="tox-mbtn__select-chevron">
                                  <svg width="10" height="10" focusable="false">
                                    <path
                                      d="M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 010-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8z"
                                      fill-rule="nonzero"
                                    ></path>
                                  </svg>
                                </div>
                              </button>
                              <button
                                aria-haspopup="true"
                                role="menuitem"
                                type="button"
                                tabindex="-1"
                                data-alloy-tabstop="true"
                                unselectable="on"
                                className="tox-mbtn tox-mbtn--select"
                                disabled="disabled"
                                aria-expanded="false"
                                style={{ userSelect: "none" }}
                              >
                                <span className="tox-mbtn__select-label">
                                  Edit
                                </span>
                                <div className="tox-mbtn__select-chevron">
                                  <svg width="10" height="10" focusable="false">
                                    <path
                                      d="M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 010-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8z"
                                      fill-rule="nonzero"
                                    ></path>
                                  </svg>
                                </div>
                              </button>
                              <button
                                aria-haspopup="true"
                                role="menuitem"
                                type="button"
                                tabindex="-1"
                                data-alloy-tabstop="true"
                                unselectable="on"
                                className="tox-mbtn tox-mbtn--select"
                                disabled="disabled"
                                aria-expanded="false"
                                style={{ userSelect: "none" }}
                              >
                                <span className="tox-mbtn__select-label">
                                  View
                                </span>
                                <div className="tox-mbtn__select-chevron">
                                  <svg width="10" height="10" focusable="false">
                                    <path
                                      d="M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 010-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8z"
                                      fill-rule="nonzero"
                                    ></path>
                                  </svg>
                                </div>
                              </button>
                              <button
                                aria-haspopup="true"
                                role="menuitem"
                                type="button"
                                tabindex="-1"
                                data-alloy-tabstop="true"
                                unselectable="on"
                                className="tox-mbtn tox-mbtn--select"
                                disabled="disabled"
                                aria-expanded="false"
                                style={{ userSelect: "none" }}
                              >
                                <span className="tox-mbtn__select-label">
                                  Insert
                                </span>
                                <div className="tox-mbtn__select-chevron">
                                  <svg width="10" height="10" focusable="false">
                                    <path
                                      d="M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 010-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8z"
                                      fill-rule="nonzero"
                                    ></path>
                                  </svg>
                                </div>
                              </button>
                              <button
                                aria-haspopup="true"
                                role="menuitem"
                                type="button"
                                tabindex="-1"
                                data-alloy-tabstop="true"
                                unselectable="on"
                                className="tox-mbtn tox-mbtn--select"
                                disabled="disabled"
                                aria-expanded="false"
                                style={{ userSelect: "none" }}
                              >
                                <span className="tox-mbtn__select-label">
                                  Format
                                </span>
                                <div className="tox-mbtn__select-chevron">
                                  <svg width="10" height="10" focusable="false">
                                    <path
                                      d="M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 010-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8z"
                                      fill-rule="nonzero"
                                    ></path>
                                  </svg>
                                </div>
                              </button>
                              <button
                                aria-haspopup="true"
                                role="menuitem"
                                type="button"
                                tabindex="-1"
                                data-alloy-tabstop="true"
                                unselectable="on"
                                className="tox-mbtn tox-mbtn--select"
                                disabled="disabled"
                                aria-expanded="false"
                                style={{ userSelect: "none" }}
                              >
                                <span className="tox-mbtn__select-label">
                                  Tools
                                </span>
                                <div className="tox-mbtn__select-chevron">
                                  <svg width="10" height="10" focusable="false">
                                    <path
                                      d="M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 010-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8z"
                                      fill-rule="nonzero"
                                    ></path>
                                  </svg>
                                </div>
                              </button>
                              <button
                                aria-haspopup="true"
                                role="menuitem"
                                type="button"
                                tabindex="-1"
                                data-alloy-tabstop="true"
                                unselectable="on"
                                className="tox-mbtn tox-mbtn--select"
                                disabled="disabled"
                                aria-expanded="false"
                                style={{ userSelect: "none" }}
                              >
                                <span className="tox-mbtn__select-label">
                                  Table
                                </span>
                                <div className="tox-mbtn__select-chevron">
                                  <svg width="10" height="10" focusable="false">
                                    <path
                                      d="M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 010-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8z"
                                      fill-rule="nonzero"
                                    ></path>
                                  </svg>
                                </div>
                              </button>
                            </div>
                            <div
                              role="group"
                              className="tox-toolbar-overlord tox-tbtn--disabled"
                              aria-disabled="true"
                            >
                              <div
                                role="group"
                                className="tox-toolbar__primary"
                              >
                                <div
                                  title=""
                                  role="toolbar"
                                  data-alloy-tabstop="true"
                                  tabindex="-1"
                                  className="tox-toolbar__group"
                                >
                                  <button
                                    aria-label="Undo"
                                    title="Undo"
                                    type="button"
                                    tabindex="-1"
                                    className="tox-tbtn tox-tbtn--disabled"
                                    aria-disabled="true"
                                  >
                                    <span className="tox-icon tox-tbtn__icon-wrap">
                                      <svg
                                        width="24"
                                        height="24"
                                        focusable="false"
                                      >
                                        <path
                                          d="M6.4 8H12c3.7 0 6.2 2 6.8 5.1.6 2.7-.4 5.6-2.3 6.8a1 1 0 01-1-1.8c1.1-.6 1.8-2.7 1.4-4.6-.5-2.1-2.1-3.5-4.9-3.5H6.4l3.3 3.3a1 1 0 11-1.4 1.4l-5-5a1 1 0 010-1.4l5-5a1 1 0 011.4 1.4L6.4 8z"
                                          fill-rule="nonzero"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                  <button
                                    aria-label="Redo"
                                    title="Redo"
                                    type="button"
                                    tabindex="-1"
                                    className="tox-tbtn tox-tbtn--disabled"
                                    aria-disabled="true"
                                  >
                                    <span className="tox-icon tox-tbtn__icon-wrap">
                                      <svg
                                        width="24"
                                        height="24"
                                        focusable="false"
                                      >
                                        <path
                                          d="M17.6 10H12c-2.8 0-4.4 1.4-4.9 3.5-.4 2 .3 4 1.4 4.6a1 1 0 11-1 1.8c-2-1.2-2.9-4.1-2.3-6.8.6-3 3-5.1 6.8-5.1h5.6l-3.3-3.3a1 1 0 111.4-1.4l5 5a1 1 0 010 1.4l-5 5a1 1 0 01-1.4-1.4l3.3-3.3z"
                                          fill-rule="nonzero"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                                <div
                                  title=""
                                  role="toolbar"
                                  data-alloy-tabstop="true"
                                  tabindex="-1"
                                  className="tox-toolbar__group"
                                >
                                  <button
                                    title="Formats"
                                    aria-label="Formats"
                                    aria-haspopup="true"
                                    type="button"
                                    tabindex="-1"
                                    unselectable="on"
                                    className="tox-tbtn tox-tbtn--select tox-tbtn--bespoke"
                                    disabled="disabled"
                                    aria-expanded="false"
                                    style={{ userSelect: "none" }}
                                  >
                                    <span className="tox-tbtn__select-label">
                                      Paragraph
                                    </span>
                                    <div className="tox-tbtn__select-chevron">
                                      <svg
                                        width="10"
                                        height="10"
                                        focusable="false"
                                      >
                                        <path
                                          d="M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 010-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8z"
                                          fill-rule="nonzero"
                                        ></path>
                                      </svg>
                                    </div>
                                  </button>
                                </div>
                                <div
                                  title=""
                                  role="toolbar"
                                  data-alloy-tabstop="true"
                                  tabindex="-1"
                                  className="tox-toolbar__group"
                                >
                                  <button
                                    aria-label="Bold"
                                    title="Bold"
                                    type="button"
                                    tabindex="-1"
                                    className="tox-tbtn tox-tbtn--disabled"
                                    aria-disabled="true"
                                    aria-pressed="false"
                                  >
                                    <span className="tox-icon tox-tbtn__icon-wrap">
                                      <svg
                                        width="24"
                                        height="24"
                                        focusable="false"
                                      >
                                        <path
                                          d="M7.8 19c-.3 0-.5 0-.6-.2l-.2-.5V5.7c0-.2 0-.4.2-.5l.6-.2h5c1.5 0 2.7.3 3.5 1 .7.6 1.1 1.4 1.1 2.5a3 3 0 01-.6 1.9c-.4.6-1 1-1.6 1.2.4.1.9.3 1.3.6s.8.7 1 1.2c.4.4.5 1 .5 1.6 0 1.3-.4 2.3-1.3 3-.8.7-2.1 1-3.8 1H7.8zm5-8.3c.6 0 1.2-.1 1.6-.5.4-.3.6-.7.6-1.3 0-1.1-.8-1.7-2.3-1.7H9.3v3.5h3.4zm.5 6c.7 0 1.3-.1 1.7-.4.4-.4.6-.9.6-1.5s-.2-1-.7-1.4c-.4-.3-1-.4-2-.4H9.4v3.8h4z"
                                          fill-rule="evenodd"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                  <button
                                    aria-label="Italic"
                                    title="Italic"
                                    type="button"
                                    tabindex="-1"
                                    className="tox-tbtn tox-tbtn--disabled"
                                    aria-disabled="true"
                                    aria-pressed="false"
                                  >
                                    <span className="tox-icon tox-tbtn__icon-wrap">
                                      <svg
                                        width="24"
                                        height="24"
                                        focusable="false"
                                      >
                                        <path
                                          d="M16.7 4.7l-.1.9h-.3c-.6 0-1 0-1.4.3-.3.3-.4.6-.5 1.1l-2.1 9.8v.6c0 .5.4.8 1.4.8h.2l-.2.8H8l.2-.8h.2c1.1 0 1.8-.5 2-1.5l2-9.8.1-.5c0-.6-.4-.8-1.4-.8h-.3l.2-.9h5.8z"
                                          fill-rule="evenodd"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                                <div
                                  title=""
                                  role="toolbar"
                                  data-alloy-tabstop="true"
                                  tabindex="-1"
                                  className="tox-toolbar__group"
                                >
                                  <button
                                    aria-label="Align left"
                                    title="Align left"
                                    type="button"
                                    tabindex="-1"
                                    className="tox-tbtn tox-tbtn--disabled"
                                    aria-disabled="true"
                                    aria-pressed="false"
                                  >
                                    <span className="tox-icon tox-tbtn__icon-wrap">
                                      <svg
                                        width="24"
                                        height="24"
                                        focusable="false"
                                      >
                                        <path
                                          d="M5 5h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 110-2zm0 4h8c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 110-2zm0 8h8c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 010-2zm0-4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 010-2z"
                                          fill-rule="evenodd"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                  <button
                                    aria-label="Align center"
                                    title="Align center"
                                    type="button"
                                    tabindex="-1"
                                    className="tox-tbtn tox-tbtn--disabled"
                                    aria-disabled="true"
                                    aria-pressed="false"
                                  >
                                    <span className="tox-icon tox-tbtn__icon-wrap">
                                      <svg
                                        width="24"
                                        height="24"
                                        focusable="false"
                                      >
                                        <path
                                          d="M5 5h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 110-2zm3 4h8c.6 0 1 .4 1 1s-.4 1-1 1H8a1 1 0 110-2zm0 8h8c.6 0 1 .4 1 1s-.4 1-1 1H8a1 1 0 010-2zm-3-4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 010-2z"
                                          fill-rule="evenodd"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                  <button
                                    aria-label="Align right"
                                    title="Align right"
                                    type="button"
                                    tabindex="-1"
                                    className="tox-tbtn tox-tbtn--disabled"
                                    aria-disabled="true"
                                    aria-pressed="false"
                                  >
                                    <span className="tox-icon tox-tbtn__icon-wrap">
                                      <svg
                                        width="24"
                                        height="24"
                                        focusable="false"
                                      >
                                        <path
                                          d="M5 5h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 110-2zm6 4h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm0 8h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm-6-4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 010-2z"
                                          fill-rule="evenodd"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                  <button
                                    aria-label="Justify"
                                    title="Justify"
                                    type="button"
                                    tabindex="-1"
                                    className="tox-tbtn tox-tbtn--disabled"
                                    aria-disabled="true"
                                    aria-pressed="false"
                                  >
                                    <span className="tox-icon tox-tbtn__icon-wrap">
                                      <svg
                                        width="24"
                                        height="24"
                                        focusable="false"
                                      >
                                        <path
                                          d="M5 5h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 110-2zm0 4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 110-2zm0 4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 010-2zm0 4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 010-2z"
                                          fill-rule="evenodd"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                                <div
                                  title=""
                                  role="toolbar"
                                  data-alloy-tabstop="true"
                                  tabindex="-1"
                                  className="tox-toolbar__group"
                                >
                                  <div
                                    aria-pressed="false"
                                    aria-label="Bullet list"
                                    title="Bullet list"
                                    role="button"
                                    aria-haspopup="true"
                                    tabindex="-1"
                                    unselectable="on"
                                    className="tox-split-button tox-tbtn--disabled"
                                    aria-disabled="true"
                                    aria-expanded="false"
                                    aria-describedby="aria_14889691531718961209080"
                                    style={{ userSelect: "none" }}
                                  >
                                    <span
                                      role="presentation"
                                      className="tox-tbtn tox-tbtn--disabled"
                                      aria-disabled="true"
                                    >
                                      <span className="tox-icon tox-tbtn__icon-wrap">
                                        <svg
                                          width="24"
                                          height="24"
                                          focusable="false"
                                        >
                                          <path
                                            d="M11 5h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm0 6h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm0 6h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zM4.5 6c0-.4.1-.8.4-1 .3-.4.7-.5 1.1-.5.4 0 .8.1 1 .4.4.3.5.7.5 1.1 0 .4-.1.8-.4 1-.3.4-.7.5-1.1.5-.4 0-.8-.1-1-.4-.4-.3-.5-.7-.5-1.1zm0 6c0-.4.1-.8.4-1 .3-.4.7-.5 1.1-.5.4 0 .8.1 1 .4.4.3.5.7.5 1.1 0 .4-.1.8-.4 1-.3.4-.7.5-1.1.5-.4 0-.8-.1-1-.4-.4-.3-.5-.7-.5-1.1zm0 6c0-.4.1-.8.4-1 .3-.4.7-.5 1.1-.5.4 0 .8.1 1 .4.4.3.5.7.5 1.1 0 .4-.1.8-.4 1-.3.4-.7.5-1.1.5-.4 0-.8-.1-1-.4-.4-.3-.5-.7-.5-1.1z"
                                            fill-rule="evenodd"
                                          ></path>
                                        </svg>
                                      </span>
                                    </span>
                                    <span
                                      role="presentation"
                                      className="tox-tbtn tox-split-button__chevron tox-tbtn--disabled"
                                      aria-disabled="true"
                                    >
                                      <svg
                                        width="10"
                                        height="10"
                                        focusable="false"
                                      >
                                        <path
                                          d="M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 010-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8z"
                                          fill-rule="nonzero"
                                        ></path>
                                      </svg>
                                    </span>
                                    <span
                                      aria-hidden="true"
                                      id="aria_14889691531718961209080"
                                      style={{ display: "none" }}
                                    >
                                      To open the popup, press Shift+Enter
                                    </span>
                                  </div>
                                  <div
                                    aria-pressed="false"
                                    aria-label="Numbered list"
                                    title="Numbered list"
                                    role="button"
                                    aria-haspopup="true"
                                    tabindex="-1"
                                    unselectable="on"
                                    className="tox-split-button tox-tbtn--disabled"
                                    aria-disabled="true"
                                    aria-expanded="false"
                                    aria-describedby="aria_1169656801551718961209082"
                                    style={{ userSelect: "none" }}
                                  >
                                    <span
                                      role="presentation"
                                      className="tox-tbtn tox-tbtn--disabled"
                                      aria-disabled="true"
                                    >
                                      <span className="tox-icon tox-tbtn__icon-wrap">
                                        <svg
                                          width="24"
                                          height="24"
                                          focusable="false"
                                        >
                                          <path
                                            d="M10 17h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm0-6h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm0-6h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 110-2zM6 4v3.5c0 .3-.2.5-.5.5a.5.5 0 01-.5-.5V5h-.5a.5.5 0 010-1H6zm-1 8.8l.2.2h1.3c.3 0 .5.2.5.5s-.2.5-.5.5H4.9a1 1 0 01-.9-1V13c0-.4.3-.8.6-1l1.2-.4.2-.3a.2.2 0 00-.2-.2H4.5a.5.5 0 01-.5-.5c0-.3.2-.5.5-.5h1.6c.5 0 .9.4.9 1v.1c0 .4-.3.8-.6 1l-1.2.4-.2.3zM7 17v2c0 .6-.4 1-1 1H4.5a.5.5 0 010-1h1.2c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.3H4.4a.4.4 0 110-.8h1.3c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.3H4.5a.5.5 0 110-1H6c.6 0 1 .4 1 1z"
                                            fill-rule="evenodd"
                                          ></path>
                                        </svg>
                                      </span>
                                    </span>
                                    <span
                                      role="presentation"
                                      className="tox-tbtn tox-split-button__chevron tox-tbtn--disabled"
                                      aria-disabled="true"
                                    >
                                      <svg
                                        width="10"
                                        height="10"
                                        focusable="false"
                                      >
                                        <path
                                          d="M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 010-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8z"
                                          fill-rule="nonzero"
                                        ></path>
                                      </svg>
                                    </span>
                                    <span
                                      aria-hidden="true"
                                      id="aria_1169656801551718961209082"
                                      style={{ userSelect: "none" }}
                                    >
                                      To open the popup, press Shift+Enter
                                    </span>
                                  </div>
                                  <button
                                    aria-label="Decrease indent"
                                    title="Decrease indent"
                                    type="button"
                                    tabindex="-1"
                                    className="tox-tbtn tox-tbtn--disabled"
                                    aria-disabled="true"
                                  >
                                    <span className="tox-icon tox-tbtn__icon-wrap">
                                      <svg
                                        width="24"
                                        height="24"
                                        focusable="false"
                                      >
                                        <path
                                          d="M7 5h12c.6 0 1 .4 1 1s-.4 1-1 1H7a1 1 0 110-2zm5 4h7c.6 0 1 .4 1 1s-.4 1-1 1h-7a1 1 0 010-2zm0 4h7c.6 0 1 .4 1 1s-.4 1-1 1h-7a1 1 0 010-2zm-5 4h12a1 1 0 010 2H7a1 1 0 010-2zm1.6-3.8a1 1 0 01-1.2 1.6l-3-2a1 1 0 010-1.6l3-2a1 1 0 011.2 1.6L6.8 12l1.8 1.2z"
                                          fill-rule="evenodd"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                  <button
                                    aria-label="Increase indent"
                                    title="Increase indent"
                                    type="button"
                                    tabindex="-1"
                                    className="tox-tbtn tox-tbtn--disabled"
                                    aria-disabled="true"
                                  >
                                    <span className="tox-icon tox-tbtn__icon-wrap">
                                      <svg
                                        width="24"
                                        height="24"
                                        focusable="false"
                                      >
                                        <path
                                          d="M7 5h12c.6 0 1 .4 1 1s-.4 1-1 1H7a1 1 0 110-2zm5 4h7c.6 0 1 .4 1 1s-.4 1-1 1h-7a1 1 0 010-2zm0 4h7c.6 0 1 .4 1 1s-.4 1-1 1h-7a1 1 0 010-2zm-5 4h12a1 1 0 010 2H7a1 1 0 010-2zm-2.6-3.8L6.2 12l-1.8-1.2a1 1 0 011.2-1.6l3 2a1 1 0 010 1.6l-3 2a1 1 0 11-1.2-1.6z"
                                          fill-rule="evenodd"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                                <div
                                  title=""
                                  role="toolbar"
                                  data-alloy-tabstop="true"
                                  tabindex="-1"
                                  className="tox-toolbar__group"
                                >
                                  <button
                                    aria-label="Insert/edit link"
                                    title="Insert/edit link"
                                    type="button"
                                    tabindex="-1"
                                    className="tox-tbtn tox-tbtn--disabled"
                                    aria-disabled="true"
                                    aria-pressed="false"
                                  >
                                    <span className="tox-icon tox-tbtn__icon-wrap">
                                      <svg
                                        width="24"
                                        height="24"
                                        focusable="false"
                                      >
                                        <path
                                          d="M6.2 12.3a1 1 0 011.4 1.4l-2.1 2a2 2 0 102.7 2.8l4.8-4.8a1 1 0 000-1.4 1 1 0 111.4-1.3 2.9 2.9 0 010 4L9.6 20a3.9 3.9 0 01-5.5-5.5l2-2zm11.6-.6a1 1 0 01-1.4-1.4l2-2a2 2 0 10-2.6-2.8L11 10.3a1 1 0 000 1.4A1 1 0 119.6 13a2.9 2.9 0 010-4L14.4 4a3.9 3.9 0 015.5 5.5l-2 2z"
                                          fill-rule="nonzero"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="tox-anchorbar"></div>
                          </div>
                          <div className="tox-sidebar-wrap">
                            <div className="tox-edit-area">
                              <iframe
                                id="mce_0_ifr"
                                frameborder="0"
                                allowtransparency="true"
                                title="Rich Text Area"
                                className="tox-edit-area__iframe"
                              ></iframe>
                            </div>
                            <div role="complementary" className="tox-sidebar">
                              <div
                                data-alloy-tabstop="true"
                                tabindex="-1"
                                className="tox-sidebar__slider tox-sidebar--sliding-closed"
                                style={{ width: "0px" }}
                              >
                                <div className="tox-sidebar__pane-container"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tox-statusbar">
                          <div className="tox-statusbar__text-container">
                            <div
                              role="navigation"
                              data-alloy-tabstop="true"
                              className="tox-statusbar__path"
                              aria-disabled="true"
                            ></div>
                            <button
                              type="button"
                              tabindex="-1"
                              data-alloy-tabstop="true"
                              className="tox-statusbar__wordcount"
                              disabled="disabled"
                            >
                              49 words
                            </button>
                            <span className="tox-statusbar__branding">
                              <a
                                href="https://www.tiny.cloud/?utm_campaign=editor_referral&amp;utm_medium=poweredby&amp;utm_source=tinymce&amp;utm_content=v5"
                                rel="noopener"
                                target="_blank"
                                tabindex="-1"
                                aria-label="Powered by Tiny"
                              >
                                Powered by Tiny
                              </a>
                            </span>
                          </div>
                          <div
                            title="Resize"
                            data-alloy-tabstop="true"
                            tabindex="-1"
                            className="tox-statusbar__resize-handle"
                          >
                            <svg width="10" height="10" focusable="false">
                              <g fill-rule="nonzero">
                                <path d="M8.1 1.1A.5.5 0 119 2l-7 7A.5.5 0 111 8l7-7zM8.1 5.1A.5.5 0 119 6l-3 3A.5.5 0 115 8l3-3z"></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                        <div
                          aria-hidden="true"
                          className="tox-throbber"
                          style={{ userSelect: "none" }}
                        ></div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Button text</h5>
                          <input
                            type="text"
                            name="button_text"
                            className="formControl"
                            value={about.button_text}
                            onChange={handleAboutChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Button link</h5>
                          <input
                            type="text"
                            name="button_link"
                            className="formControl"
                            value={about.button_link}
                            onChange={handleAboutChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <p>
                            <h5>Photo</h5>{" "}
                            <span style={{ fontSize: "14px" }}>
                              Upload image{" "}
                              <a
                                target="_blank"
                                href="https://niva4.lucian.host/admin/media/create?language=en"
                                style={{ fontSize: "14px" }}
                              >
                                {" "}
                                here{" "}
                              </a>{" "}
                              then copy the URL from{" "}
                              <a
                                target="_blank"
                                href="https://niva4.lucian.host/admin/media?language=en"
                                style={{ fontSize: "14px" }}
                              >
                                {" "}
                                here{" "}
                              </a>
                            </span>
                          </p>

                          <input
                            type="text"
                            name="photo_one"
                            className="formControl"
                            value={about.photo_one}
                            onChange={handleAboutChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="formgroup"
                          style={{ padding: "25px", height: "145px" }}
                        >
                          <p>
                            {" "}
                            <h5>Photo</h5>
                          </p>
                          <input
                            type="text"
                            name="photo_two"
                            className="formControl"
                            value={about.photo_two}
                            onChange={handleAboutChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Years experience - number</h5>
                          <input
                            type="text"
                            name="year_experience_number"
                            className="formControl"
                            value={about.year_experience_number}
                            onChange={handleAboutChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Years experience - text</h5>
                          <input
                            type="text"
                            name="year_experience_text"
                            className="formControl"
                            value={about.year_experience_text}
                            onChange={handleAboutChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button type="submit" className=" cardBtn">
                      Update
                    </button>
                  </div>
                </div>
              </form>
              {errorabout && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {errorabout}
                </div>
              )}
              {successabout && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {successabout}{" "}
                </div>
              )}
            </div>
          </div>

          <div className="card shadow mb-4">
            <div className="card-header templete py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Section - Services
              </h6>
            </div>
            <div className="card-body mt-3">
              <div className="mb-4">
                <a
                  className="dashboardBtn"
                  href="/dashboard/manageservices?language=en"
                >
                  View All
                </a>
                <a
                  className="dashboardBtn ml-3 "
                  href="/dashboard/createServices?language=en"
                >
                  Create
                </a>
              </div>
              <form onSubmit={handleSectionSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="fs7duLCZoHdpbNbkAAIUpuI58AMy5gQaEGtcx61Z"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="formgroup">
                      <h5>Title</h5>
                      <input
                        type="text"
                        name="title"
                        className="formControl"
                        value={sections.title}
                        onChange={handleSectionsChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button type="submit" className="cardBtn">
                      Update
                    </button>
                  </div>
                </div>
              </form>
              {errorsections && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {errorsections}
                </div>
              )}
              {successsections && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {successsections}{" "}
                </div>
              )}
            </div>
          </div>

          <div className="card shadow mb-4">
            <div className="card-header templete  py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Section - Portfolio
              </h6>
            </div>
            <div className="card-body mt-3">
              <div className="mb-4">
                <a
                  className="dashboardBtn "
                  href="/dashboard/all-project?language=en"
                >
                  View All
                </a>
                <a
                  className="dashboardBtn ml-3"
                  href="/dashboard/createProject?language=en"
                >
                  Create
                </a>
              </div>
              <form onSubmit={handlePortfolioSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="fs7duLCZoHdpbNbkAAIUpuI58AMy5gQaEGtcx61Z"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>Title</h5>
                      <input
                        type="text"
                        name="title"
                        className="formControl"
                        value={portfolio.title}
                        onChange={handlePortfolioChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>Subtitle</h5>
                      <input
                        type="text"
                        name="subtitle"
                        className="formControl"
                        value={portfolio.subtitle}
                        onChange={handlePortfolioChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button type="submit" className=" cardBtn">
                      Update
                    </button>
                  </div>
                </div>
              </form>
              {errorPortfolio && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {errorPortfolio}
                </div>
              )}
              {successPortfolio && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {successPortfolio}{" "}
                </div>
              )}
            </div>
          </div>

          <div className="card shadow mb-4">
            <div className="card-header templete py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Section - Testimonials
              </h6>
            </div>
            <div className="card-body mt-3">
              <div className="mb-4">
                <a
                  className="dashboardBtn "
                  href="/dashboard/managetestimonials?language=en"
                >
                  View All
                </a>
                <a
                  className="dashboardBtn ml-3"
                  href="/dashboard/createTestimonials?language=en"
                >
                  Create
                </a>
              </div>
            </div>
          </div>

          <div className="card shadow mb-4">
            <div className="card-header templete py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Section - Blog
              </h6>
            </div>
            <div className="card-body mt-3">
              <div className="mb-4">
                <a
                  className="dashboardBtn "
                  href="/dashboard/allPosts?language=en"
                >
                  View All
                </a>
                <a
                  className="dashboardBtn ml-3"
                  href="/dashboard/createPost?language=en"
                >
                  Create
                </a>
              </div>
              <form onSubmit={handleBlogSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="fs7duLCZoHdpbNbkAAIUpuI58AMy5gQaEGtcx61Z"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>Title</h5>
                      <input
                        type="text"
                        name="title"
                        className="formControl"
                        value={blog.title}
                        onChange={handleBlogChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>Subtitle</h5>
                      <input
                        type="text"
                        name="subtitle"
                        className="formControl"
                        value={blog.subtitle}
                        onChange={handleBlogChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button type="submit" className="cardBtn" disabled="">
                      Update
                    </button>
                  </div>
                </div>
              </form>
              {errorBlog && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {errorBlog}
                </div>
              )}
              {successBlog && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {successBlog}{" "}
                </div>
              )}
            </div>
          </div>

          <div className="card shadow mb-4">
            <div className="card-header templete  py-3">
              <h6 className="m-0 font-weight-bold text-primary">SEO</h6>
            </div>
            <div className="card-body">
              <form onSubmit={handleSeoSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="qo6swE7Ef17bxcxuScLXpgr78RLOXpTnCBG1QWEh"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>meta title</h5>
                      <input
                        type="text"
                        name="meta_title"
                        className="formControl"
                        value={seo.meta_title}
                        onChange={handleSeoChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>meta description</h5>
                      <input
                        type="text"
                        name="meta_description"
                        className="formControl"
                        value={seo.meta_description}
                        onChange={handleSeoChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button type="submit" className=" cardBtn" disabled="">
                      Update
                    </button>
                  </div>
                </div>
              </form>
              {errorSeo && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {errorSeo}
                </div>
              )}
              {successSeo && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {successSeo}{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NivaCMS;
