import React from "react";
import "./About.css";
import { useState, useEffect } from "react";

import service1 from "../../../Images/services1.jpg";
import service2 from "../../../Images/service2.jpg";
import service3 from "../../../Images/service3.jpg";
import service4 from "../../../Images/service4.jpg";

import finance from "../../../Images/finance&banking.jpg";
import ecommerce from "../../../Images/ecommerce.jpg";
import realestate from "../../../Images/realestate.jpg";
import software from "../../../Images/software.jpg";
import healthfitness from "../../../Images/health&fitness.jpg";
import travel from "../../../Images/travel.jpg";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";

import ChooseUs from "./ChooseUs";
import Loader from "../../Loader/Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";

function About() {
  const [loading, setLoading] = useState(true);
  const [about, setAbout] = useState({});
  const [services, setServices] = useState({});
  const [sections, setSections] = useState({});
  const [error, setError] = useState(null);
  const [loadingabout, setLoadingabout] = useState(false);
  const [errorabout, setErrorabout] = useState(null);
  const [loadingsections, setLoadingsections] = useState(false);
  const [errorsections, setErrorsections] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const SectionValues = async () => {
      setLoadingsections(true);
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readSectionServiceMainTitleById/1`
        );
        setSections(response.data);
        setLoadingsections(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorsections("Failed to fetch default values.");
        setLoadingsections(false);
      }
    };
    SectionValues();
  }, []);

  useEffect(() => {
    const servicesValues = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${ngrokUrl.url}/api/readSectionServices`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        setServices(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    servicesValues();
  }, []);
  useEffect(() => {
    const AboutValues = async () => {
      setLoadingabout(true);
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readSectionAbout/1`
        );
        setAbout(response.data);
        setLoadingabout(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorabout("Failed to fetch default values.");
        setLoadingabout(false);
      }
    };
    AboutValues();
  }, []);

  const navigate = useNavigate();
  const options = {
    loop: true,
    margin: 10,
    responsiveClass: true,
    rtl: true,
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const backgroundStyle1 = {
    backgroundImage: `url(${service1})`,
  };
  const backgroundStyle2 = {
    backgroundImage: `url(${service2})`,
  };
  const backgroundStyle3 = {
    backgroundImage: `url(${service3})`,
  };
  const backgroundStyle4 = {
    backgroundImage: `url(${service4})`,
  };
  const [isMounted, setIsMounted] = useState(true);
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* about section */}
          <div className="about-section">
            <div className="container">
              <div className="row">
                <div className="col-md-7">
                  <div className="pictures-row">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="item-about">
                          <div className="imgone big-paral">
                            <img
                              src={about.photo_one}
                              className="lazy thumparallax img-fluid"
                              alt="About Image One"
                            />
                          </div>
                          <div className="exp-about">
                            <h5 className="nmb-font-about">
                              {about.year_experience_number}
                            </h5>
                            <h6 className="service_summary-about">
                              {about.year_experience_text}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="item-about">
                          <div className="imgtwo big-paral">
                            <img
                              src={about.photo_two}
                              className="lazy thumparallax img-fluid"
                              alt="About Image Two"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <h4 className="about-heading1-home text-start">
                    {about.subtitle}
                  </h4>
                  <h3 className="about-heading2-home">{about.title}</h3>
                  <p>
                    {about.description}
                    <br />
                    <span>
                      <a href="/about-us" className="readmore">
                        Read More
                      </a>
                    </span>
                    .
                  </p>
                  <a
                    className="btn btn-style1 aboutBtn"
                    href={about.button_link}
                  >
                    <span style={{ color: "white" }}>{about.button_text}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* overview section */}
          <div className="services-section">
            <div className="container">
              <h3 style={{ marginBottom: "15px" }}>Industries We Serve</h3>
              <p style={{ fontSize: "16px", textAlign: "justify" }}>
                Businesses are in a perpetual state of evolution, driven by a
                persistent quest to enhance operational efficiency and minimize
                costs. In the contemporary landscape, technology stands as a
                cornerstone in facilitating this evolution. YRP is uniquely
                poised to assist global businesses in attaining their digital
                transformation objectives by harnessing the power of Website, Application design & development, Augmented
                Reality (AR), Virtual Reality (VR), Artificial Intelligence
                (AI), and other digital technologies across diverse sectors.
              </p>
              <div className="row" style={{ rowGap: "25px" }}>
                <div className="item overview-section col-md-4">
                  <div className="service-box-slide">
                    <div
                      className="service-box center-alignment"
                      style={{ backgroundImage: `url(${finance})` }}
                    >
                      <h5>Finance & Banking</h5>
                    </div>
                  </div>
                </div>
                <div className="item overview-section col-md-4">
                  <div className="service-box-slide">
                    <div
                      className="service-box center-alignment"
                      style={{ backgroundImage: `url(${ecommerce})` }}
                    >
                      <h5>E-commerce</h5>
                    </div>
                  </div>
                </div>
                <div className="item overview-section col-md-4">
                  <div className="service-box-slide">
                    <div
                      className="service-box center-alignment"
                      style={{ backgroundImage: `url(${realestate})` }}
                    >
                      <h5>Real Estate</h5>
                    </div>
                  </div>
                </div>
                <div className="item overview-section col-md-4">
                  <div className="service-box-slide">
                    <div
                      className="service-box center-alignment"
                      style={{ backgroundImage: `url(${software})` }}
                    >
                      <h5>Software</h5>
                    </div>
                  </div>
                </div>
                <div className="item overview-section col-md-4">
                  <div className="service-box-slide">
                    <div
                      className="service-box center-alignment"
                      style={{ backgroundImage: `url(${healthfitness})` }}
                    >
                      <h5>Health & Fitness</h5>
                    </div>
                  </div>
                </div>
                <div className="item overview-section col-md-4">
                  <div className="service-box-slide">
                    <div
                      className="service-box center-alignment"
                      style={{ backgroundImage: `url(${travel})` }}
                    >
                      <h5>Travel</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <a
                className="btn btn-style1 industrialBtn"
                onClick={() => navigate("/Industries")}
              >
                <span style={{ color: "white" }}>View All Industries</span>
              </a>
            </div>
          </div>

          {/* services section */}

          <div className="services-section">
            <div className="container">
              <h3>{sections.title}</h3>
              <Swiper
                spaceBetween={30}
                slidesPerView={3}
                loop={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                breakpoints={{
                  300: { slidesPerView: 1 },
                  640: { slidesPerView: 1 },
                  768: { slidesPerView: 1 },
                  1024: { slidesPerView: 3 },
                }}
              >
                <>
                  {Array.isArray(services) && services.length > 0 ? (
                    services.map((service) => (
                      <SwiperSlide key={service.id}>
                        <div className="service-box-slide">
                          <div
                            className="service-box"
                            style={{
                              backgroundImage: `url(${service.photo_url})`,
                            }}
                          >
                            <i className={service.box_icon}></i>
                            <h5>{service.title}</h5>
                            <p>{service.description}</p>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))
                  ) : (
                    <p>No services available.</p>
                  )}
                </>
              </Swiper>
            </div>
          </div>
          {/* <Technologies /> */}
          <ChooseUs />
        </>
      )}
    </div>
  );
}
export default About;
