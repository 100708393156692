import React from "react";
import { Link } from "react-router-dom";

function BlogSetting() {
  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Blog settings</h1>

        <div className="pb-2 text-right">
          <select
            name="language"
            className="form-control language-control"
            onchange="window.location='https://niva4.lucian.host/admin/blog-settings?language='+this.value"
          >
            <option value="" selected disabled>
              Select a language
            </option>
            <option value="en" selected>
              English
            </option>
            <option value="pt">Portuguese</option>
            <option value="عربى">عربى</option>
          </select>
        </div>

        <div className="row">
          <div className="col-md-12">
            {/* <!-- blogs --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark label_dimension">
                  Blog settings
                </h6>
              </div>
              <div className="card-body">
                <div className="mb-3">
                  <Link
                    to="/dashboard/allPosts"
                    className="btn btn-primary anchor_font mr-3"
                  >
                    View All
                  </Link>
                  <Link
                    to="/dashboard/createPost"
                    className="btn btn-primary anchor_font"
                  >
                    Create
                  </Link>
                </div>
              </div>
            </div>
            {/* <!-- blogs --> */}

            {/* <!-- Sidebar --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  Sidebar
                </h6>
              </div>
              <div className="card-body">
                <form
                  action="https://niva4.lucian.host/admin/blog-settings/1/update"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="XVqRXn6iJK30czwrwmj6gRF5ebdrEvhj1txDKh7a"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Ad HTML 1</strong>
                        <textarea
                          name="html_sidebar1"
                          className="form-control"
                          rows="6"
                        >
                          {/* &lt;h3 className=&quot;widget-title&quot;&gt;About
                          us&lt;/h3&gt; &lt;div
                          className=&quot;textwidget&quot;&gt;&lt;a
                          href=&quot;/about-us&quot;&gt;&lt;img
                          className=&quot;html-widget-image img-fluid&quot;
                          src=&quot;/public/images/media/1615714364sidebar-img1.jpg&quot;
                          alt=&quot;&quot; /&gt;&lt;/a&gt; &lt;p
                          className=&quot;html-widget-paragraph&quot;&gt;Do you
                          believe that your brand needs help from a creative
                          team? Contact us to start working for your
                          project!&lt;/p&gt; &lt;a className=&quot;btn
                          btn-style1&quot; href=&quot;/about-us&quot;&gt;Read
                          More &lt;/a&gt;&lt;/div&gt; */}
                        </textarea>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Ad HTML 2</strong>
                        <textarea
                          name="html_sidebar2"
                          className="form-control"
                          rows="6"
                        >
                          {/* &lt;h3 className=&quot;widget-title&quot;&gt;Banner
                          ad&lt;/h3&gt; &lt;div
                          className=&quot;textwidget&quot;&gt;&lt;a
                          title=&quot;adsense&quot;
                          href=&quot;https://www.google.com/adsense/start/&quot;
                          target=&quot;_blank&quot; rel=&quot;noreferrer
                          noopener&quot;&gt;&lt;img
                          className=&quot;html-widget-image img-ad
                          img-fluid&quot;
                          src=&quot;/public/images/media/1615715240adsense500x500.png&quot;
                          alt=&quot;&quot; /&gt;&lt;/a&gt; &lt;/div&gt; */}
                        </textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary anchor_font"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- SEO --> */}

            {/* <!-- SEO --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  SEO
                </h6>
              </div>
              <div className="card-body">
                <form method="POST" enctype="multipart/form-data">
                  <input
                    type="hidden"
                    name="_token"
                    value="XVqRXn6iJK30czwrwmj6gRF5ebdrEvhj1txDKh7a"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">meta title</strong>
                        <input
                          type="text"
                          name="meta_title"
                          className="form-control label_dimension"
                          value="Our recent news"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">
                          meta description
                        </strong>
                        <input
                          type="text"
                          name="meta_description"
                          className="form-control label_dimension"
                          value="Do you believe that your brand needs help from a creative team? Contact us to start working for your project!"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Slug</strong>
                        <input
                          type="text"
                          name="slug"
                          className="form-control label_dimension"
                          value="blog"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Anchor text</strong>
                        <input
                          type="text"
                          name="breadcrumbs_anchor"
                          className="form-control label_dimension"
                          value="Home"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary anchor_font"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- SEO --> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogSetting;
