import React, { useState } from "react";
import "./dashboardnavbar.css";
import Profile from "../Images/about1.jpg";
import { SiZebratechnologies } from "react-icons/si";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "./data";

function DashboardNavbar({ setSidebar, sidebar }) {
  const [dismiss, setDismiss] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${ngrokUrl.url}/api/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      if (response.status === 200) {
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Failed to logout");
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };
  return (
    <>
      {/* <!-- Topbar --> */}
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        {/* <!-- Sidebar Toggle (Topbar) --> */}
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
          onClick={() => setSidebar(!sidebar)}
        >
          <i className="fa fa-bars"></i>
        </button>

        {/* <!-- Topbar Navbar --> */}
        <ul className="navbar-nav ml-auto ">
          <li>
            {" "}
            <a
              target="_blank"
              href="https://niva4.lucian.host"
              className="view-website-link d-sm-inline-block btn btn-sm btn-primary shadow-sm"
              style={{ borderRadius: "5px", fontSize: "15px" }}
            >
              <i className="fab fa-chrome"></i> View website
            </a>
          </li>

          <div className="topbar-divider d-none d-sm-block"></div>

          {/* <!-- Nav Item - User Information --> */}
          <li className="nav-item dropdown no-arrow">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span
                className="mr-2 d-none d-lg-inline text-gray-600 small label_dimension"
                style={{ verticalAlign: "middle" }}
              >
                Elisabeth Doe
              </span>
              <img
                className="img-profile rounded-circle"
                src={Profile}
                alt=""
              />
            </a>
            {/* <!-- Dropdown - User Information --> */}
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <Link to="/dashboard/editUser" className="dropdown-item">
                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                Edit user
              </Link>
              <div className="dropdown-divider"></div>
              <a
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#logoutModal"
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
      {/* demo alert */}
      {/* <div
        className="alert alert-success alert-dismissible fade show"
        role="alert"
      >
        <strong style={{ fontSize: "larger" }}>
          In the demo version, all buttons are deactivated for security reasons.
        </strong>
        <button
          type="button"
          className="close"
          data-bs-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i className="fa fa-times" aria-hidden="true"></i>
          </span>
        </button>
      </div> */}
      {/* <!-- Logout Modal--> */}
      <div
        className="modal fade"
        id="logoutModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title header_heady"
                id="exampleModalLabel"
                style={{ color: "white", important: "true" }}
              >
                Ready to Leave?
              </h5>
              <button
                className="close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className="fs-1">
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body label_dimension">
              Select "Logout" below if you are ready to end your current
              session.
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary anchor_font"
                type="button"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary anchor_font"
                onClick={handleLogout}
                data-bs-dismiss="modal"
              >
                Logout
              </button>
              <form
                id="logout-form"
                action="https://niva4.lucian.host/logout"
                method="POST"
                style={{ display: "none" }}
              >
                <input
                  type="hidden"
                  name="_token"
                  value="qM55wrMAXt2wESPrkwipyy1DWRpImUMvzVUZUWvq"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardNavbar;
