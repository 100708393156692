import React, { useEffect, useState } from "react";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import "../Pages/EditPage/editpage.css";

const CreateUser = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    roles: "",
    address: "",
    city: "",
    phone_whatsapp: "",
    photo: null,
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 3000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 3000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setUser({
      ...user,
      [name]: type === "file" ? files[0] : value,
    });
  };

  console.log("user", user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(`${ngrokUrl.url}/api/saveUser`, user, {
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "multipart/form-data",
        },
      });

      setUser(response.data);
      setSuccess("user  Create Successfully!");
      setLoading(false);
      setUser({
        name: "",
        email: "",
        roles: "",
        address: "",
        city: "",
        phone_whatsapp: "",
        photo: null,
        password: "",
      });
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to create page");
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800"> Create user </h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              Create user
            </h6>
          </div>
          <div className="card-body mt-3">
            <a href="/dashboard/allUsers" className="dashboardBtn mb-3">
              Back to users
            </a>

            <div className="row align-items-center mt-4">
              <div className="col-md-12">
                <form onSubmit={handleSubmit}>
                  <input
                    type="hidden"
                    name="_token"
                    value="9kr4A0f0neruHdqd4e2UADuzDOw4VyxcaJSUzoO3"
                  />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Name</strong>
                        <input
                          type="text"
                          name="name"
                          className="form-control input_height anchor_font"
                          placeholder="Name"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className=" col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Email</strong>
                        <input
                          type="email"
                          name="email"
                          className="form-control anchor_font input_height"
                          placeholder="Email"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Roles</strong>
                        <select
                          name="roles"
                          id="role_id"
                          className="form-control anchor_font input_height"
                          onChange={handleChange}
                        >
                          <option>Choose role</option>
                          <option value="administrator">administrator</option>
                          <option value="author">author</option>
                          <option value="subscriber">subscriber</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Address</strong>
                        <input
                          type="text"
                          name="address"
                          className="form-control input_height anchor_font"
                          placeholder="Address"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">City</strong>
                        <input
                          type="text"
                          name="city"
                          className="form-control input_height anchor_font"
                          placeholder="City"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">
                          Phone / Whatsapp
                        </strong>
                        <input
                          type="text"
                          name="phone_whatsapp"
                          className="form-control input_height anchor_font"
                          placeholder="Phone / Whatsapp"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Photo</strong>
                        <input
                          type="file"
                          name="photo"
                          className="form-control-file anchor_font"
                          id="photo_id"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Password</strong>
                        <input
                          type="password"
                          name="password"
                          className="form-control input_height anchor_font"
                          placeholder="Password"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <button type="submit" className="cardBtn">
                          Create User
                        </button>
                      )}
                    </div>
                  </div>
                </form>
                {error && (
                  <div
                    className="alert alert-danger"
                    style={{ fontSize: "14px" }}
                  >
                    {error}
                  </div>
                )}
                {success && (
                  <div
                    className="alert alert-success"
                    style={{ fontSize: "14px" }}
                  >
                    {success}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
