import React, { useState, useEffect } from "react";
import "./template.css";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";

const OurPortfolio = () => {
  const [seo, setSeo] = useState({});
  const [loadingSeo, setLoadingSeo] = useState(false);
  const [errorSeo, setErrorSeo] = useState(null);
  const [successSeo, setSuccessSeo] = useState(null);

  useEffect(() => {
    const timeouts = {};

    const manageTimeout = (state, setter, key) => {
      if (state) {
        timeouts[key] = setTimeout(() => setter(null), 2000);
      }
    };

    manageTimeout(errorSeo, setErrorSeo, "errorSeo");
    manageTimeout(successSeo, setSuccessSeo, "successSeo");

    return () => {
      Object.values(timeouts).forEach(clearTimeout);
    };
  }, [errorSeo, successSeo]);

  useEffect(() => {
    const SeoValues = async () => {
      setLoadingSeo(true);
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readOurPortfolio/1`
        );
        setSeo(response.data);
        setLoadingSeo(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorSeo("Failed to fetch default values.");
        setLoadingSeo(false);
      }
    };
    SeoValues();
  }, []);

  const handleSeoChange = (e) => {
    const { name, value } = e.target;
    setSeo({
      ...seo,
      [name]: value,
    });
  };

  const handleSeoSubmit = async (e) => {
    e.preventDefault();
    setLoadingSeo(true);
    setErrorSeo(null);
    setSuccessSeo(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateOurPortfolio/1`,
        seo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        setSeo(response.data);
        setSuccessSeo("Seo Updated Successfully!");
        setLoadingSeo(false);
      } else {
        throw new Error("Failed to updated Seo");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);
      setErrorSeo("Failed to update Seo");
      setLoadingSeo(false);
    }
  };

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Portfolio Settings</h1>
      <div className="pb-2 text-right">
        <select
          name="language"
          className="form-control language-control"
          onchange="window.location='https://niva4.lucian.host/admin/portfolio-settings?language='+this.value"
        >
          <option value="" selected="" disabled="">
            Select a language
          </option>
          <option value="en" selected="">
            English
          </option>
          <option value="pt">Portuguese</option>
          <option value="عربى">عربى</option>
        </select>
      </div>

      <div className="card shadow mb-4">
        <div className="card-header  templete py-3">
          <h6 className="m-0 font-weight-bold text-primary">Section 1</h6>
        </div>
        <div className="card-body">
          <div className="mb-3">
            <a
              className="cardBtn  m-0"
              href="/dashboard/all-project?language=en"
            >
              View All
            </a>
            <a
              className="cardBtn ml-3 m-0"
              href="/dashboard/createProject?language=en"
            >
              Create
            </a>
          </div>
        </div>
      </div>

      <div className="card shadow mb-4">
        <div className="card-header templete  py-3">
          <h6 className="m-0 font-weight-bold text-primary">SEO</h6>
        </div>
        <div className="card-body">
          <form onSubmit={handleSeoSubmit}>
            <input
              type="hidden"
              name="_token"
              value="qo6swE7Ef17bxcxuScLXpgr78RLOXpTnCBG1QWEh"
            />{" "}
            <input type="hidden" name="_method" value="PUT" />
            <div className="row">
              <div className="col-md-6">
                <div className="formgroup">
                  <h5>meta title</h5>
                  <input
                    type="text"
                    name="meta_title"
                    className="formControl"
                    value={seo.meta_title}
                    onChange={handleSeoChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="formgroup">
                  <h5>meta description</h5>
                  <input
                    type="meta_desc"
                    name="meta_description"
                    className="formControl"
                    value={seo.meta_description}
                    onChange={handleSeoChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="formgroup">
                  <h5>Slug</h5>
                  <input
                    type="text"
                    name="slug"
                    className="formControl"
                    value={seo.slug}
                    onChange={handleSeoChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="formgroup">
                  <h5>Anchor text</h5>
                  <input
                    type="text"
                    name="anchor_text"
                    className="formControl"
                    value={seo.anchor_text}
                    onChange={handleSeoChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                <button
                  type="submit"
                  className="btn btn-primary cardBtn"
                  disabled=""
                >
                  Update
                </button>
              </div>
            </div>
          </form>
          {errorSeo && (
            <div className="alert alert-danger" style={{ fontSize: "14px" }}>
              {errorSeo}
            </div>
          )}
          {successSeo && (
            <div className="alert alert-success" style={{ fontSize: "14px" }}>
              {successSeo}{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OurPortfolio;
