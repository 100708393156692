import React, { useEffect } from "react";
import ReactPaginate from "react-paginate";

function PaginatedItems({
  pageCount,
  setPageCount,
  itemsPerPage,
  itemOffset,
  setItemOffset,
  Data,
  setCurrentItems,
}) {
  
  useEffect(() => {
    // Ending item
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(Data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(Data.length / itemsPerPage));
  }, [itemOffset, Data]);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <p className="showing_result">
        Showing {itemOffset + 1} to {itemOffset + itemsPerPage} of {Data.length} results
      </p>
      <ReactPaginate
        breakLabel="..."
        nextLabel="&gt;"
        activeClassName="activeee"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="&lt;"
        // renderOnZeroPageCount={null}
      />
    </div>
  );
}

export default PaginatedItems;
