import React, { useState, useEffect } from "react";
import "./dashboard.css";
import Sidebar from "../../Utility/Sidebar";
import DashboardNavbar from "../../Utility/DashboardNavbar";
import { Outlet } from "react-router-dom";
import Footer from "../../Utility/Footer";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      navigate("/login");
    }
  }, []);

  return (
    <>
      <div id="wrapper" className="d-flex">
        <Sidebar sidebar={sidebar} />

        {/* <!-- Content Wrapper --> */}
        <div
          id="content-wrapper"
          className="d-flex flex-column w-100 justify-content-between"
        >
          {/* <!-- Main Content --> */}
          <div id="content">
            <DashboardNavbar setSidebar={setSidebar} sidebar={sidebar} />

            <Outlet />

            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}

          {/* <!-- Footer --> */}
          <Footer />
          {/* <!-- End of Footer --> */}
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      {/* <!-- End of Page Wrapper --> */}

      {/* <!-- Scroll to Top Button--> */}
      {/* <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a> */}
    </>
  );
}

export default Dashboard;
