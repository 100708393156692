import React from "react";
import { Link } from "react-router-dom";

function CreatePricing() {
  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Create pricing</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">Create pricing</h6>
          </div>
          <div className="card-body">
            <Link
            to="/dashboard/managepricing"
              className="btn btn-primary btn-back anchor_font"
            >
              Back to Pricing page
            </Link>

            <div className="row mt-4">
              <div className="col-md-12">
                <form
                  action="https://niva4.lucian.host/admin/pricing"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />
                  <input type="hidden" name="language_id" value="1" />

                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group">
                        <strong className="header_heady">Title</strong>
                        <input type="text" name="title" className="form-control label_dimension" />
                      </div>
                      <div className="form-group">
                        <strong className="header_heady">Description</strong>
                        <textarea
                          name="description"
                          className="form-control label_dimension"
                          id="description"
                          rows="6"
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <strong className="header_heady">Button text</strong>
                        <input
                          type="text"
                          name="button_text"
                          className="form-control label_dimension"
                        />
                      </div>
                      <div className="form-group">
                        <strong className="header_heady">Button link</strong>
                        <input
                          type="text"
                          name="button_link"
                          className="form-control label_dimension"
                        />
                      </div>

                      <div className="form-group">
                        <strong className="header_heady">Is featured?</strong>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="pricing_switch"
                            id="pricing_switch1"
                            value="1"
                          />
                          <label className="form-check-label" for="pricing_switch1">
                            {" "}
                            Yes{" "}
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="pricing_switch"
                            id="pricing_switch0"
                            value="0"
                          />
                          <label className="form-check-label" for="pricing_switch0">
                            {" "}
                            No{" "}
                          </label>
                        </div>
                      </div>

                      <div className="form-group">
                        <strong className="header_heady">Is featured - text badge</strong>
                        <input
                          type="text"
                          name="popular_text"
                          className="form-control label_dimension"
                        />
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button type="submit" className="btn btn-primary anchor_font">
                        Create pricing
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatePricing;
