import React, { useState, useEffect } from "react";
import "../../Pages/EditPage/editpage.css";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Editpost = () => {
  const navigate = useNavigate();
  const [post, setPost] = useState({
    body: "",
    categories: "",
    link: "",
    meta_description: "",
    meta_title: "",
    photo: null,
    title: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const val = useParams();
  const id = val.id;

  useEffect(() => {
    const postValues = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/editAllPostsById/${id}`
        );
        setPost(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    if (id) {
      postValues();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setPost({
      ...post,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const formDataObj = new FormData();
      formDataObj.append("_method", "PUT");
      formDataObj.append("body", post.body);
      formDataObj.append("categories", post.categories);
      formDataObj.append("link", post.link);
      formDataObj.append("meta_description", post.meta_description);
      formDataObj.append("meta_title", post.meta_title);
      formDataObj.append("title", post.title);
      if (typeof post.photo != "string")
        formDataObj.append("photo", post.photo);

      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${ngrokUrl.url}/api/updateAllPosts/${id}`,
        formDataObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setPost(response.data);
        setSuccess("post  updated successfully!");
        setLoading(false);
      } else {
        throw new Error("Failed to updated post");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to update post");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        navigate("/dashboard/allPosts");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [success]);

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Edit article</h1>
      <div className="card shadow mb-4">
        <div className="card-header templete py-3">
          <h6 className="m-0 font-weight-bold text-primary">Edit article</h6>
        </div>
        <a
          href="/dashboard/allPosts?language=en"
          className="btn-back cardBtn m-4 mb-2"
        >
          Back to Blog page
        </a>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="bQa1IU2NI1tyRO907URYzQIyqDY6fe83pMqncSHh"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Title</h5>
                          <input
                            type="text"
                            name="title"
                            className="formControl"
                            placeholder=""
                            value={post.title}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Link</h5>
                          <div className="slug-container">
                            <span>https://niva-website.vercel.app/</span>
                            <input
                              type="text"
                              name="link"
                              className="formControl"
                              placeholder=""
                              value={post.link}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <img
                            className="img-fluid pb-4"
                            width="100"
                            height="100"
                            src={post.photo_url}
                          />
                          <p>
                            <h5>Photo</h5>
                          </p>
                          <input
                            type="file"
                            name="photo"
                            className="formControl-file"
                            id="photo_id"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Categories</h5>
                          <select
                            name="categories"
                            id="category_id"
                            className="form-control"
                            style={{ height: "38px", fontSize: "15px" }}
                            value={post.categories}
                            onChange={handleChange}
                          >
                            <option value="1">Agency, Consulting</option>
                            <option value="2">Design, UI/UX</option>
                            {/* <option {="" selected="selected" }="" value="3">Programming</option> */}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="formgroup">
                      <h5>Body</h5>
                      <textarea
                        name="body"
                        className="formControl"
                        id="body"
                        rows="20"
                        aria-hidden="true"
                        value={post.body}
                        onChange={handleChange}
                      ></textarea>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>meta title</h5>
                          <input
                            type="text"
                            name="meta_title"
                            className="formControl"
                            placeholder=""
                            value={post.meta_title}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>meta description</h5>
                          <input
                            type="text"
                            name="meta_description"
                            className="formControl"
                            placeholder=""
                            value={post.meta_description}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button type="submit" className="btn btn-primary cardBtn">
                      {loading ? "Submitting..." : "Update"}
                    </button>
                  </div>
                </div>
              </form>
              {error && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {error}
                </div>
              )}
              {success && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {success}{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editpost;
