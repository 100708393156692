import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";

function ManagePricing() {
  const [price, setPrice] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 2000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 2000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  useEffect(() => {
    const priceValues = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${ngrokUrl.url}/api/readPricing`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        });
        setPrice(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch price values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    priceValues();
  }, []);

  const handleDelete = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const selectedCheckboxes = document.querySelectorAll(
        ".checkboxes:checked"
      );
      const selectedIds = Array.from(selectedCheckboxes).map(
        (checkbox) => checkbox.value
      );

      await Promise.all(
        selectedIds.map(async (id) => {
          await axios.delete(
            `${ngrokUrl.url}/api/deleteSectionServices/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "69420",
              },
            }
          );
        })
      );

      setSuccess("slider deleted successfully.");
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete selected items.", error);
      setError("Failed to delete selected items.");
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">All pricings</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              All pricings
            </h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="row align-items-center mb-3">
                <div className="col-lg-6">
                  <Link
                    to="/dashboard/createPricing"
                    href="https://niva4.lucian.host/admin/pricing/create?language=en"
                    className="btn btn-primary btn-back anchor_font mr-3"
                  >
                    Create
                  </Link>
                  <Link
                    to="/dashboard/pricingPage"
                    className="btn btn-primary btn-back anchor_font"
                  >
                    Back to Pricing page
                  </Link>
                </div>

                <div className="col-lg-6 text-right">
                  <select
                    name="language"
                    className="form-control language-control"
                    onchange="window.location='https://niva4.lucian.host/admin/pricing?language='+this.value"
                  >
                    <option value="" selected disabled>
                      Select a language
                    </option>
                    <option value="en" selected>
                      English
                    </option>
                    <option value="pt">Portuguese</option>
                    <option value="عربى">عربى</option>
                  </select>
                </div>
              </div>

              <form
                action="https://niva4.lucian.host/delete/pricing"
                method="POST"
                className="form-inline"
              >
                <input
                  type="hidden"
                  name="_token"
                  value="9kr4A0f0neruHdqd4e2UADuzDOw4VyxcaJSUzoO3"
                />{" "}
                <input type="hidden" name="_method" value="DELETE" />{" "}
                <div className="form-group">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary "
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        backgroundColor: "white",
                        color: "grey",
                        fontSize: "medium",
                      }}
                    >
                      Delete
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={handleDelete}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="submit"
                    name="delete_all"
                    className="btn btn-primary anchor_font"
                  />
                </div>
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" id="options" />
                      </th>
                      <th className="header_heady">Title</th>
                      <th className="header_heady">Description</th>
                      <th className="header_heady">Button text</th>
                      <th className="header_heady">Button link</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>
                        <input type="checkbox" id="options1" />
                      </th>
                      <th className="header_heady">Title</th>
                      <th className="header_heady">Description</th>
                      <th className="header_heady">Button text</th>
                      <th className="header_heady">Button link</th>
                    </tr>
                  </tfoot>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="3">Loading...</td>
                      </tr>
                    ) : error ? (
                      <tr>
                        <td colSpan="3">Error: {error}</td>
                      </tr>
                    ) : price.length === 0 ? (
                      <tr>
                        <td colSpan="3">No data found.</td>
                      </tr>
                    ) : (
                      Array.isArray(price) &&
                      price.map((price) => (
                        <tr key={price.id}>
                          <td>
                            <input
                              className="checkboxes"
                              type="checkbox"
                              name="checkbox_array[]"
                              value={price.id}
                            />
                          </td>

                          <td data-label="title">
                            {price.title}
                            <p class="mb-0 mt-2">
                              <a
                                href={`/dashboard/editPricing/${price.id}/edit?language=en`}
                              >
                                Edit
                              </a>
                            </p>
                          </td>
                          <td
                            data-label="description"
                            className="label_dimension"
                          >
                            {price.description}
                          </td>
                          <td data-label="text" className="label_dimension">
                            {price.button_text}
                          </td>
                          <td data-label="link" className="label_dimension">
                            {price.button_link}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                {error && (
                  <div style={{ color: "red", fontSize: "14px" }}>{error}</div>
                )}
                {success && (
                  <div style={{ color: "green", fontSize: "14px" }}>
                    {success}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagePricing;
