import React, { useEffect } from "react";
import PricingTop from "../Components/Pricing/PricingTop";
import PricingElements from "../Components/Pricing/PricingElements";
import Footer from "../Components/Home/Footer/Footer";
import NavBar from "../Utility/NavBar";
import Topbar from "../Topbar/Topbar";
import Whatsapp from "../Components/TopupButtons/Whatsapp";

function Pricing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Topbar />
      <Whatsapp />
      <NavBar />
      <PricingTop />
      <PricingElements />
      <Footer />
    </div>
  );
}
export default Pricing;
