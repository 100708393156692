import React, { useState, useEffect } from "react";
import "../Pages/EditPage/editpage.css";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import { Link } from "react-router-dom";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AllUsers = () => {
  const [userData, setUserData] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 3000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 3000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(itemId)
        ? prevSelected.filter((id) => id !== itemId)
        : [...prevSelected, itemId]
    );
  };

  useEffect(() => {
    const usersValues = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${ngrokUrl.url}/api/readUser`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        });

        setUserData(response.data);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
      }
    };

    usersValues();
  }, []);

  const handleDelete = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const selectedCheckboxes = document.querySelectorAll(
        ".checkboxes:checked"
      );
      const selectedIds = Array.from(selectedCheckboxes).map(
        (checkbox) => checkbox.value
      );

      await Promise.all(
        selectedIds.map(async (id) => {
          await axios.delete(`${ngrokUrl.url}/api/deleteUser/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": "69420",
            },
          });
        })
      );

      const response = await axios.get(`${ngrokUrl.url}/api/readUser`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      });
      setUserData(response.data);
      setSuccess("user deleted successfully.");
      setLoading(false);
    } catch (error) {
      console.error("Failed to delete selected items.", error);
      setError("Failed to delete selected items.");
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">All users</h1>
      <div className="card shadow  mb-4">
        <div className="card-header templete py-3">
          <h6 className="m-0 font-weight-bold text-primary">All users</h6>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <form className="form-inline">
              <input
                type="hidden"
                name="_token"
                value="bQa1IU2NI1tyRO907URYzQIyqDY6fe83pMqncSHh"
              />{" "}
              {/* <input type="hidden" name="_method" value="DELETE" />{" "} */}
              {/* <div className="form-group">
                <select
                  name="checkbox_array"
                  id=""
                  className="form-control"
                  style={{ fontSize: "15px", height: "38px" }}
                >
                  <option value="" onClick={handleDelete}>
                    Delete
                  </option>
                </select>
              </div> */}
              {/* <div className="form-group">
                <input
                  type="submit"
                  name="delete_all"
                  className="btn btn-primary cardBtn"
                  disabled=""
                  style={{ fontSize: "15px" }}
                />
                <input type="hidden" name="current_user" value="1" />
              </div> */}
              <table
                className="table table-bordered m-0"
                id="dataTable"
                width="100%"
                cellspacing="0"
                color="#858796"
              >
                <thead>
                  <tr>
                    {/* <th>
                      <input type="checkbox" id="options" />
                    </th> */}
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th scope="colgroup" className="header_heady " colSpan="2">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    {/* <th>
                      <input type="checkbox" id="options1" />
                    </th> */}
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th scope="colgroup" className="header_heady " colSpan="2">
                      Actions
                    </th>
                  </tr>
                </tfoot>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="3">Loading...</td>
                    </tr>
                  ) : error ? (
                    <tr>
                      <td colSpan="3">Error: {error}</td>
                    </tr>
                  ) : userData.length === 0 ? (
                    <tr>
                      <td colSpan="3">No data found.</td>
                    </tr>
                  ) : (
                    Array.isArray(userData) &&
                    userData.map((user) => (
                      <tr key={user.id}>
                        {/* <td>
                          <input
                            className="checkboxes"
                            type="checkbox"
                            name="checkbox_array[]"
                            value={user.id}
                            onChange={() => handleCheckboxChange(user.id)}
                          />
                        </td> */}
                        <td data-label="Name">
                          <div class="float-left-avatar">
                            <img
                              width="35"
                              height="35"
                              src={user.photo_url}
                              alt="img"
                            />
                          </div>
                          <div class="float-left-user-name">
                            <p>{user.name}</p>
                            {/* <a href={`/dashboard/EditUsers/${user.id}/edit`}>
                              Edit
                            </a> */}
                          </div>
                        </td>
                        <td data-label="OWNER" className="label_dimension">
                          {user.email}
                        </td>
                        <td data-label="TITLE" className="label_dimension">
                          {user.roles}
                        </td>
                        {/* <td data-label="Category" className="label_dimension">
                          {user.categories}
                        </td> */}
                        <td className="body-url" data-label="BODY">
                          <Link
                            to={`/dashboard/EditUsers/${user.id}/edit`}
                            className="action-button"
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Link>

                          <FontAwesomeIcon
                            icon={faTrash}
                            className="action-button"
                            onClick={() => handleDelete(user.id)}
                            style={{ marginLeft: "25px" }}
                          />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </form>
            <nav
              role="navigation"
              aria-label="Pagination Navigation"
              class="flex items-center justify-between"
            >
              <div class="flex justify-between flex-1 sm:hidden">
                <span class="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 cursor-default leading-5 rounded-md">
                  « Previous
                </span>

                <a
                  href="https://niva4.lucian.host/admin/users?page=2"
                  class="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 rounded-md hover:text-gray-500 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                >
                  Next »
                </a>
              </div>

              <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p class="text-sm text-gray-700 leading-5">
                    Showing
                    <span class="font-medium">1</span>
                    to
                    <span class="font-medium">5</span>
                    of
                    <span class="font-medium">79</span>
                    results
                  </p>
                </div>

                <div>
                  <span class="relative z-0 inline-flex shadow-sm rounded-md">
                    <span
                      aria-disabled="true"
                      aria-label="&amp;laquo; Previous"
                    >
                      <span
                        class="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 cursor-default rounded-l-md leading-5"
                        aria-hidden="true"
                      >
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </span>

                    <span aria-current="page">
                      <span class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-500 bg-white border border-gray-300 cursor-default leading-5">
                        1
                      </span>
                    </span>
                    <a
                      href="https://niva4.lucian.host/admin/users?page=2"
                      class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 hover:text-gray-500 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                      aria-label="Go to page 2"
                    >
                      2
                    </a>
                    <a
                      href="https://niva4.lucian.host/admin/users?page=3"
                      class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 hover:text-gray-500 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                      aria-label="Go to page 3"
                    >
                      3
                    </a>
                    <a
                      href="https://niva4.lucian.host/admin/users?page=4"
                      class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 hover:text-gray-500 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                      aria-label="Go to page 4"
                    >
                      4
                    </a>
                    <a
                      href="https://niva4.lucian.host/admin/users?page=5"
                      class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 hover:text-gray-500 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                      aria-label="Go to page 5"
                    >
                      5
                    </a>
                    <a
                      href="https://niva4.lucian.host/admin/users?page=6"
                      class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 hover:text-gray-500 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                      aria-label="Go to page 6"
                    >
                      6
                    </a>
                    <a
                      href="https://niva4.lucian.host/admin/users?page=7"
                      class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 hover:text-gray-500 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                      aria-label="Go to page 7"
                    >
                      7
                    </a>
                    <a
                      href="https://niva4.lucian.host/admin/users?page=8"
                      class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 hover:text-gray-500 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                      aria-label="Go to page 8"
                    >
                      8
                    </a>
                    <a
                      href="https://niva4.lucian.host/admin/users?page=9"
                      class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 hover:text-gray-500 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                      aria-label="Go to page 9"
                    >
                      9
                    </a>
                    <a
                      href="https://niva4.lucian.host/admin/users?page=10"
                      class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 hover:text-gray-500 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                      aria-label="Go to page 10"
                    >
                      10
                    </a>

                    <span aria-disabled="true">
                      <span class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 cursor-default leading-5">
                        ...
                      </span>
                    </span>

                    <a
                      href="https://niva4.lucian.host/admin/users?page=15"
                      class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 hover:text-gray-500 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                      aria-label="Go to page 15"
                    >
                      15
                    </a>
                    <a
                      href="https://niva4.lucian.host/admin/users?page=16"
                      class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 hover:text-gray-500 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                      aria-label="Go to page 16"
                    >
                      16
                    </a>

                    <a
                      href="https://niva4.lucian.host/admin/users?page=2"
                      rel="next"
                      class="relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md leading-5 hover:text-gray-400 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
                      aria-label="Next &amp;raquo;"
                    >
                      <svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </a>
                  </span>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllUsers;
