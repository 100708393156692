import "./editpage.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";
import { useParams } from "react-router-dom";
import "../../Pages/EditPage/editpage.css";
import { useNavigate } from "react-router-dom";

const EditPage = () => {
  const [page, setPage] = useState({
    title: "",
    link: "",
    photo: null,
    body: "",
    meta_desc: "",
    meta_keywords: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 3000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 3000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  const val = useParams();
  const id = val.id;

  useEffect(() => {
    const fetchDefaultValues = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/editPage/${id}`,
          {}
        );
        setPage(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    if (id) {
      fetchDefaultValues();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setPage({
      ...page,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const formDataObj = new FormData();
      formDataObj.append("_method", "PUT");
      formDataObj.append("title", page.title);
      formDataObj.append("link", page.link);
      formDataObj.append("body", page.body);
      formDataObj.append("meta_title", page.meta_title);
      formDataObj.append("meta_desc", page.meta_desc);
      if (typeof page.photo != "string")
        formDataObj.append("photo", page.photo);

      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${ngrokUrl.url}/api/updatePage/${id}`,
        formDataObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setPage(response.data);
        setSuccess("page  updated successfully!");
        setLoading(false);
      } else {
        throw new Error("Failed to updated page");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to update page");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        navigate("/dashboard/all-pages");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [success]);

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Edit page</h1>
      <div className="card shadow mb-4">
        <div className="card-header  py-3">
          <h5 className="m-0 font-weight-bold text-primary subtitle">
            Create page
          </h5>
        </div>
        <a
          className="anchorTitile mt-3"
          href="/dashboard/all-pages?language=en"
        >
          Back to Pages
        </a>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="IcQsAYizLYQuWUkXeVvL5ozVzwTdA1iC75ju6nRr"
                />
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>Title</h5>
                      <input
                        type="text"
                        name="title"
                        className="formControl"
                        placeholder="Enter page title"
                        value={page.title}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>Link</h5>
                      <div className="slug-container">
                        <span style={{ fontSize: "15px" }}>
                          https://niva-website.vercel.app/
                        </span>
                        <input
                          type="text"
                          name="link"
                          className="formControl"
                          placeholder="Enter page slug"
                          value={page.link}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="formgroup">
                  <img
                    className="img-fluid pb-4"
                    width="200"
                    height="200"
                    src={page.photo_url}
                    alt="Preview"
                  />

                  <h5>Photo</h5>

                  <input
                    type="file"
                    name="photo"
                    className="formControl-file"
                    id="photo_id"
                    style={{ fontSize: "15px" }}
                    onChange={handleChange}
                  />
                </div>
                <div className="formgroup">
                  <h5>Body</h5>
                  <>
                    {/* <TineMce /> */}
                    <textarea
                      name="body"
                      class="formControl"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      style={{
                        fontSize: "15px",
                        lineHeight: 1.5,
                      }}
                      value={page.body}
                      onChange={handleChange}
                    ></textarea>
                  </>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>meta title</h5>
                      <input
                        name="meta_title"
                        className="formControl"
                        value={page.meta_title}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>meta description</h5>
                      <input
                        name="meta_desc"
                        className="formControl"
                        value={page.meta_desc}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="updatebtn">
                  <button type="submit" className="btn btn-primary">
                    {loading ? "Submitting..." : "Update"}
                  </button>
                </div>
              </form>
              {error && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {error}
                </div>
              )}
              {success && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {success}{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPage;
