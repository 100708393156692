import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import { toast, ToastContainer } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import "react-toastify/dist/ReactToastify.css";
import "./ContactPageMap.css";

function ContactPageMap() {
  const formRef = useRef(null);
  const [formInfo, setFormInfo] = useState({
    name: "",
    email: "",
    phone_number: "",
    budget: "",
    message: "",
  });
  const [formData, setFormData] = useState({});
  const [loadingFormInfo, setLoadingFormInfo] = useState(false);
  const [errorFormInfo, setErrorFormInfo] = useState(null);
  const [errors, setErrors] = useState({});
  const recaptchaRef = useRef(null);

  useEffect(() => {
    const FormInformation = async () => {
      setLoadingFormInfo(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readContactUs/1`);
        setFormInfo(response.data);
        setLoadingFormInfo(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorFormInfo("Failed to fetch default values.");
        setLoadingFormInfo(false);
      }
    };
    FormInformation();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    const errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is not valid";
    }
    if (!formData.phone_number)
      errors.phone_number = "Phone Number is required";
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!validate()) {
        return;
      }

      const recaptchaValue = recaptchaRef.current.getValue();
      if (!recaptchaValue) {
        setErrors({ recaptcha: "Please verify you are not a robot." });
        return;
      }

      const response = await axios.post(`${ngrokUrl.url}/api/contact`, {
        ...formData,
        recaptcha: recaptchaValue,
      });

      if (response.status >= 200 && response.status < 300) {
        toast.success("Form submitted successfully!");
        formRef.current.reset();
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }

        setFormData({
          name: "",
          email: "",
          phone_number: "",
          budget: "",
          message: "",
        });
      }
    } catch (error) {
      console.error("Failed to submit form:", error);
    }
  };

  return (
    <div>
      <div className="iframe-contact" id="contact-section">
        <div className="container contactPage">
          <div className="row contactpage">
            <div
              className="col-lg-6 col-12 mapwidth"
              style={{ backgroundColor: "#fff", borderRadius: "0.35rem" }}
            >
              <h3 style={{ textAlign: "center", marginBottom: "0px" }}>
                Where we are
              </h3>
              <div style={{ width: "100%" }}>
                <p>
                  <iframe
                    className="responsive-iframe"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3880.8970564547603!2d77.72621177412793!3d13.418698704920352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb1ef23450cd7d5%3A0x2cd7c906893aa2c4!2sYRP%20IT%20SOLUTIONS!5e0!3m2!1sen!2sin!4v1721028269889!5m2!1sen!2sin"
                    // width="525"
                    // height="455"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </p>
              </div>
            </div>

            <div
              className="col-lg-6 col-12 contactForm"
              style={{ borderRadius: "0.35rem" }}
            >
              <h3 style={{ textAlign: "center", marginBottom: "0px" }}>
                Send us a message
              </h3>

              <form onSubmit={handleSubmit} ref={formRef}>
                <div className="row">
                  <div className="col-md-6 col-12 ">
                    <div className="form-group p-0 border-0 fields">
                      <label htmlFor="name">
                        Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        name="name"
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Name"
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <span className="text-danger">{errors.name}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-12">
                    <div className="form-group p-0 border-0 fields">
                      <label htmlFor="email">
                        Email <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <span className="text-danger">{errors.email}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-12 contactPage">
                    <div className="form-group p-0 border-0 fields">
                      <label htmlFor="phone">
                        Phone <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        name="phone_number"
                        type="text"
                        className="form-control"
                        id="phone"
                        placeholder="Phone"
                        onChange={handleChange}
                      />
                      {errors.phone_number && (
                        <span className="text-danger">
                          {errors.phone_number}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-12">
                    <div className="form-group p-0 border-0 fields">
                      <label htmlFor="budget">Budget</label>
                      <input
                        name="budget"
                        type="text"
                        className="form-control"
                        id="budget"
                        placeholder="Budget"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group p-0 mb-0 border-0 fields">
                  <label htmlFor="message">Message</label>
                  <textarea
                    name="message"
                    className="form-control"
                    id="message"
                    rows="3"
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div
                  className="form-group p-0 border-0 fields recaptcha"
                  style={{ marginBottom: "0px", marginTop: "15px" }}
                >
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Lc4xycqAAAAAELppXnwvjauMqdPJnZyd05zjJlO"
                  />
                  {errors.recaptcha && (
                    <span className="text-danger">{errors.recaptcha}</span>
                  )}
                </div>

                <div className="form-group p-0 border-0 fields contactbtn">
                  <button type="submit" className="btn btn-style1 contactBtn">
                    Submit
                  </button>
                </div>
              </form>
              <ToastContainer autoClose={2000} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPageMap;
