import React from "react";
import "./chart.css";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js"; // Ensure CategoryScale is imported
import { Chart } from "chart.js/auto"; // Ensure automatic registration

// Register the CategoryScale
Chart.register(CategoryScale);

const Graph = () => {
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Clicks",
        data: [10, 11, 10, 10, 350, 10, 10, 12, 13, 10, 11, 12],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "category",
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: 350,
        ticks: {
          stepSize: 50,
        },
      },
    },
  };

  return (
    <div className="graph">
      <Line data={data} options={options} />
    </div>
  );
};

export default Graph;
