import React, { useState, useEffect } from "react";
import PaginatedItems from "../../Utility/PaginatedItems";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";

import "../Pages/EditPage/editpage.css";

function AllMedia() {
  const [buttonText, setButtonText] = useState([]);
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 2000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 2000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  useEffect(() => {
    const MediaValues = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/photos`);
        const sortedMedia = response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setMedia(sortedMedia);
        setPageCount(Math.ceil(sortedMedia.length / itemsPerPage));

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    MediaValues();
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(media.slice(itemOffset, endOffset));
  }, [itemOffset, itemsPerPage, media]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const copyToClipboard = (index, url) => {
    navigator.clipboard.writeText(url).then(() => {
      const newText = [...buttonText];
      newText[index] = "Copied";
      setButtonText(newText);

      setTimeout(() => {
        newText[index] = "Copy URL";
        setButtonText(newText);
      }, 2000);
    });
  };

  return (
    <div>
      <div className="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">All media</h1>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              All media
            </h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <Link
                to="/dashboard/upload"
                className="btn btn-primary btn-back anchor_font  mt-2"
              >
                Upload image
              </Link>

              <form>
                <table
                  className="table table-bordered media-index"
                  id="dataTable"
                >
                  <thead>
                    <tr>
                      <th className="header_heady">ID</th>
                      <th className="header_heady">Image</th>
                      <th className="header_heady">Link</th>
                      <th className="header_heady">Created</th>
                      {/* <th className="header_heady">Actions</th> */}
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th className="header_heady">ID</th>
                      <th className="header_heady">Image</th>
                      <th className="header_heady">Link</th>
                      <th className="header_heady">Created</th>
                      {/* <th className="header_heady">Actions</th> */}
                    </tr>
                  </tfoot>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="4">Loading...</td>
                      </tr>
                    ) : error ? (
                      <tr>
                        <td colSpan="4">Error: {error}</td>
                      </tr>
                    ) : currentItems.length === 0 ? (
                      <tr>
                        <td colSpan="4">No data found.</td>
                      </tr>
                    ) : (
                      currentItems.map((item, index) => {
                        // Calculate the global index
                        const globalIndex = itemOffset + index + 1;
                        return (
                          <tr key={item.id}>
                            <td style={{ width: "50px" }}>{globalIndex}</td>
                            <td style={{ width: "300px" }}>
                              <img src={item.photo_url} alt="media" />
                            </td>
                            <td style={{ width: "450px" }}>
                              <div style={{ display: "flex", width: "300px" }}>
                                <input
                                  type="text"
                                  name="url-clip"
                                  className="form-control"
                                  id={`copy-clip-${index}`}
                                  value={item.photo_url}
                                  readOnly
                                  style={{ width: "auto", fontSize: "15px" }}
                                />
                                <button
                                  type="button"
                                  style={{
                                    width: "100px",
                                    height: "36px",
                                    fontSize: "medium",
                                  }}
                                  className="dashboardBtn ml-3"
                                  onClick={() =>
                                    copyToClipboard(index, item.photo_url)
                                  }
                                >
                                  {buttonText[index] || "Copy URL"}
                                </button>
                              </div>
                            </td>
                            <td
                              className="body-url"
                              data-label="Created"
                              style={{ width: "140px" }}
                            >
                              {formatDate(item.created_at)}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>

                {error && (
                  <div style={{ color: "red", fontSize: "15px" }}>{error}</div>
                )}
                {success && (
                  <div style={{ color: "green", fontSize: "15px" }}>
                    {success}
                  </div>
                )}
              </form>

              <div className="">
                <PaginatedItems
                  itemsPerPage={itemsPerPage}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  itemOffset={itemOffset}
                  setItemOffset={setItemOffset}
                  Data={media}
                  setCurrentItems={setCurrentItems}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllMedia;
