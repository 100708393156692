import React, { useState, useEffect } from "react";

import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import { useNavigate } from "react-router-dom";
import "../Pages/EditPage/editpage.css";

function CreateSlider() {
  const [formData, setFormData] = useState({
    photo: null,
    heading_first: "",
    heading_second: "",
    button_text: "",
    button_link: "",
    photo_mobile: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 3000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 3000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const data = new FormData();
      data.append("photo", formData.photo);
      data.append("photo_mobile", formData.photo_mobile);
      data.append("heading_first", formData.heading_first);
      data.append("heading_second", formData.heading_second);
      data.append("button_text", formData.button_text);
      data.append("button_link", formData.button_link);

      const response = await axios.post(
        `${ngrokUrl.url}/api/saveSlider`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setSuccess("page added successfully!");
        setLoading(false);

        setFormData({
          photo: "",
          heading_first: "",
          heading_second: "",
          button_text: "",
          button_link: "",
          photo_mobile: "",
        });
      } else {
        throw new Error("Failed to create page");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to create page");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        navigate("/dashboard/manageslider");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [success]);

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Create slider</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              Create slider
            </h6>
          </div>

          <div className="card-body mt-3">
            <a
              className="dashboardBtn "
              href="/dashboard/manageslider"
              style={{ marginBottom: "15px" }}
            >
              Back to sliders
            </a>
            <div className="row mt-4">
              <div className="col-md-12">
                <form onSubmit={handleSubmit}>
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="header_heady">Heading 1</strong>
                            <input
                              type="text"
                              name="heading_first"
                              className="form-control label_dimension"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="header_heady">Heading 2</strong>
                            <input
                              type="text"
                              name="heading_second"
                              className="form-control label_dimension"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="header_heady">
                              Button text
                            </strong>
                            <input
                              type="text"
                              name="button_text"
                              className="form-control label_dimension"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="header_heady">
                              Button link
                            </strong>
                            <input
                              type="text"
                              name="button_link"
                              className="form-control label_dimension"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="header_heady">
                              Desktop View
                            </strong>
                            <input
                              type="file"
                              name="photo"
                              className="form-control-file label_dimension "
                              id="photo_id"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="header_heady">
                              Mobile View
                            </strong>
                            <input
                              type="file"
                              name="photo_mobile"
                              className="form-control-file label_dimension "
                              id="photo_id"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                        <button
                          type="submit"
                          className="btn btn-primary cardBtn"
                          style={{ fontSize: "15px" }}
                        >
                          Create Slider
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                {error && (
                  <div style={{ color: "red", fontSize: "14px" }}>{error}</div>
                )}
                {success && (
                  <div style={{ color: "green", fontSize: "14px" }}>
                    {success}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateSlider;
