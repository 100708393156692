import React, { useState, useEffect } from "react";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";

function Meta() {
  const [metaData, setMetaData] = useState({
    meta_keyword: "",
    meta_desc: "",
    social_title: "",
    social_desc: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const MetaValues = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${ngrokUrl.url}/api/readSeo/3`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      });

      setMetaData(response.data);
    } catch (error) {
      console.error("Failed to fetch default values.", error);
      setError("Failed to fetch default values.");
    }
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    setMetaData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    MetaValues();
  }, []);

  const handlesubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateSeo/3`,
        metaData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      await MetaValues();

      setMetaData(response.data);
      setSuccess("updated successfully.");
    } catch (error) {
      console.error("Failed to update website settings.", error);
      setError("Failed to update website settings.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex gap-4">
      <div className="col-3">
        <div>
          <div className="outey_div d-flex justify-content-center">
            <div className="inty_div">
              <input type="file" name="" id="" className="fily_file" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-9">
        <form onSubmit={handlesubmit}>
          <div className="">
            <span className="header_heady">Meta Keywords</span>
            <input
              type="text"
              name="meta_keyword"
              className="form-control input_height label_dimension"
              // value="agency, building, business, construct, equipment, industrial, ecommerce  construction, corporate, property, real estate, tools,  architecture, building construction"
              value={metaData.meta_keyword}
              placeholder=""
              onChange={handlechange}
            />
            <p className="label_dimension">
              Keyword should separated by coma (,)
            </p>
          </div>
          <div className="mb-3">
            <span className="header_heady">Meta Description</span>
            <textarea
              name="meta_desc"
              className="form-control label_dimension"
              id="body"
              // value="Construm - A multi purpose website and construction business platform"
              value={metaData.meta_desc}
              rows="5"
              onChange={handlechange}
            ></textarea>
          </div>
          <div className="">
            <span className="header_heady">Social Title</span>
            <input
              type="text"
              name="social_title"
              className="form-control input_height label_dimension"
              // value="Construm - A multi purpose website and construction business platform"
              value={metaData.social_title}
              placeholder=""
              onChange={handlechange}
            />
          </div>
          <div className="mt-3">
            <strong className="header_heady">Social Description</strong>
            <input
              type="text"
              name="social_desc"
              className="form-control input_height label_dimension"
              // value="Construm - A multi purpose website and construction business platform"
              value={metaData.social_desc}
              onChange={handlechange}
              placeholder=""
              style={{
                paddingBottom: "50px",
                paddingTop: "10px",
              }}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 text-right mt-3 pr-0">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <button type="submit" className="btn btn-primary anchor_font">
                Save Changes
              </button>
            )}
          </div>
        </form>
        {error && <p style={{ color: "red" }}>Error: {error}</p>}
        {success && <p style={{ color: "green" }}>Success: {success}</p>}
      </div>
    </div>
  );
}

export default Meta;
