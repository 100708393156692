import React from "react";
import "../../App.css";
import { useNavigate } from "react-router-dom";

function PricingTop(){
    const navigate = useNavigate();
    return(
        <div>
<div class="breadcrumb-area">
       <h1 class="breadcrumb-title">Pricing</h1>
       <ul class="page-list">
            <li class="item-home"><a class="bread-link"  title="Home" onClick={()=> navigate("/")}>Home</a></li>
            <li class="separator separator-home"></li>
            <li class="item-current">Pricing</li>
        </ul>
   </div>
        </div>
    )
}
export default PricingTop;