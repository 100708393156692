import React, { useState, useEffect } from "react";
import "../../Pages/EditPage/editpage.css";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const EditSlider = () => {
  const navigate = useNavigate();
  const [slider, setSlider] = useState({
    button_link: "",
    button_text: "",
    heading_first: "",
    heading_second: "",
    photo: null,
    photo_mobile: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const val = useParams();
  const id = val.id;

  useEffect(() => {
    const SliderValues = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${ngrokUrl.url}/api/readSliderById/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        setSlider(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    if (id) {
      SliderValues();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setSlider({
      ...slider,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const formDataObj = new FormData();
      formDataObj.append("_method", "PUT");
      formDataObj.append("button_link", slider.button_link);
      formDataObj.append("button_text", slider.button_text);
      formDataObj.append("heading_first", slider.heading_first);
      formDataObj.append("heading_second", slider.heading_second);
      if (typeof slider.photo != "string")
        formDataObj.append("photo", slider.photo);

      if (typeof slider.photo_mobile != "string")
        formDataObj.append("photo_mobile", slider.photo_mobile);

      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${ngrokUrl.url}/api/updateSlider/${id}`,
        formDataObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setSlider(response.data);
        setSuccess("Slider  updated successfully!");
        setLoading(false);
      } else {
        throw new Error("Failed to updated Slider");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to update Slider");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        navigate("/dashboard/manageslider");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [success]);

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Edit slider</h1>
      <div className="card shadow mb-4">
        <div className="card-header  templete py-3">
          <h6 className="m-0 font-weight-bold text-primary">Edit slider</h6>
        </div>
        <a
          href="/dashboard/manageslider?language=en"
          className="btn-back cardBtn m-4 mb-2"
        >
          Back to sliders{" "}
        </a>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="bQa1IU2NI1tyRO907URYzQIyqDY6fe83pMqncSHh"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Heading 1</h5>
                          <input
                            type="text"
                            name="heading_first"
                            className="formControl"
                            value={slider.heading_first}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Heading 2</h5>
                          <input
                            type="text"
                            name="heading_second"
                            className="formControl"
                            value={slider.heading_second}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Button text</h5>
                          <input
                            type="text"
                            name="button_text"
                            className="formControl"
                            value={slider.button_text}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Button link</h5>
                          <input
                            type="text"
                            name="button_link"
                            className="formControl"
                            value={slider.button_link}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup " style={{ height: "180px" }}>
                          <img
                            className="img-fluid pb-4"
                            width="100"
                            height="80px"
                            src={slider.photo_url}
                          />
                          <p>
                            <h5>Desktop View</h5>
                          </p>
                          <input
                            type="file"
                            name="photo"
                            className="formControl-file"
                            id="photo_id"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="formgroup" style={{ height: "180px" }}>
                          <img
                            className="img-fluid pb-4"
                            width="100"
                            height="80px"
                            src={slider.photo_mobile_url}
                          />
                          <p>
                            <h5>mobile View</h5>
                          </p>
                          <input
                            type="file"
                            name="photo_mobile"
                            className="formControl-file"
                            id="photo_id"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button type="submit" className="btn btn-primary cardBtn">
                      {loading ? "Submitting..." : "Update"}
                    </button>
                  </div>
                </div>
              </form>
              {error && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {error}
                </div>
              )}
              {success && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {success}{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSlider;
