import React from "react";
import { useState, useEffect } from "react";
import Loader from "../Components/Loader/Loader";
import OurService from "../Components/OurService/OurService";
import Topbar from "../Topbar/Topbar";
import Footer from "../Components/Home/Footer/Footer";
import NavBar from "../Utility/NavBar";

const OurServices = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Topbar />
          <NavBar />
          <OurService />
          <Footer />
        </>
      )}
    </div>
  );
};

export default OurServices;
