import React, { useState, useEffect } from "react";
import "../../Pages/EditPage/editpage.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ngrokUrl } from "../../../Utility/data";
import { useNavigate } from "react-router-dom";

const EditPostCategories = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const idVal = useParams();
  const id = idVal.id;

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 3000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 3000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  useEffect(() => {
    const categoriesValues = async () => {
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readPostCategoriesId/${id}`
        );

        setCategories(response.data);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
      }
    };

    categoriesValues();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategories({
      ...categories,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updatePostCategories/${id}`,
        categories,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      setCategories(response.data);
      setSuccess("Categories  updated Successfully!");
      setLoading(false);
    } catch (error) {
      console.error("Failed to add categories:", error);

      setError("Failed to add categories ");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        navigate("/dashboard/postCategory");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [success]);
  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Categories</h1>
      <div className="card shadow mb-4">
        <div className="card-header templete py-3">
          <h6 className="m-0 font-weight-bold text-primary">Categories</h6>
        </div>
        <a
          href="/dashboard/postCategory?language=en"
          className="btn-back cardBtn m-4 mb-2"
        >
          Back to categories
        </a>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="bQa1IU2NI1tyRO907URYzQIyqDY6fe83pMqncSHh"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="formgroup">
                      <h5>Name</h5>
                      <input
                        type="text"
                        name="categories"
                        className="formControl"
                        placeholder=""
                        value={categories.categories}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button type="submit" className="dashboardBtn " disabled="">
                      Update
                    </button>
                  </div>
                </div>
              </form>
              {error && (
                <div style={{ color: "red", fontSize: "14px" }}>{error}</div>
              )}
              {success && (
                <div style={{ color: "green", fontSize: "14px" }}>
                  {success}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPostCategories;
