import React from "react";
import { BeatLoader } from "react-spinners";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader ">
      <BeatLoader color="#94618e" size={30} />
    </div>
  );
};

export default Loader;
