import React from "react";
import Footer from "../Home/Footer/Footer";
import NavBar from "../../Utility/NavBar";
import Whatsapp from "../TopupButtons/Whatsapp";
import Topbar from "../../Topbar/Topbar";

function Gdpr() {
  return (
    <div>
      <Topbar />
      <Whatsapp />
      <NavBar />
      <div class="breadcrumb-area">
        <h1 class="breadcrumb-title">GDPR</h1>
      </div>

      <div class="page-content">
        <div class="container">
          <p>
            The&nbsp;<strong>General Data Protection Regulation</strong>
            &nbsp;(EU)&nbsp;
            <a
              class="external text"
              href="https://eur-lex.europa.eu/eli/reg/2016/679/oj"
            >
              2016/679
            </a>
            &nbsp;(<strong>GDPR</strong>) is a&nbsp;
            <a
              title="Regulation (European Union)"
              href="https://en.wikipedia.org/wiki/Regulation_(European_Union)"
            >
              regulation
            </a>
            &nbsp;in&nbsp;
            <a
              class="mw-redirect"
              title="EU law"
              href="https://en.wikipedia.org/wiki/EU_law"
            >
              EU law
            </a>
            &nbsp;on&nbsp;
            <a
              class="mw-redirect"
              title="Data protection"
              href="https://en.wikipedia.org/wiki/Data_protection"
            >
              data protection
            </a>
            &nbsp;and privacy in the&nbsp;
            <a
              title="European Union"
              href="https://en.wikipedia.org/wiki/European_Union"
            >
              European Union
            </a>
            &nbsp;(EU) and the&nbsp;
            <a
              title="European Economic Area"
              href="https://en.wikipedia.org/wiki/European_Economic_Area"
            >
              European Economic Area
            </a>
            &nbsp;(EEA). It also addresses the transfer of&nbsp;
            <a
              title="Personal data"
              href="https://en.wikipedia.org/wiki/Personal_data"
            >
              personal data
            </a>
            &nbsp;outside the EU and EEA areas. The GDPR's primary aim is to
            give individuals control over their personal data and to simplify
            the regulatory environment for&nbsp;
            <a
              title="International business"
              href="https://en.wikipedia.org/wiki/International_business"
            >
              international business
            </a>
            &nbsp;by unifying the regulation within the EU.
            <sup id="cite_ref-1" class="reference">
              <a href="https://en.wikipedia.org/wiki/General_Data_Protection_Regulation#cite_note-1">
                [1]
              </a>
            </sup>
            &nbsp;Superseding the&nbsp;
            <a
              title="Data Protection Directive"
              href="https://en.wikipedia.org/wiki/Data_Protection_Directive"
            >
              Data Protection Directive
            </a>
            &nbsp;95/46/EC, the regulation contains provisions and requirements
            related to the processing of&nbsp;
            <a
              title="Personal data"
              href="https://en.wikipedia.org/wiki/Personal_data"
            >
              personal data
            </a>
            &nbsp;of individuals (formally called&nbsp;<em>data subjects</em> in
            the GDPR) who are located in the EEA, and applies to any
            enterprise—regardless of its location and the data subjects'
            citizenship or residence—that is processing the personal information
            of individuals inside the EEA.a
          </p>
          <p>
            Controllers and processors of personal data must put in place&nbsp;
            <em>appropriate technical and organizational measures</em>&nbsp;to
            implement the data protection principles. Business processes that
            handle personal data must be designed and built with consideration
            of the principles and provide safeguards to protect data (for
            example, using&nbsp;
            <a
              title="Pseudonymization"
              href="https://en.wikipedia.org/wiki/Pseudonymization"
            >
              pseudonymization
            </a>
            &nbsp;or full&nbsp;
            <a
              title="Data anonymization"
              href="https://en.wikipedia.org/wiki/Data_anonymization"
            >
              anonymization
            </a>
            &nbsp;where appropriate). Data controllers must design information
            systems with privacy in mind. For instance, using the
            highest-possible privacy settings by default, so that the datasets
            are not publicly available by default and cannot be used to identify
            a subject. No personal data may be processed unless this processing
            is done under one of the six lawful bases specified by the
            regulation (
            <a title="Consent" href="https://en.wikipedia.org/wiki/Consent">
              consent
            </a>
            , contract, public task, vital interest, legitimate interest or
            legal requirement). When the processing is based on consent the data
            subject has the right to revoke it at any time.
          </p>
          <p>
            Data controllers must clearly disclose any&nbsp;
            <a
              title="Data collection"
              href="https://en.wikipedia.org/wiki/Data_collection"
            >
              data collection
            </a>
            , declare the lawful basis and purpose for data processing, and
            state how long data is being retained and if it is being shared with
            any third parties or outside of the EEA. Firms have the obligation
            to protect data of employees and consumers to the degree where only
            the necessary data is extracted with minimum interference with data
            privacy from employees, consumers, or third parties. Firms should
            have internal controls and regulations for various departments such
            as audit, internal controls, and operations. Data subjects have the
            right to request a&nbsp;
            <a
              title="Data portability"
              href="https://en.wikipedia.org/wiki/Data_portability"
            >
              portable
            </a>
            &nbsp;copy of the data collected by a controller in a common format,
            and the right to have their&nbsp;
            <a
              title="Data erasure"
              href="https://en.wikipedia.org/wiki/Data_erasure"
            >
              data erased
            </a>
            &nbsp;under certain circumstances. Public authorities, and
            businesses whose core activities consist of regular or systematic
            processing of personal data, are required to employ a&nbsp;
            <em>data protection officer</em>&nbsp;(DPO), who is responsible for
            managing compliance with the GDPR. Businesses must report&nbsp;
            <a
              title="Data breach"
              href="https://en.wikipedia.org/wiki/Data_breach"
            >
              data breaches
            </a>
            &nbsp;to national supervisory authorities within 72 hours if they
            have an adverse effect on user privacy. In some cases, violators of
            the GDPR may be fined up to €20 million or up to 4% of the annual
            worldwide turnover of the preceding financial year in case of an
            enterprise, whichever is greater.
          </p>
          <p>
            The GDPR was adopted on 14 April 2016, and became enforceable
            beginning 25 May 2018. As the GDPR is a&nbsp;
            <a
              title="Regulation (European Union)"
              href="https://en.wikipedia.org/wiki/Regulation_(European_Union)"
            >
              regulation
            </a>
            , not a&nbsp;
            <a
              title="Directive (European Union)"
              href="https://en.wikipedia.org/wiki/Directive_(European_Union)"
            >
              directive
            </a>
            , it is directly binding and applicable, but does provide
            flexibility for certain aspects of the regulation to be adjusted by
            individual member states.
          </p>
          <p>
            The regulation became a model for many national laws outside EU,
            including Chile, Japan, Brazil, South Korea, Argentina and Kenya.
            The&nbsp;
            <a
              title="California Consumer Privacy Act"
              href="https://en.wikipedia.org/wiki/California_Consumer_Privacy_Act"
            >
              California Consumer Privacy Act
            </a>
            &nbsp;(CCPA), adopted on 28 June 2018, has many similarities with
            the GDPR.
            <sup id="cite_ref-2" class="reference">
              <a href="https://en.wikipedia.org/wiki/General_Data_Protection_Regulation#cite_note-2">
                [2]
              </a>
            </sup>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Gdpr;
