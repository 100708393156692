// import React, { useState, useEffect } from "react";
// import memberpic from "../../Images/blog-project-memberpic.jpg";
// import post1 from "../../Images/blog-project-post1.jpg";
// import Footer from "../Home/Footer/Footer";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { ngrokUrl } from "../../Utility/data";
// import Loader from "../Loader/Loader";

// function BlogArea() {
//   const [posts, setPosts] = useState([]);
//   const [sidebar, setSidebar] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [errorSidebar, setErrorSidebar] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const token = localStorage.getItem("token");

//         // Fetch all posts data
//         const response = await axios.get(`${ngrokUrl.url}/api/readAllPosts`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "ngrok-skip-browser-warning": "69420",
//           },
//         });

//         setPosts(response.data);

//         setLoading(false);
//       } catch (error) {
//         console.error("Failed to fetch data.", error);
//         setError("Failed to fetch data.");
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   if (loading) {
//     return <Loader />;
//   }

//   return (
//     <div>
//       <div className="breadcrumb-area">
//         <h1 className="breadcrumb-title">Our recent news</h1>
//         <ul className="page-list">
//           <li className="item-home">
//             <a
//               className="bread-link"
//               onClick={() => navigate("/")}
//               title="Home"
//             >
//               Home
//             </a>
//           </li>
//           <li className="separator separator-home"></li>
//           <li className="item-current">Our recent news</li>
//         </ul>
//       </div>

//       <div className="blog-page-section">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-8">
//               {posts.map((post) => (
//                 <article key={post.id} className="single-post blogloop-v2">
//                   <div className="blog_custom">
//                     <div className="post-thumbnail">
//                       <a href={post.link}>
//                         <img
//                           className="blog_post_image img-fluid lazy"
//                           style={{ width: "717px", height: "380px" }}
//                           src={post.photo_url}
//                           alt={post.title}
//                         />
//                       </a>
//                     </div>
//                     <span className="post-date">
//                       {new Date(post.created_at).toLocaleDateString("en-US", {
//                         year: "numeric",
//                         month: "long",
//                         day: "numeric",
//                       })}
//                     </span>
//                     <div className="post-details">
//                       <div className="post-details-holder">
//                         <div className="post-author-avatar">
//                           <img
//                             alt=""
//                             src={memberpic}
//                             className="avatar img-fluid lazy"
//                             style={{ height: "120px", width: "120px" }}
//                           />
//                         </div>

//                         <h2 className="post-name">
//                           <a href={post.link}>{post.title}</a>
//                         </h2>

//                         <div className="post-category-comment-date">
//                           <span className="post-tags">
//                             <i className="fa fa-tag"></i> {post.categories}
//                           </span>
//                         </div>

//                         <div className="post-excerpt">
//                           <p>{post.body}</p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </article>
//               ))}
//             </div>

//             <div className="col-md-4">
//               <div className="widget_element">
//                 <h3 style={{ textAlign: "center" }} className="widget-title">
//                   About us
//                 </h3>
//                 <div className="textwidget">
//                   <a href="/about-us">
//                     <img
//                       className="html-widget-image img-fluid"
//                       src={post1}
//                       alt=""
//                     />
//                   </a>
//                   <p className="html-widget-paragraph">
//                     {sidebar.sidebar_html_one}
//                   </p>
//                   <a
//                     className="btn btn-style1"
//                     href="/about-us"
//                     style={{ position: "relative", left: "60px" }}
//                   >
//                     Read More
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// }

// export default BlogArea;
import React, { useState, useEffect } from "react";
import memberpic from "../../Images/blog-project-memberpic.jpg";
import post1 from "../../Images/blog-project-post1.jpg";
import Footer from "../Home/Footer/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import Loader from "../Loader/Loader";

function BlogArea() {
  const [posts, setPosts] = useState([]);
  const [sidebar, setSidebar] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingSidebar, setLoadingSidebar] = useState(false);
  const [error, setError] = useState(null);
  const [errorSidebar, setErrorSidebar] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3; // Number of posts per page
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");

        // Fetch all posts data
        const response = await axios.get(`${ngrokUrl.url}/api/readAllPosts`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        });

        setPosts(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch data.", error);
        setError("Failed to fetch data.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const SideBarValues = async () => {
      setLoadingSidebar(true);
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readOurRecentNewsById/1`
        );
        setSidebar(response.data);
        setLoadingSidebar(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorSidebar("Failed to fetch default values.");
        setLoadingSidebar(false);
      }
    };
    SideBarValues();
  }, []);

  if (loading) {
    return <Loader />;
  }

  // Pagination logic
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(posts.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination controls
  const paginationControls = (
    <div className="pagination-controls">
      <span
        className={`round-effect ${currentPage === 1 ? "disabled" : ""}`}
        onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
      >
        &lsaquo;
      </span>
      {[...Array(totalPages).keys()].map((number) => (
        <span
          key={number + 1}
          className={`round-effect ${
            currentPage === number + 1 ? "active" : ""
          }`}
          onClick={() => handlePageChange(number + 1)}
        >
          {number + 1}
        </span>
      ))}
      <span
        className={`round-effect ${
          currentPage === totalPages ? "disabled" : ""
        }`}
        onClick={() =>
          currentPage < totalPages && handlePageChange(currentPage + 1)
        }
      >
        &rsaquo;
      </span>
    </div>
  );

  return (
    <div>
      <div className="breadcrumb-area">
        <h1 className="breadcrumb-title">Our recent news</h1>
        <ul className="page-list">
          <li className="item-home">
            <a
              className="bread-link"
              onClick={() => navigate("/")}
              title="Home"
            >
              Home
            </a>
          </li>
          <li className="separator separator-home"></li>
          <li className="item-current">Our recent news</li>
        </ul>
      </div>

      <div className="blog-page-section">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              {currentPosts.map((post) => (
                <article key={post.id} className="single-post blogloop-v2">
                  <div className="blog_custom">
                    <div className="post-thumbnail">
                      <a href={post.link}>
                        <img
                          className="blog_post_image img-fluid lazy"
                          style={{ width: "717px", height: "380px" }}
                          src={post.photo_url}
                          alt={post.title}
                        />
                      </a>
                    </div>
                    <span className="post-date">
                      {new Date(post.created_at).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </span>
                    <div className="post-details">
                      <div className="post-details-holder">
                        <div className="post-author-avatar">
                          <img
                            alt=""
                            src={memberpic}
                            className="avatar img-fluid lazy"
                            style={{ height: "120px", width: "120px" }}
                          />
                        </div>

                        <h2 className="post-name">
                          <a href={post.link}>{post.title}</a>
                        </h2>

                        <div className="post-category-comment-date">
                          <span className="post-tags">
                            <i className="fa fa-tag"></i> {post.categories}
                          </span>
                        </div>

                        <div className="post-excerpt">
                          <p>{post.body}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              ))}
              {paginationControls}
            </div>

            <div className="col-md-4">
              <div className="widget_element">
                <h3 style={{ textAlign: "center" }} className="widget-title">
                  About us
                </h3>
                <div className="textwidget">
                  <a href="/about-us">
                    <img
                      className="html-widget-image img-fluid"
                      src={post1}
                      alt=""
                    />
                  </a>
                  <p className="html-widget-paragraph">
                    {sidebar.sidebar_html_one}
                  </p>
                  <a
                    className="btn btn-style1"
                    href="/about-us"
                    style={{ position: "relative", left: "60px" }}
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BlogArea;
