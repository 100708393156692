import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import "../Pages/EditPage/editpage.css";

function PostCategories() {
  const [categoriesData, setCategoriesData] = useState({});
  const [value, setValue] = useState({
    categories: "",
  });
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const categoriesValues = async () => {
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readPostCategories`
        );

        setCategoriesData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
      }
    };

    categoriesValues();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${ngrokUrl.url}/api/savePostCategories`,
        value,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      setValue(response.data);
      setSuccess("Categories added Successfully!");
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.error("Failed to add categories:", error);

      setError("Failed to add categories ");
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.delete(
        `${ngrokUrl.url}/api/deletePostCategories/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      setSuccess("categories deleted successfully.");
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete categories.", error);
      setError("Failed to delete categories.");
    }
  };

  const tableData = Array.isArray(categoriesData) ? categoriesData : [];

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Categories</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              Categories
            </h6>
          </div>
          <div className="card-body mt-3">
            <div className="table-responsive">
              <div className="row align-items-center mb-3">
                <div className="col-md-6">
                  <Link to="/dashboard/allPosts" className="dashboardBtn">
                    Back to Blog page
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <form className="form-inline">
                    <input
                      type="hidden"
                      name="_token"
                      value="9kr4A0f0neruHdqd4e2UADuzDOw4VyxcaJSUzoO3"
                    />{" "}
                    <input type="hidden" name="_method" value="DELETE" />{" "}
                    <table
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellspacing="0"
                    >
                      <thead>
                        <tr>
                          <th scope="col" className="header_heady">
                            ID
                          </th>
                          <th scope="col" className="header_heady">
                            Name
                          </th>
                          <th scope="col" className="header_heady" colSpan={2}>
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <th scope="col" className="header_heady">
                            ID
                          </th>
                          <th scope="col" className="header_heady">
                            Name
                          </th>
                          <th scope="col" className="header_heady" colSpan={2}>
                            Actions
                          </th>
                        </tr>
                      </tfoot>
                      <tbody>
                        {tableData.map((item) => (
                          <tr key={item.id}>
                            <td data-label="ID" className="label_dimension">
                              {item.id}
                            </td>
                            <td data-label="name" className="label_dimension">
                              {item.categories}
                            </td>
                            <td className="body-url" data-label="BODY">
                              <Link
                                to={`/dashboard/postCategoriesEdit/${item.id}/edit?language=en`}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Link>

                              <FontAwesomeIcon
                                icon={faTrash}
                                className="action-button"
                                onClick={() => handleDelete(item.id)}
                                style={{ marginLeft: "25px" }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {error && (
                      <div style={{ color: "red", fontSize: "14px" }}>
                        {error}
                      </div>
                    )}
                    {success && (
                      <div style={{ color: "green", fontSize: "14px" }}>
                        {success}
                      </div>
                    )}
                  </form>
                </div>

                <div className="col-md-6">
                  <form onSubmit={handleSubmit}>
                    <input
                      type="hidden"
                      name="_token"
                      value="9kr4A0f0neruHdqd4e2UADuzDOw4VyxcaJSUzoO3"
                    />
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="form-group">
                          <strong className="header_heady">Category</strong>
                          <input
                            type="text"
                            name="categories"
                            className="form-control input_height"
                            onChange={handleChange}
                            style={{ fontSize: "15px" }}
                          />
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                        {loading ? (
                          <p>Loading...</p>
                        ) : (
                          <button type="submit" className="cardBtn">
                            Create
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostCategories;
