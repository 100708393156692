// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { ngrokUrl } from "../../Utility/data";
// import "./portfolio.css";
// import Loader from "../Loader/Loader";

// function PortfolioSectionFilters() {
//   const [projectData, setProjectData] = useState([]);
//   const [categoriesData, setCategoriesData] = useState([]);
//   const [error, setError] = useState(null);
//   const [activeFilter, setActiveFilter] = useState("All");
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(true);
//   const [isLoadingCategories, setIsLoadingCategories] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const responseProject = await axios.get(
//           `${ngrokUrl.url}/api/readSectionProject`
//         );

//         const responseCategories = await axios.get(
//           `${ngrokUrl.url}/api/readCategories`
//         );

//         setProjectData(responseProject.data);
//         setCategoriesData(responseCategories.data);
//         setLoading(false);
//       } catch (error) {
//         console.error("Failed to fetch data.", error);
//         setError("Failed to fetch data.");
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (categoriesData.length > 0) {
//       setIsLoadingCategories(false);
//     }
//   }, [categoriesData]);

//   const handleFilterChange = (filter) => {
//     setActiveFilter(filter);
//   };

//   return (
//     <>
//       {loading ? (
//         <Loader />
//       ) : (
//         <div className="portfolio-section-filters">
//           <div className="container">
//             <div className="row">
//               <div className="col-md-3">
//                 <div className="filters">
//                   <h4>Sort by</h4>
//                   <span
//                     className={`filter ${
//                       activeFilter === "All" ? "activecategories" : ""
//                     }`}
//                     onClick={() => handleFilterChange("All")}
//                   >
//                     All
//                   </span>

//                   <div>
//                     {categoriesData.map((category) => (
//                       <span
//                         key={category.id}
//                         className={`filter ${
//                           activeFilter === category.categories
//                             ? "activecategories"
//                             : ""
//                         }`}
//                         onClick={() => handleFilterChange(category.categories)}
//                       >
//                         {category.categories}
//                       </span>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//               <div className="col-md-9">
//                 <div className="projects row">
//                   {projectData.map((project) => (
//                     <div
//                       key={project.id}
//                       className={`project col-md-6 ${
//                         activeFilter === "All" ||
//                         activeFilter === project.categories
//                           ? "show"
//                           : "hide"
//                       }`}
//                       data-filter={project.categories}
//                     >
//                       <div className="project-thumbnail">
//                         <a
//                           onClick={() => navigate(`/project/${project.link}`)}
//                           title=""
//                         >
//                           <img
//                             width="400"
//                             height="250"
//                             src={project.photo_url}
//                             className="lazy img-fluid"
//                             alt={project.title}
//                           />
//                         </a>
//                       </div>
//                       <h4 className="entry-details-title">
//                         <a onClick={() => navigate(`/project/${project.link}`)}>
//                           {project.title}
//                         </a>
//                       </h4>
//                       <h5 className="project-category">{project.categories}</h5>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

// export default PortfolioSectionFilters;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import "./portfolio.css";
import Loader from "../Loader/Loader";

function PortfolioSectionFilters() {
  const [projectData, setProjectData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [error, setError] = useState(null);
  const [activeFilter, setActiveFilter] = useState("All");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4; // Adjust based on your design

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseProject = await axios.get(
          `${ngrokUrl.url}/api/readSectionProject`
        );

        const responseCategories = await axios.get(
          `${ngrokUrl.url}/api/readCategories`
        );

        setProjectData(responseProject.data);
        setCategoriesData(responseCategories.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch data.", error);
        setError("Failed to fetch data.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (categoriesData.length > 0) {
      setIsLoadingCategories(false);
    }
  }, [categoriesData]);

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  // Pagination logic
  const filteredProjects = projectData.filter((project) =>
    activeFilter === "All" ? true : project.categories === activeFilter
  );

  const indexOfLastProject = currentPage * itemsPerPage;
  const indexOfFirstProject = indexOfLastProject - itemsPerPage;
  const currentProjects = filteredProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const totalPages = Math.ceil(filteredProjects.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination controls
  const paginationControls = (
    <div className="pagination-controls">
      <span
        className={`round-effect ${currentPage === 1 ? "disabled" : ""}`}
        onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
      >
        &lsaquo;
      </span>
      {[...Array(totalPages).keys()].map((number) => (
        <span
          key={number + 1}
          className={`round-effect ${
            currentPage === number + 1 ? "active" : ""
          }`}
          onClick={() => handlePageChange(number + 1)}
        >
          {number + 1}
        </span>
      ))}
      <span
        className={`round-effect ${
          currentPage === totalPages ? "disabled" : ""
        }`}
        onClick={() =>
          currentPage < totalPages && handlePageChange(currentPage + 1)
        }
      >
        &rsaquo;
      </span>
    </div>
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="portfolio-section-filters">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="filters">
                  <h4>Sort by</h4>
                  <span
                    className={`filter ${
                      activeFilter === "All" ? "activecategories" : ""
                    }`}
                    onClick={() => handleFilterChange("All")}
                  >
                    All
                  </span>
                  <div>
                    {categoriesData.map((category) => (
                      <span
                        key={category.id}
                        className={`filter ${
                          activeFilter === category.categories
                            ? "activecategories"
                            : ""
                        }`}
                        onClick={() => handleFilterChange(category.categories)}
                      >
                        {category.categories}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="projects row">
                  {currentProjects.map((project) => (
                    <div
                      key={project.id}
                      className={`project col-md-6 ${
                        activeFilter === "All" ||
                        activeFilter === project.categories
                          ? "show"
                          : "hide"
                      }`}
                      data-filter={project.categories}
                    >
                      <div className="project-thumbnail">
                        <a
                          onClick={() => navigate(`/project/${project.link}`)}
                          title=""
                        >
                          <img
                            width="400"
                            height="250"
                            src={project.photo_url}
                            className="lazy img-fluid"
                            alt={project.title}
                          />
                        </a>
                      </div>
                      <h4 className="entry-details-title">
                        <a onClick={() => navigate(`/project/${project.link}`)}>
                          {project.title}
                        </a>
                      </h4>
                      <h5 className="project-category">{project.categories}</h5>
                    </div>
                  ))}
                </div>
                {paginationControls}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PortfolioSectionFilters;
