import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import "../Pages/EditPage/editpage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

function ManageClients() {
  const [client, setClients] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 2000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 2000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  useEffect(() => {
    const clientsValues = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${ngrokUrl.url}/api/readClient`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        });
        setClients(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    clientsValues();
  }, []);

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      await axios.delete(`${ngrokUrl.url}/api/deleteClient/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      });

      setSuccess("Clients deleted successfully.");
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete item.", error);
      setError("Failed to delete item.");
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">All clients</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              All clients
            </h6>
          </div>
          <div className="card-body mt-3">
            <div className="table-responsive">
              <Link to="/dashboard/aboutSetting" className="dashboardBtn">
                Back to About page
              </Link>
              <Link
                to="/dashboard/contactSetting"
                className="dashboardBtn ml-3"
              >
                Back to Contact page
              </Link>
              <Link to="/dashboard/createClient" className="dashboardBtn ml-3">
                Create
              </Link>

              <form>
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th className="header_heady">Photo</th>
                      <th className="header_heady">Company name</th>
                      <th className="header_heady">Company link</th>
                      <th
                        scope="colgroup"
                        className="header_heady "
                        colSpan="2"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th className="header_heady">Photo</th>
                      <th className="header_heady">Company name</th>
                      <th className="header_heady">Company link</th>
                      <th
                        scope="colgroup"
                        className="header_heady "
                        colSpan="2"
                      >
                        Actions
                      </th>
                    </tr>
                  </tfoot>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="3">Loading...</td>
                      </tr>
                    ) : error ? (
                      <tr>
                        <td colSpan="3">Error: {error}</td>
                      </tr>
                    ) : client.length === 0 ? (
                      <tr>
                        <td colSpan="3">No data found.</td>
                      </tr>
                    ) : (
                      Array.isArray(client) &&
                      client.map((client) => (
                        <tr key={client.id}>
                          <td>
                            <img
                              height="100"
                              src={client.photo_url}
                              alt="client"
                            />
                          </td>
                          <td data-label="link">{client.company_name}</td>
                          <td data-label="link" className="label_dimension">
                            {client.company_link}
                          </td>
                          <td className="body-url" data-label="BODY">
                            <Link
                              to={`/dashboard/editClients/${client.id}/edit?language=en`}
                              className="action-button"
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Link>

                            <FontAwesomeIcon
                              icon={faTrash}
                              className="action-button"
                              onClick={() => handleDelete(client.id)}
                              style={{ marginLeft: "25px" }}
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                {error && (
                  <div style={{ color: "red", fontSize: "14px" }}>{error}</div>
                )}
                {success && (
                  <div style={{ color: "green", fontSize: "14px" }}>
                    {success}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageClients;
