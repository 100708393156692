import React, { useEffect } from "react";
import ContactSection from "../Components/Contact/ContactSection";
import ContactPageMap from "../Components/Contact/ContactPageMap";
import Client from "../Components/AboutUs/ClientTestimonialSection/Client";
import Footer from "../Components/Home/Footer/Footer";
import NavBar from "../Utility/NavBar";
import Topbar from "../Topbar/Topbar";
import Whatsapp from "../Components/TopupButtons/Whatsapp";

import { useState } from "react";

function Contact() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <>
        <Topbar />
        <Whatsapp />
        <NavBar />
        <ContactSection />
        <ContactPageMap />
        <Client />
        <Footer />
      </>
    </div>
  );
}
export default Contact;
