import React, { useState, useEffect } from "react";
import "./template.css";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";

const Contactus = () => {
  const [information, setInformation] = useState({});
  const [formInfo, setFormInfo] = useState({});
  const [seo, setSeo] = useState({});

  const [loadinginfo, setLoadinginfo] = useState(false);
  const [errorinfo, setErrorinfo] = useState(null);
  const [successinfo, setSuccessinfo] = useState(null);

  const [loadingFormInfo, setLoadingFormInfo] = useState(false);
  const [errorFormInfo, setErrorFormInfo] = useState(null);
  const [successFormInfo, setSuccessFormInfo] = useState(null);

  const [loadingSeo, setLoadingSeo] = useState(false);
  const [errorSeo, setErrorSeo] = useState(null);
  const [successSeo, setSuccessSeo] = useState(null);

  useEffect(() => {
    const timeouts = {};

    const manageTimeout = (state, setter, key) => {
      if (state) {
        timeouts[key] = setTimeout(() => setter(null), 2000);
      }
    };

    manageTimeout(errorinfo, setErrorinfo, "errorinfo");
    manageTimeout(successinfo, setSuccessinfo, "successinfo");
    manageTimeout(errorFormInfo, setErrorFormInfo, "errorinfo");
    manageTimeout(successFormInfo, setSuccessFormInfo, "successinfo");
    manageTimeout(errorSeo, setErrorSeo, "errorSeo");
    manageTimeout(successSeo, setSuccessSeo, "successSeo");

    return () => {
      Object.values(timeouts).forEach(clearTimeout);
    };
  }, [
    errorinfo,
    successinfo,
    errorFormInfo,
    errorSeo,
    successFormInfo,
    successSeo,
  ]);

  //fecth values
  useEffect(() => {
    const contactInformation = async () => {
      setLoadinginfo(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readContactUs/1`);
        setInformation(response.data);
        setLoadinginfo(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorinfo("Failed to fetch default values.");
        setLoadinginfo(false);
      }
    };
    contactInformation();
  }, []);

  useEffect(() => {
    const FormInformation = async () => {
      setLoadingFormInfo(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readContactUs/1`);
        setFormInfo(response.data);
        setLoadingFormInfo(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorFormInfo("Failed to fetch default values.");
        setLoadingFormInfo(false);
      }
    };
    FormInformation();
  }, []);

  useEffect(() => {
    const SeoValues = async () => {
      setLoadingSeo(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readContactUs/1`);
        setSeo(response.data);
        setLoadingSeo(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorSeo("Failed to fetch default values.");
        setLoadingSeo(false);
      }
    };
    SeoValues();
  }, []);

  //onchange
  const handleContactChange = (e) => {
    const { name, value, type } = e.target;
    setInformation({
      ...information,
      [name]: value,
    });
  };

  const handleFormInfoChange = (e) => {
    const { name, value, type } = e.target;
    setFormInfo({
      ...formInfo,
      [name]: value,
    });
  };

  const handleSeoChange = (e) => {
    const { name, value } = e.target;
    setSeo({
      ...seo,
      [name]: value,
    });
  };

  const handleContactInformationSubmit = async (e) => {
    e.preventDefault();
    setLoadinginfo(true);
    setErrorinfo(null);
    setSuccessinfo(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateContactUsPage/1`,
        information,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setInformation(response.data);
        setSuccessinfo("Contact Information Updated Successfully!");
        setLoadinginfo(false);
      } else {
        throw new Error("Failed to updated Contact Information ");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setErrorinfo("Failed to update Contact Information");
      setLoadinginfo(false);
    }
  };

  const handleFormInformationSubmit = async (e) => {
    e.preventDefault();
    setLoadingFormInfo(true);
    setErrorFormInfo(null);
    setSuccessFormInfo(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateContactUsPages_two/1`,
        formInfo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setFormInfo(response.data);
        setSuccessFormInfo("Contact Information Updated Successfully!");
        setLoadingFormInfo(false);
      } else {
        throw new Error("Failed to updated Contact Information ");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setErrorFormInfo("Failed to update Contact Information");
      setLoadingFormInfo(false);
    }
  };

  const handleSeoSubmit = async (e) => {
    e.preventDefault();
    setLoadingSeo(true);
    setErrorSeo(null);
    setSuccessSeo(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateContactUs_four/1`,
        seo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        setSeo(response.data);
        setSuccessSeo("Seo Updated Successfully!");
        setLoadingSeo(false);
      } else {
        throw new Error("Failed to updated Seo");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);
      setErrorSeo("Failed to update Seo");
      setLoadingSeo(false);
    }
  };

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Contact settings</h1>

      <div className="row">
        <div className="col-md-12">
          <div className="card shadow mb-4">
            <div className="card-header templete py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Contact information
              </h6>
            </div>
            <div className="card-body">
              <form onSubmit={handleContactInformationSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="E4pBwjaWwuNPwgVs4YuelK0JQjB33VUagBimTf3n"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-md-4">
                    <div className="formgroup">
                      <h5>
                        Box icon <i className="fas fa-phone-volume"></i>
                      </h5>
                      <input
                        type="text"
                        name="box_call"
                        className="formControl"
                        value={information.box_call}
                        onChange={handleContactChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="formgroup">
                      <h5>
                        Box icon <i className="fas fa-envelope"></i>
                      </h5>
                      <input
                        type="text"
                        name="box_mail"
                        className="formControl"
                        value={information.box_mail}
                        onChange={handleContactChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="formgroup">
                      <h5>
                        Box icon <i className="fas fa-map-marker-alt"></i>
                      </h5>
                      <input
                        type="text"
                        name="box_location"
                        className="formControl"
                        value={information.box_location}
                        onChange={handleContactChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="formgroup">
                      <h5>Box title</h5>
                      <input
                        type="text"
                        name="box_title_call_us_today"
                        className="formControl"
                        value={information.box_title_call_us_today}
                        onChange={handleContactChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="formgroup">
                      <h5>Box title</h5>
                      <input
                        type="text"
                        name="box_title_mails"
                        className="formControl"
                        value={information.box_title_mails}
                        onChange={handleContactChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="formgroup">
                      <h5>Box title</h5>
                      <input
                        type="text"
                        name="box_title_address"
                        className="formControl"
                        value={information.box_title_address}
                        onChange={handleContactChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="formgroup">
                      <h5>Box description</h5>
                      <textarea
                        name="box_desc_one"
                        className="formControl"
                        rows="6"
                        id="mce_0"
                        aria-hidden="true"
                        onChange={handleContactChange}
                        value={information.box_desc_one}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="formgroup">
                      <h5>Box description</h5>
                      <textarea
                        name="box_desc_two"
                        className="formControl"
                        rows="6"
                        id="mce_2"
                        aria-hidden="true"
                        onChange={handleContactChange}
                        value={information.box_desc_two}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="formgroup">
                      <h5>Box description</h5>
                      <textarea
                        name="box_desc_three"
                        className="formControl"
                        rows="6"
                        id="mce_3"
                        aria-hidden="true"
                        onChange={handleContactChange}
                        value={information.box_desc_three}
                      ></textarea>
                      <div
                        role="application"
                        className="tox tox-tinymce tox-tinymce--disabled"
                        aria-disabled="true"
                      >
                        <div aria-hidden="true" className="tox-throbber"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button
                      type="submit"
                      className="btn btn-primary cardBtn"
                      disabled=""
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
              {errorinfo && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {errorinfo}
                </div>
              )}
              {successinfo && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {successinfo}{" "}
                </div>
              )}
            </div>
          </div>

          <div className="card shadow mb-4">
            <div className="card-header  templete py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Form information
              </h6>
            </div>
            <div className="card-body">
              <form onSubmit={handleFormInformationSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="E4pBwjaWwuNPwgVs4YuelK0JQjB33VUagBimTf3n"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="formgroup">
                  <h5>Title form</h5>
                  <input
                    type="text"
                    name="title_form"
                    className="formControl"
                    value={formInfo.title_form}
                    onChange={handleFormInfoChange}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>Form input text 1</h5>
                      <input
                        type="text"
                        name="form_input_text_one"
                        className="formControl"
                        value={formInfo.form_input_text_one}
                        onChange={handleFormInfoChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>Form input text 2</h5>
                      <input
                        type="text"
                        name="form_input_text_two"
                        className="formControl"
                        value={formInfo.form_input_text_two}
                        onChange={handleFormInfoChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>Form input text 3</h5>
                      <input
                        type="text"
                        name="form_input_text_three"
                        className="formControl"
                        value={formInfo.form_input_text_three}
                        onChange={handleFormInfoChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>Form input text 4</h5>
                      <input
                        type="text"
                        name="form_input_text_four"
                        className="formControl"
                        value={formInfo.form_input_text_four}
                        onChange={handleFormInfoChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="formgroup">
                  <h5>Form textarea text</h5>
                  <input
                    type="text"
                    name="form_text_area"
                    className="formControl"
                    value={formInfo.form_text_area}
                    onChange={handleFormInfoChange}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>Button text</h5>
                      <input
                        type="text"
                        name="button_text"
                        className="formControl"
                        value={formInfo.button_text}
                        onChange={handleFormInfoChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>Mail to @email</h5>
                      <input
                        type="text"
                        name="mail_to"
                        className="formControl"
                        value={formInfo.mail_to}
                        onChange={handleFormInfoChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button
                      type="submit"
                      className="btn btn-primary cardBtn"
                      disabled=""
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
              {errorFormInfo && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {errorFormInfo}
                </div>
              )}
              {successFormInfo && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {successFormInfo}{" "}
                </div>
              )}
            </div>
          </div>

          <div className="card shadow mb-4">
            <div className="card-header  templete py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Map information
              </h6>
            </div>
            <div className="card-body">
              <form method="POST" enctype="multipart/form-data">
                <input
                  type="hidden"
                  name="_token"
                  value="E4pBwjaWwuNPwgVs4YuelK0JQjB33VUagBimTf3n"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="formgroup">
                  <h5>Title</h5>
                  <input
                    type="text"
                    name="title"
                    className="formControl"
                    value="Where we are"
                  />
                </div>
                <div className="formgroup">
                  <h5>Map iframe</h5>

                  <div
                    className="mapContainer"
                    style={{
                      height: "40vh",
                      overflowY: "scroll",
                    }}
                  >
                    {" "}
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15542.589980268205!2d77.6253029!3d13.1215035!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae196b7b8fcfcb%3A0x6c2268d978bd3d16!2sTECHMINDSCAPE%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1720793454337!5m2!1sen!2sin"
                      width="1000"
                      height="450"
                      style={{ border: "0" }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button
                      type="submit"
                      className="btn btn-primary cardBtn"
                      disabled=""
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="card shadow mb-4">
            <div className="card-header  templete py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Section Clients
              </h6>
            </div>
            <div className="card-body">
              <div className="mb-3">
                <a
                  className="cardBtn  m-0"
                  href="/dashboard/manageclients?language=en"
                >
                  View All
                </a>
                <a
                  className="cardBtn ml-3 m-0"
                  href="/dashboard/createClient?language=en"
                >
                  Create
                </a>
              </div>
            </div>
          </div>

          <div className="card shadow mb-4">
            <div className="card-header templete  py-3">
              <h6 className="m-0 font-weight-bold text-primary">SEO</h6>
            </div>
            <div className="card-body">
              <form onSubmit={handleSeoSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="qo6swE7Ef17bxcxuScLXpgr78RLOXpTnCBG1QWEh"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>meta title</h5>
                      <input
                        type="text"
                        name="seo_meta_title"
                        className="formControl"
                        value={seo.seo_meta_title}
                        onChange={handleSeoChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>meta description</h5>
                      <input
                        type="text"
                        name="seo_meta_description"
                        className="formControl"
                        value={seo.seo_meta_description}
                        onChange={handleSeoChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>Slug</h5>
                      <input
                        type="text"
                        name="seo_slug"
                        className="formControl"
                        value={seo.seo_slug}
                        onChange={handleSeoChange}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="formgroup">
                      <h5>Anchor text</h5>
                      <input
                        type="text"
                        name="seo_anchor_text"
                        class="formControl"
                        value={seo.seo_anchor_text}
                        onChange={handleSeoChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button
                      type="submit"
                      className="btn btn-primary cardBtn"
                      disabled=""
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
              {errorSeo && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {errorSeo}
                </div>
              )}
              {successSeo && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {successSeo}{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
