import React, { useState, useEffect } from "react";
import "./template.css";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";

const AboutNiva = () => {
  const [aboutSettings, setAboutSettings] = useState({});
  const [members, setMembers] = useState({});
  const [seo, setSeo] = useState({});

  const [loadingSettings, setLoadingSettings] = useState(false);
  const [errorSettings, setErrorSettings] = useState(null);
  const [successSettings, setSuccessSettings] = useState(null);

  const [loadingMembers, setLoadingMembers] = useState(false);
  const [errorMembers, setErrorMembers] = useState(null);
  const [successMembers, setSuccessMembers] = useState(null);

  const [loadingSeo, setLoadingSeo] = useState(false);
  const [errorSeo, setErrorSeo] = useState(null);
  const [successSeo, setSuccessSeo] = useState(null);

  //timer
  useEffect(() => {
    const timeouts = {};

    const manageTimeout = (state, setter, key) => {
      if (state) {
        timeouts[key] = setTimeout(() => setter(null), 2000);
      }
    };

    manageTimeout(errorSettings, setErrorSettings, "errorSettings");
    manageTimeout(successSettings, setSuccessSettings, "successSettings");
    manageTimeout(errorMembers, setErrorMembers, "errorMembers");
    manageTimeout(successMembers, setSuccessMembers, "successMembers");

    manageTimeout(errorSeo, setErrorSeo, "errorSeo");
    manageTimeout(successSeo, setSuccessSeo, "successSeo");

    return () => {
      Object.values(timeouts).forEach(clearTimeout);
    };
  }, [
    errorSettings,
    errorMembers,
    errorSeo,
    successSettings,
    successMembers,
    successSeo,
  ]);

  //fetch values
  useEffect(() => {
    const settingsValues = async () => {
      setLoadingSettings(true);
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readAboutSetting/1`
        );
        setAboutSettings(response.data);
        setLoadingSettings(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorSettings("Failed to fetch default values.");
        setLoadingSettings(false);
      }
    };
    settingsValues();
  }, []);

  useEffect(() => {
    const memberValues = async () => {
      setLoadingMembers(true);
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readMemberTitle/1`
        );
        setMembers(response.data);
        setLoadingMembers(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorMembers("Failed to fetch default values.");
        setLoadingMembers(false);
      }
    };
    memberValues();
  }, []);

  useEffect(() => {
    const seoValues = async () => {
      setLoadingSeo(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readSeo/1`);
        setSeo(response.data);
        setLoadingSeo(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorSeo("Failed to fetch default values.");
        setLoadingSeo(false);
      }
    };
    seoValues();
  }, []);

  //onChange
  const handleSettingsChange = (e) => {
    const { name, value } = e.target;
    setAboutSettings({
      ...aboutSettings,
      [name]: value,
    });
  };

  const handlememberChange = (e) => {
    const { name, value } = e.target;
    setMembers({
      ...members,
      [name]: value,
    });
  };

  const handleSeoChange = (e) => {
    const { name, value } = e.target;
    setSeo({
      ...seo,
      [name]: value,
    });
  };

  //FormSubmit
  const handleSettingsSubmit = async (e) => {
    e.preventDefault();
    setLoadingSettings(true);
    setErrorSettings(null);
    setSuccessSettings(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateAboutSetting/1`,
        aboutSettings,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setAboutSettings(response.data);
        setSuccessSettings("About Settings  Updated Successfully!");
        setLoadingSettings(false);
      } else {
        throw new Error("Failed to updated About Settings");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setErrorSettings("Failed to update About Settings");
      setLoadingSettings(false);
    }
  };

  const handleMembersSubmit = async (e) => {
    e.preventDefault();
    setLoadingMembers(true);
    setErrorMembers(null);
    setSuccessMembers(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateMemberTitle/1`,
        members,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setMembers(response.data);
        setSuccessMembers("Title Updated Successfully!");
        setLoadingMembers(false);
      } else {
        throw new Error("Failed to update Title");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setErrorMembers("Failed to update Title");
      setLoadingMembers(false);
    }
  };

  const handleSeoSubmit = async (e) => {
    e.preventDefault();
    setLoadingSeo(true);
    setErrorSeo(null);
    setSuccessSeo(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateAboutNiva/1`,
        seo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setSeo(response.data);
        setSuccessSeo("Seo  Updated Successfully!");
        setLoadingSeo(false);
      } else {
        throw new Error("Failed to updated Seo");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setErrorSeo("Failed to update Seo");
      setLoadingSeo(false);
    }
  };

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">About settings</h1>

      <div className="row">
        <div className="col-md-12">
          <div className="card shadow mb-4">
            <div className="card-header templete py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                About settings
              </h6>
            </div>
            <div className="card-body">
              <form onSubmit={handleSettingsSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="E4pBwjaWwuNPwgVs4YuelK0JQjB33VUagBimTf3n"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Subtitle</h5>
                          <input
                            type="text"
                            name="subtitle"
                            className="formControl"
                            value={aboutSettings.subtitle}
                            onChange={handleSettingsChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Title</h5>
                          <input
                            type="text"
                            name="title"
                            className="formControl"
                            value={aboutSettings.title}
                            onChange={handleSettingsChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="formgroup">
                      <h5>Description</h5>
                      <textarea
                        name="description"
                        className="form-control"
                        rows="10"
                        id="mce_0"
                        aria-hidden="true"
                        value={aboutSettings.description}
                        onChange={handleSettingsChange}
                        style={{ fontSize: "15px" }}
                      ></textarea>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Button text</h5>
                          <input
                            type="text"
                            name="button_text"
                            className="formControl"
                            value={aboutSettings.button_text}
                            onChange={handleSettingsChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Button link</h5>
                          <input
                            type="text"
                            name="button_link"
                            className="formControl"
                            value={aboutSettings.button_link}
                            onChange={handleSettingsChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Photo</h5>
                          <input
                            type="text"
                            name="photo_url"
                            className="formControl"
                            value={aboutSettings.photo_url}
                            onChange={handleSettingsChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Button youtube link</h5>
                          <input
                            type="text"
                            name="button_youtube_link"
                            className="formControl"
                            value={aboutSettings.button_youtube_link}
                            onChange={handleSettingsChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button
                      type="submit"
                      className="btn btn-primary cardBtn"
                      disabled=""
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
              {errorSettings && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {errorSettings}
                </div>
              )}
              {successSettings && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {successSettings}{" "}
                </div>
              )}
            </div>
          </div>

          <div className="card shadow mb-4">
            <div className="card-header  templete py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Section Members
              </h6>
            </div>
            <div className="card-body">
              <form onSubmit={handleMembersSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="E4pBwjaWwuNPwgVs4YuelK0JQjB33VUagBimTf3n"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="formgroup">
                  <h5>Title</h5>
                  <input
                    type="text"
                    name="title"
                    className="formControl"
                    value={members.title}
                    onChange={handlememberChange}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                  <button
                    type="submit"
                    className="btn btn-primary cardBtn"
                    disabled=""
                  >
                    Update
                  </button>
                </div>
              </form>
              {errorMembers && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {errorMembers}
                </div>
              )}
              {successMembers && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {successMembers}{" "}
                </div>
              )}
              <div className="mb-3">
                <a
                  className="cardBtn  m-0"
                  href="/dashboard/members?language=en"
                >
                  View All
                </a>
                <a
                  className="cardBtn ml-3 m-0"
                  href="/dashboard/createMember?language=en"
                >
                  Create
                </a>
              </div>
            </div>
          </div>

          <div className="card shadow mb-4">
            <div className="card-header templete py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Section - Testimonials
              </h6>
            </div>
            <div className="card-body">
              <div className="mb-3">
                <a
                  className="cardBtn  m-0"
                  href="/dashboard/managetestimonials?language=en"
                >
                  View All
                </a>
                <a
                  className="cardBtn ml-3 m-0"
                  href="/dashboard/createTestimonials?language=en"
                >
                  Create
                </a>
              </div>
            </div>
          </div>

          <div className="card shadow mb-4">
            <div className="card-header  templete py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Section Clients
              </h6>
            </div>
            <div className="card-body">
              <div className="mb-3">
                <a
                  className="cardBtn  m-0"
                  href="/dashboard/manageclients?language=en"
                >
                  View All
                </a>
                <a
                  className="cardBtn ml-3 m-0"
                  href="/dashboard/createClient?language=en"
                >
                  Create
                </a>
              </div>
            </div>
          </div>

          <div className="card shadow mb-4">
            <div className="card-header templete  py-3">
              <h6 className="m-0 font-weight-bold text-primary">SEO</h6>
            </div>
            <div className="card-body">
              <form onSubmit={handleSeoSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="qo6swE7Ef17bxcxuScLXpgr78RLOXpTnCBG1QWEh"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>meta title</h5>
                      <input
                        type="text"
                        name="meta_keyword"
                        className="formControl"
                        value={seo.meta_keyword}
                        onChange={handleSeoChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>meta description</h5>
                      <input
                        type="text"
                        name="meta_desc"
                        className="formControl"
                        value={seo.meta_desc}
                        onChange={handleSeoChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="formgroup">
                      <h5>Slug</h5>
                      <input
                        type="text"
                        name="social_title"
                        className="formControl"
                        value={seo.social_title}
                        onChange={handleSeoChange}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="formgroup">
                      <h5>Anchor text</h5>
                      <input
                        type="text"
                        name="social_desc"
                        class="formControl"
                        value={seo.social_desc}
                        onChange={handleSeoChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button
                      type="submit"
                      className="btn btn-primary cardBtn"
                      disabled=""
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
              {errorSeo && (
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "14px" }}
                >
                  {errorSeo}
                </div>
              )}
              {successSeo && (
                <div
                  className="alert alert-success"
                  style={{ fontSize: "14px" }}
                >
                  {successSeo}{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutNiva;
