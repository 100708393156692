import React from "react";
import "../../App.css";
import { useNavigate } from "react-router-dom";

function PricingElements() {
    const navigate = useNavigate();

    return (
        <div>
            <div class="pricing-elements">

                <div class="container">

                    <h2>Pricing Plans</h2>
                    <p>Whether you need a new logo, website, video, marketing campaign, or ebook created for your business, the key to making the project a success starts with having a well-thought-out creative brief.</p>

                    <div class="row">

                        <div class="col-md-4">
                            <div class="niva-price-box ">
                                <h3><strong>Basic Plan</strong> <span>No coding skills required to create unique sites. Customize your site in real-time and see the results instantly.</span></h3>
                                <div class="plan-features">
                                    <ul>
                                        <li><strong>10GB</strong> Disk Space</li>
                                        <li><strong>100GB</strong> Monthly Bandwith</li>
                                        <li><strong>20</strong> Email Accounts</li>
                                        <li>Unlimited Subdomains</li>
                                    </ul>
                                </div>
                                <a onClick={()=> navigate("/contact")}  class="btn btn-style1" style={{color:"white"}}>Get the offer</a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="niva-price-box  premium-pricing ">
                                <div class="plan-ribbon-wrapper"><div class="plan-ribbon">Popular</div></div> 					    <h3><strong>Professional Plan</strong> <span>No coding skills required to create unique sites. Customize your site in real-time and see the results instantly.</span></h3>
                                <div class="plan-features">
                                    <ul>
                                        <li><strong>10GB</strong> Disk Space</li>
                                        <li><strong>100GB</strong> Monthly Bandwith</li>
                                        <li><strong>20</strong> Email Accounts</li>
                                        <li>Unlimited Subdomains</li>
                                    </ul>
                                </div>
                                <a onClick={()=> navigate("/contact")} class="btn btn-style1" style={{color:"white"}}>Get the offer</a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="niva-price-box ">
                                <h3><strong>Advanced Plan</strong> <span>No coding skills required to create unique sites. Customize your site in real-time and see the results instantly.</span></h3>
                                <div class="plan-features">
                                    <ul>
                                        <li><strong>10GB</strong> Disk Space</li>
                                        <li><strong>100GB</strong> Monthly Bandwith</li>
                                        <li><strong>20</strong> Email Accounts</li>
                                        <li>Unlimited Subdomains</li>
                                    </ul>
                                </div>
                                <a onClick={()=> navigate("/contact")} class="btn btn-style1" style={{color:"white"}}>Get the offer</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default PricingElements;