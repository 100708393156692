import React, { useState, useEffect } from "react";
import "../../Pages/EditPage/editpage.css";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";
import { useParams } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate } from "react-router-dom";

const EditService = () => {
  const navigate = useNavigate();
  const [service, setService] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const val = useParams();
  const id = val.id;

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 3000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 3000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  useEffect(() => {
    const serviceValues = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${ngrokUrl.url}/api/readSectionServicesById/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        setService(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch members Details.", error);
        setError("Failed to fetch members Details");
        setLoading(false);
      }
    };

    if (id) {
      serviceValues();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setService({
      ...service,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const formDataObj = new FormData();
      formDataObj.append("_method", "PUT");
      formDataObj.append("description", service.description);
      formDataObj.append("title", service.title);
      formDataObj.append("box_icon", service.box_icon);
      if (typeof service.photo != "string")
        formDataObj.append("photo", service.photo);

      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${ngrokUrl.url}/api/updateSectionServices/${id}`,
        formDataObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setService(response.data);
        setSuccess("service Details Updated Successfully!");
        setLoading(false);
      } else {
        throw new Error("Failed to updated service");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to update service");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        navigate("/dashboard/manageservices");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [success]);

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Section - Services</h1>
      <div className="card shadow mb-4">
        <div className="card-header templete py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            Section - Services
          </h6>
        </div>
        <a
          href="/dashboard/manageservices?language=en"
          className="btn-back cardBtn m-4 mb-2"
        >
          Back to services
        </a>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="bQa1IU2NI1tyRO907URYzQIyqDY6fe83pMqncSHh"
                />{" "}
                <input type="hidden" name="_method" value="PUT" />
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="formgroup">
                          <h5>
                            Box icon{" "}
                            <a target="_blank" href="https://fontawesome.com/">
                              here
                            </a>
                          </h5>
                          <input
                            type="text"
                            name="box_icon"
                            className="formControl"
                            value={service.box_icon}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="formgroup">
                          <h5>Title</h5>
                          <input
                            type="text"
                            name="title"
                            className="formControl"
                            value={service.title}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="formgroup">
                          <h5>Description</h5>
                          <input
                            type="text"
                            name="description"
                            className="formControl"
                            value={service.description}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="formgroup">
                      <img
                        className="img-fluid pb-4"
                        width="100"
                        height="100"
                        src={service.photo_url}
                      />
                      <p>
                        <h5>Photo</h5>
                      </p>
                      <input
                        type="file"
                        name="photo"
                        className="formControl-file"
                        id="photo_id"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      <button type="submit" className=" cardBtn">
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </form>
              {error && (
                <div style={{ color: "red", fontSize: "14px" }}>{error}</div>
              )}
              {success && (
                <div style={{ color: "green", fontSize: "14px" }}>
                  {success}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditService;
