import React, { useState, useEffect } from "react";
import "../../App.css";
import Footer from "../Home/Footer/Footer";
import NavBar from "../../Utility/NavBar";
import Whatsapp from "../TopupButtons/Whatsapp";
import Topbar from "../../Topbar/Topbar";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import Loader from "../Loader/Loader";
import "./portfolio.css";

function ProjectNivaCms() {
  const [project, setProject] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const projectValues = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readSectionProjectById/${28}`
        );
        setProject(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch members Details.", error);
        setError("Failed to fetch members Details");
        setLoading(false);
      }
    };

    projectValues();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="scrollable-container">
          <Topbar />
          <Whatsapp />
          <NavBar />
          <div className="breadcrumb-area">
            <h1 className="breadcrumb-title">{project.title}</h1>
          </div>

          <div className="project-content">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <h2 className="post-name">{project.title}</h2>
                  <span className="niva-animate-border"></span>
                  <p>{project.body}</p>
                </div>
                <div className="col-md-4  info">
                  <h4 className="post-name" style={{ textAlign: "left" }}>
                    Info{" "}
                  </h4>
                  <span className="niva-animate-border"></span>

                  <p>
                    <strong>{project.duration_project}</strong>
                  </p>
                  <p>
                    <strong>{project.client}</strong>
                  </p>
                  <p>
                    <strong>{project.categories}</strong>
                  </p>

                  <a href={project.button_link} className="btn btn-style1">
                    <span style={{ color: "white" }}>
                      {project.button_text}
                    </span>
                  </a>
                </div>
              </div>

              <div className="gallery">
                <div className="row">
                  <div className="col-md-6">
                    <div className="featured-image">
                      <a>
                        <img
                          className="img-fluid lazy"
                          src={project.photo_url}
                        />
                      </a>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="featured-image">
                      <a>
                        <img
                          className="img-fluid lazy"
                          src={project.photo_one}
                        />
                      </a>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="featured-image">
                      <a>
                        <img
                          className="img-fluid lazy"
                          src={project.photo_two}
                        />
                      </a>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="featured-image">
                      <a>
                        <img
                          className="img-fluid lazy"
                          src={project.photo_three}
                        />
                      </a>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="featured-image">
                      <a>
                        <img
                          className="img-fluid lazy"
                          src={project.photo_four}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </>
  );
}
export default ProjectNivaCms;
