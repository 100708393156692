import React from "react";
import { Link } from "react-router-dom";
import "../Pages/EditPage/editpage.css";

function ProjectPage() {
  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Portfolio Settings</h1>

        <div className="row">
          <div className="col-md-12">
            {/* <!-- projects --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  Section 1
                </h6>
              </div>
              <div className="card-body mt-3">
                <div className="mb-3">
                  <Link to="/dashboard/all-project" className="dashboardBtn ">
                    View All
                  </Link>
                  <Link
                    to="/dashboard/createProject"
                    className="dashboardBtn ml-3 "
                  >
                    Create
                  </Link>
                </div>
              </div>
            </div>
            {/* <!-- projects --> */}

            {/* <!-- SEO --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  SEO
                </h6>
              </div>
              <div className="card-body">
                <form
                  action="https://niva4.lucian.host/admin/portfolio-settings/1/update"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">meta title</strong>
                        <input
                          type="text"
                          name="meta_title"
                          className="form-control label_dimension"
                          value="Our Portfolio"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">
                          meta description
                        </strong>
                        <input
                          type="text"
                          name="meta_description"
                          className="form-control label_dimension"
                          value="Niva projects"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Slug</strong>
                        <input
                          type="text"
                          name="slug"
                          className="form-control label_dimension"
                          value="portfolio"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="label_dimension">Anchor text</strong>
                        <input
                          type="text"
                          name="breadcrumbs_anchor"
                          className="form-control label_dimension"
                          value="Home"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button type="submit" className="cardBtn ">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- SEO --> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectPage;
