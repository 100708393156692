import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import "../Pages/EditPage/editpage.css";

function WebsiteNavigation() {
  const [navigation, setnavigation] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const navigationValues = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${ngrokUrl.url}/api/readMainmenu`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        });

        setnavigation(response.data);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
      }
    };

    navigationValues();
  }, []);

  const handleDelete = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const selectedCheckboxes = document.querySelectorAll(
        ".checkboxes:checked"
      );
      const selectedIds = Array.from(selectedCheckboxes).map(
        (checkbox) => checkbox.value
      );

      await Promise.all(
        selectedIds.map(async (id) => {
          await axios.delete(`${ngrokUrl.url}/api/deleteMainmenu/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": "69420",
            },
          });
        })
      );

      const response = await axios.get(`${ngrokUrl.url}/api/readClient`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      });
      setnavigation(response.data);
      setSuccess("slider deleted successfully.");
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete selected items.", error);
      setError("Failed to delete selected items.");
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">All menu links</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              All menu links
            </h6>
          </div>
          <div className="card-body mt-3">
            <div className="table-responsive">
              <div className="row align-items-center mb-4">
                <div className="col-lg-6">
                  <Link to="/dashboard/createmenu" className="dashboardBtn">
                    Create menu link
                  </Link>
                </div>
              </div>

              <form>
                <input
                  type="hidden"
                  name="_token"
                  value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                />{" "}
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th className="header_heady">Name</th>
                      <th className="header_heady">Link</th>
                      <th className="header_heady">Order</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th className="header_heady">Name</th>
                      <th className="header_heady">Link</th>
                      <th className="header_heady">Order</th>
                    </tr>
                  </tfoot>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="3">Loading...</td>
                      </tr>
                    ) : error ? (
                      <tr>
                        <td colSpan="3">Error: {error}</td>
                      </tr>
                    ) : navigation.length === 0 ? (
                      <tr>
                        <td colSpan="3">No data found.</td>
                      </tr>
                    ) : (
                      Array.isArray(navigation) &&
                      navigation.map((item) => (
                        <tr key={item.id}>
                          <td data-label="name" className="label_dimension">
                            {item.name}
                            <p className="mb-0 mt-2 anchor_font">
                              <a
                                href={`/dashboard/editNavigation/${item.id}/edit?language=en`}
                              >
                                Edit
                              </a>
                            </p>
                          </td>
                          <td data-label="link" className="label_dimension">
                            {item.link}
                          </td>
                          <td data-label="order" className="label_dimension">
                            {item.order}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                {error && (
                  <div style={{ color: "red", fontSize: "14px" }}>{error}</div>
                )}
                {success && (
                  <div style={{ color: "green", fontSize: "14px" }}>
                    {success}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebsiteNavigation;
