import React, { useEffect, useState } from "react";
import Web from "../Components/Home/Web/Web";
import FunFacts from "../Components/Home/FunFacts/FunFacts";
import About from "../Components/Home/About/About";
import Portfolio from "../Components/Home/PortfolioSection/Portfolio";

import Blog from "../Components/Home/Blog/Blog";
import Footer from "../Components/Home/Footer/Footer";
import Testimonial from "../Components/Home/Testimonial/Testimonial";
import NavBar from "../Utility/NavBar";
import Topbar from "../Topbar/Topbar";
import Whatsapp from "../Components/TopupButtons/Whatsapp";
// import OurProjects from "../Components/Home/PortfolioSection/OurProjects";
import Loader from "../Components/Loader/Loader";
import Worksection from "../Components/Home/WorkSuccessful/Worksection.jsx";
// import CookiesPage from "../Components/CookiesAndPrivacy/CookiesPage.jsx";
// import { Helmet } from "react-helmet-async";

function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    });
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (loading) {
    return <Loader />;
  }

  const pageTitle = "Dynamic Page Title";
  return (
    <div>
      {/* <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content="This is a dynamic description" />
      </Helmet> */}
      <Topbar />
      <NavBar />
      <Web />
      <FunFacts />
      <About />
      <Worksection />
      <Portfolio />
      {/* <CookiesPage /> */}
      {/* <OurProjects /> */}

      <Testimonial />
      <Blog />
      <Footer />
      <Whatsapp />
    </div>
  );
}
export default Home;
