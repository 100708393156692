import React from "react";

function AboutSettings() {
  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">About settings</h1>

        <div className="pb-2 text-right">
          <select
            name="language"
            className="form-control label_dimension language-control"
            onchange="window.location='https://niva4.lucian.host/admin/about-settings?language='+this.value"
          >
            <option value="" selected disabled>
              Select a language
            </option>
            <option value="en">English</option>
            <option value="pt">Portuguese</option>
            <option value="عربى">عربى</option>
          </select>
        </div>

        <div className="row">
          <div className="col-md-12">
            {/* <!-- about --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  About settings
                </h6>
              </div>
              <div className="card-body">
                <form
                  action="https://niva4.lucian.host/admin/about-settings/1/update"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="header_heady">Subtitle</strong>
                            <input
                              type="text"
                              name="about_subtitle"
                              className="form-control label_dimension"
                              value="ABOUT AGENCY"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="header_heady">Title</strong>
                            <input
                              type="text"
                              name="about_title"
                              className="form-control label_dimension"
                              value="Welcome to Niva Agency."
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <strong className="header_heady">Description</strong>
                        <textarea
                          name="about_description"
                          className="form-control label_dimension"
                          rows="6"
                        >
                          {/* &lt;p&gt;Whether you need a new logo, website, video,
                          marketing campaign, or ebook created for your
                          business, the key to making the project a success
                          starts with having a well-thought-out creative brief.
                          No coding skills required to create unique sites.
                          Customize your site in real-time and see the results
                          instantly.&lt;/p&gt; &lt;p&gt;Whether you need a new
                          logo, website, video, marketing campaign, or ebook
                          created for your business, the key to making the
                          project a success starts with having a
                          well-thought-out creative brief. No coding skills
                          required to create unique sites. Customize your site
                          in real-time and see the results instantly.&lt;/p&gt; */}
                        </textarea>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="header_heady">
                              Button text
                            </strong>
                            <input
                              type="text"
                              name="about_buttontext"
                              className="form-control label_dimension"
                              value="Contact us"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="header_heady">
                              Button link
                            </strong>
                            <input
                              type="text"
                              name="about_buttonlink"
                              className="form-control label_dimension"
                              value="https://niva4.lucian.host/contact"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="header_heady">Photo</strong>
                            <input
                              type="text"
                              name="about_image"
                              className="form-control label_dimension"
                              value="https://niva4.lucian.host/public/images/media/1615649260about-3-page.jpg"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <strong className="header_heady">
                              Button youtube link
                            </strong>
                            <input
                              type="text"
                              name="about_ytlink"
                              className="form-control label_dimension"
                              value="https://www.youtube.com/watch?v=mJT899QyRvI"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary anchor_font"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- about --> */}

            {/* <!-- member --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  Section Members
                </h6>
              </div>
              <div className="card-body">
                <form
                  action="https://niva4.lucian.host/admin/about-settings/1/update"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="form-group">
                    <strong className="header_heady">Title</strong>
                    <input
                      type="text"
                      name="member_title_section"
                      className="form-control label_dimension"
                      value="Professional team."
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    <button type="submit" className="btn btn-primary anchor_font">
                      Update
                    </button>
                  </div>
                </form>
                <div className="mb-3">
                  <a
                    className="btn btn-primary anchor_font mr-3"
                    href="https://niva4.lucian.host/admin/member?language="
                  >
                    View All
                  </a>
                  <a
                    className="btn btn-primary anchor_font"
                    href="https://niva4.lucian.host/admin/member/create?language="
                  >
                    Create
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- member --> */}

            {/* <!-- testimonial --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  Section - Testimonials
                </h6>
              </div>
              <div className="card-body">
                <div className="mb-3">
                  <a
                    className="btn btn-primary anchor_font mr-3"
                    href="https://niva4.lucian.host/admin/testimonial?language="
                  >
                    View All
                  </a>
                  <a
                    className="btn btn-primary anchor_font"
                    href="https://niva4.lucian.host/admin/testimonial/create?language="
                  >
                    Create
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- testimonial --> */}

            {/* <!-- clients --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  Section Clients
                </h6>
              </div>
              <div className="card-body">
                <div className="mb-3">
                  <a
                    className="btn btn-primary anchor_font mr-3"
                    href="https://niva4.lucian.host/admin/client?language="
                  >
                    View All
                  </a>
                  <a
                    className="btn btn-primary anchor_font"
                    href="https://niva4.lucian.host/admin/client/create?language="
                  >
                    Create
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- clients --> */}

            {/* <!-- SEO --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark header_heady">
                  SEO
                </h6>
              </div>
              <div className="card-body">
                <form
                  action="https://niva4.lucian.host/admin/about-settings/1/update"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">meta title</strong>
                        <input
                          type="text"
                          name="meta_title"
                          className="form-control label_dimension"
                          value="About Niva"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">
                          meta description
                        </strong>
                        <input
                          type="text"
                          name="meta_description"
                          className="form-control label_dimension"
                          value="Web design encompasses many different skills and disciplines in the production and maintenance of websites."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Slug</strong>
                        <input
                          type="text"
                          name="slug"
                          className="form-control label_dimension"
                          value="about-us"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Anchor text</strong>
                        <input
                          type="text"
                          name="breadcrumbs_anchor"
                          className="form-control label_dimension"
                          value="Home"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary anchor_font"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- SEO --> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSettings;
