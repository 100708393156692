import React from "react";
import "./worksection.css";
import Infographic_dekstop from "../../../Images/Infographic_dekstop.png";
import Infographic_mobile from "../../../Images/Infographic_mobile.png";
const Worksection = () => {
  return (
    <div className="worksection-container  ">
      <div className="container">
        <div className="justify-content-center">
          <div
            xs={12}
            className="worksection-title"
            style={{ background: "#f4decb" }}
          >
            <div className="services-section" style={{ paddingTop: "0px" }}>
              <h3 style={{ marginBottom: "0px" }}>
                How we make work successful
              </h3>
            </div>
          </div>
        </div>
        <div className="worksection-images">
          <img
            src={Infographic_dekstop}
            alt="Desktop View"
            className="desktop-image"
          />
          <img
            src={Infographic_mobile}
            alt="Mobile View"
            className="mobile-image"
          />
        </div>
      </div>
    </div>
  );
};

export default Worksection;
