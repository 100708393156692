import React from "react";
import arabic from "../../Images/1618065739arabic.svg";
import english from "../../Images/1618066305united-kingdom.svg";
import portugese from "../../Images/1618066273portugal.svg";
import { Link } from "react-router-dom";

function WebsiteLanguage() {
  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">All languages</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              All languages
            </h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="row">
                <div className="col-lg-6">
                  <Link
                    to="/dashboard/createlanguage"
                    className="btn btn-primary btn-back anchor_font"
                  >
                    Create language
                  </Link>
                </div>

                <div className="col-lg-6 text-right"></div>
              </div>

              <form
                action="https://niva4.lucian.host/delete/language"
                method="POST"
                className="form-inline mt-4"
              >
                <input
                  type="hidden"
                  name="_token"
                  value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                />{" "}
                <input type="hidden" name="_method" value="DELETE" />{" "}
                <div className="form-group">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary "
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        backgroundColor: "white",
                        color: "grey",
                        fontSize: "medium",
                      }}
                    >
                      Delete
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="submit"
                    name="delete_all"
                    className="btn btn-primary anchor_font"
                  />
                </div>
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" id="options" />
                      </th>
                      <th className="header_heady">Photo</th>
                      <th className="header_heady">Name</th>
                      <th className="header_heady">Code language</th>
                      <th className="header_heady">Is default?</th>
                      <th className="header_heady">LTR or RTL</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>
                        <input type="checkbox" id="options1" />
                      </th>
                      <th className="header_heady">Photo</th>
                      <th className="header_heady">Name</th>
                      <th className="header_heady">Code language</th>
                      <th className="header_heady">Is default?</th>
                      <th className="header_heady">LTR or RTL</th>
                    </tr>
                  </tfoot>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          className="checkboxes"
                          type="checkbox"
                          name="checkbox_array[]"
                          value="1"
                        />
                      </td>
                      <td data-label="Photo">
                        <img height="50" src={english} alt="" />
                      </td>
                      <td className="language-name" data-label="Name">
                        <div className="float-left-language-name label_dimension">
                          <p>English</p>
                          <a href="https://niva4.lucian.host/admin/language/1/edit">
                            Edit
                          </a>
                        </div>
                      </td>
                      <td
                        className="language-link label_dimension"
                        data-label="link"
                      >
                        en
                      </td>
                      <td data-label="link" className="label_dimension">
                        Yes{" "}
                      </td>

                      <td data-label="link" className="label_dimension">
                        LTR{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          className="checkboxes"
                          type="checkbox"
                          name="checkbox_array[]"
                          value="2"
                        />
                      </td>
                      <td data-label="Photo">
                        <img height="50" src={portugese} alt="" />
                      </td>
                      <td className="language-name" data-label="Name">
                        <div className="float-left-language-name label_dimension">
                          <p>Portuguese</p>
                          <a href="https://niva4.lucian.host/admin/language/2/edit">
                            Edit
                          </a>
                        </div>
                      </td>
                      <td
                        className="language-link label_dimension"
                        data-label="link"
                      >
                        pt
                      </td>
                      <td data-label="link" className="label_dimension">
                        No{" "}
                      </td>

                      <td data-label="link" className="label_dimension">
                        LTR{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          className="checkboxes"
                          type="checkbox"
                          name="checkbox_array[]"
                          value="3"
                        />
                      </td>
                      <td data-label="Photo">
                        <img height="50" src={arabic} alt="" />
                      </td>
                      <td className="language-name" data-label="Name">
                        <div className="float-left-language-name label_dimension">
                          <p>عربى</p>
                          <a href="https://niva4.lucian.host/admin/language/3/edit">
                            Edit
                          </a>
                        </div>
                      </td>
                      <td
                        className="language-link label_dimension"
                        data-label="link"
                      >
                        عربى
                      </td>
                      <td data-label="link" className="label_dimension">
                        No{" "}
                      </td>

                      <td data-label="link" className="label_dimension">
                        RTL{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebsiteLanguage;
