import React, { useState, useEffect } from "react";
import "./GDPR.css";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";

const GDPRSettings = () => {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    fetchDefaultValues();
  }, []);

  const fetchDefaultValues = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${ngrokUrl.url}/api/readGdpr/9`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      });

      setFormData(response.data);
    } catch (error) {
      console.error("Failed to fetch default values.", error);
      setError("Failed to fetch default values.");
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateGdpr/9`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status < 200 || response.status >= 300) {
        throw new Error("Network response was not ok");
      }

      await fetchDefaultValues();

      const result = response.data;
      setFormData(result);
      setSuccess("Form data updated successfully!");
      window.location.reload();
    } catch (error) {
      console.error("Failed to update form data.", error);
      setError("Failed to update form data.");
    } finally {
      setLoading(false);
    }
  };

  const handleToggle = async (e) => {
    const { name, checked } = e.target;

    setFormData({
      ...formData,
      [name]: checked,
    });

    await handleSubmit(e);
  };

  return (
    <div>
      <div className="container-fluid">
        <h1>GDPR Compliant Cookies Settings</h1>
        <div className="cards shadow  mb-4 main_section rounded-1">
          <div className="cards-header py-2 mt-5 pl-3">
            <h6 className="mt-2 font-weight-bold text-primary anchor_font cardTitle pl-4">
              GDPR Compliant Cookies Settings
            </h6>
            <div className="header_sections col-12 mt-5">
              <form className="form-section" onSubmit={handleSubmit}>
                <div>
                  <a href="" style={{ fontSize: "20px" }}>
                    English (UK)
                  </a>
                  {/* <a className="hometab" href="" style={{ fontSize: "20px" }}>
                    Arabic
                  </a> */}
                </div>
                <div className="inputsection py-2 pr-3">
                  <div>
                    <label htmlFor="">GDPR title</label>
                    <input
                      type="text"
                      name="gdpr_title"
                      onChange={handleChange}
                      value={formData.gdpr_title}
                    />
                  </div>
                  <div>
                    <label htmlFor="">GDPR Message</label>
                    <textarea
                      rows={"5"}
                      name="gdpr_message"
                      onChange={handleChange}
                      value={formData.gdpr_message}
                    />
                  </div>
                  <div>
                    <label htmlFor="">GDPR More Info Link Label</label>
                    <input
                      type="text"
                      name="gdpr_more_info_link_label"
                      onChange={handleChange}
                      value={formData.gdpr_more_info_link_label}
                    />
                  </div>
                  <div>
                    <label htmlFor="">GDPR More Info Link </label>
                    <input
                      type="text"
                      name="gdpr_more_info_link"
                      onChange={handleChange}
                      value={formData.gdpr_more_info_link}
                    />
                  </div>
                  <div className="smalltext">
                    <h5>{`enter more info link user {url} to point the site address, example: {url}/about , it will be converted to www.yoursite.com/about`}</h5>
                  </div>
                  <div>
                    <label htmlFor="">GDPR Cookie Accept Button Label</label>
                    <input
                      type="text"
                      name="gdpr_cookie_accept_button_label"
                      onChange={handleChange}
                      value={formData.gdpr_cookie_accept_button_label}
                    />
                  </div>
                  <div>
                    <label htmlFor="">GDPR Cookie Decline Button Label</label>
                    <input
                      type="text"
                      name="gdpr_cookie_decline_button_label"
                      onChange={handleChange}
                      value={formData.gdpr_cookie_decline_button_label}
                    />
                  </div>
                  <div className="py-3">
                    <label htmlFor="">GDPR Cookie Enable/Disable</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="cookie_enable_disable"
                        id="site_gdpr_cookie_enabled"
                        onChange={handleToggle}
                        // checked={formData.cookie_enable_disable}
                        value={formData.cookie_enable_disable}
                      />
                      <span class="slider"></span>
                    </label>
                  </div>
                  <div>
                    <label htmlFor="">Cookie Expire</label>
                    <input
                      type="text"
                      name="cookie_expire"
                      onChange={handleChange}
                      value={formData.cookie_expire}
                    />
                  </div>
                  <div className="smalltext">
                    <h5>set cookie expire time, eg: 30, means 30days</h5>
                  </div>
                  <div>
                    <label htmlFor="">Show Delay</label>
                    <input
                      type="text"
                      name="show_delay"
                      onChange={handleChange}
                      value={formData.show_delay}
                    />
                  </div>
                  <div className="smalltext">
                    <h5>
                      set GDPR cookie delay time, it mean the notification will
                      show after this time. number count as mili seconds. eg:
                      5000, means 5seconds
                    </h5>
                  </div>

                  <button className="  mt-4 pr-4 pl-4 subBtn" type="submit">
                    {loading ? "Updating..." : "Update Changes"}
                  </button>
                </div>
              </form>
              {error && <div className="alert alert-danger mt-3">{error}</div>}
              {success && (
                <div className="alert alert-success mt-3">{success}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GDPRSettings;
