import React, { useState, useEffect } from "react";
import "../../App.css";
import Footer from "../Home/Footer/Footer";
import NavBar from "../../Utility/NavBar";
import Whatsapp from "../TopupButtons/Whatsapp";
import Topbar from "../../Topbar/Topbar";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import Loader from "../Loader/Loader";
import "./portfolio.css";

function ProjectDigma() {
  const [project, setProject] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const projectValues = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readSectionProjectById/${25}`
        );
        setProject(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch members Details.", error);
        setError("Failed to fetch members Details");
        setLoading(false);
      }
    };

    projectValues();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Topbar />
          <Whatsapp />
          <NavBar />
          <div class="breadcrumb-area">
            <h1 class="breadcrumb-title">{project.title}</h1>
          </div>

          <div class="project-content">
            <div class="container">
              <div class="row">
                <div class="col-md-8">
                  <h2 class="post-name">{project.title}</h2>
                  <span class="niva-animate-border"></span>
                  <p>{project.body}</p>
                </div>
                <div class="col-md-4 info">
                  <h4 class="post-name" style={{ textAlign: "left" }}>
                    Info{" "}
                  </h4>
                  <span class="niva-animate-border"></span>

                  <p>
                    <strong>{project.duration_project}</strong>
                  </p>
                  <p>
                    <strong>{project.client}</strong>
                  </p>
                  <p>
                    <strong>{project.categories}</strong>
                  </p>

                  <a href={project.button_link} class="btn btn-style1">
                    <span style={{ color: "white" }}>
                      {project.button_text}
                    </span>
                  </a>
                </div>
              </div>

              <div class="gallery">
                <div class="row">
                  <div class="col-md-6">
                    <div class="featured-image">
                      <a>
                        <img class="img-fluid lazy" src={project.photo_url} />
                      </a>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="featured-image">
                      <a>
                        <img class="img-fluid lazy" src={project.photo_one} />
                      </a>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="featured-image">
                      <a>
                        <img class="img-fluid lazy" src={project.photo_two} />
                      </a>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="featured-image">
                      <a>
                        <img class="img-fluid lazy" src={project.photo_three} />
                      </a>
                    </div>
                  </div>

                  <div class="col-md-6">
                    s
                    <div class="featured-image">
                      <a>
                        <img class="img-fluid lazy" src={project.photo_four} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </>
  );
}
export default ProjectDigma;
