import React from "react";
import RouterComponent from "./Utility/RouterComponent";
import Topbar from "./Topbar/Topbar";
import "./App.css";
import "./rtl.css";
// import { useLocation } from "react-router-dom";
import Topup from "./Components/TopupButtons/Topup";
// import Whatsapp from "./Components/TopupButtons/Whatsapp";

function App() {
  // const location = useLocation();
  return (
    <>
      {/* {location.pathname !== "/login" && <Topbar />} */}

      <RouterComponent />
      {/* <SweetyBuy/> */}
      {/* {location.pathname !== "/login" && <Whatsapp />} */}

      <Topup />
    </>
  );
}

export default App;
