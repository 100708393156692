import React from "react";
import { Country } from "../../Utility/data";
import Graph from "../Chart/Graph";
import Worldmap from "../Worldmap/Worldmap";

function DashboardStats() {
  return (
    <div>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Dashboard</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4 dashboard-page">
          <div className="card-header py-3">
            <h6 className="h6 m-0 font-weight-bold text-primary">Dashboard</h6>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-xl-4 col-md-6 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Blog
                        </div>
                        <div
                          className="h5 mb-0 font-weight-bold text-gray-800"
                          style={{ fontSize: "1.9rem", color: "#858796" }}
                        >
                          9 Posts
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Projects
                        </div>
                        <div
                          className="h5 mb-0 font-weight-bold text-gray-800"
                          style={{ fontSize: "1.9rem", color: "#858796" }}
                        >
                          18 Projects
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          What we do
                        </div>
                        <div
                          className="h5 mb-0 font-weight-bold text-gray-800"
                          style={{ fontSize: "1.9rem", color: "#858796" }}
                        >
                          12 Services
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Feedback
                        </div>
                        <div
                          className="h5 mb-0 font-weight-bold text-gray-800"
                          style={{ fontSize: "1.9rem", color: "#858796" }}
                        >
                          12 Testimonials
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          {" "}
                          Our team
                        </div>
                        <div
                          className="h5 mb-0 font-weight-bold text-gray-800"
                          style={{ fontSize: "1.9rem", color: "#858796" }}
                        >
                          6 Members
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          {" "}
                          Our clients
                        </div>
                        <div
                          className="h5 mb-0 font-weight-bold text-gray-800"
                          style={{ fontSize: "1.9rem", color: "#858796" }}
                        >
                          6 Clients
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow mb-4 dashboard-page ">
          <div
            className="card-header py-3 maintext m-4"
            style={{ borderRadius: "12px" }}
          >
            <h6 className="h6 m-0 font-weight-bold pl-3 ">Clicks over Time</h6>
          </div>
          <div className="card_body">
            <div className="row pl-5 pb-5">
              <Graph />
            </div>
            <div className="row">
              <div className="col-md-5">
                <div className="card card-primary pl-3 m-0">
                  <div className="card_header">
                    <h3 className="pl-4 pt-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="white"
                        class="bi bi-flag"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                      </svg>
                      Top Countries
                    </h3>
                  </div>
                  <div className="card-body">
                    <table>
                      <thead>
                        <tr>
                          <th>FLAG</th>
                          <th>COUNTRY</th>
                          <th>CLICKS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Country.map((country, index) => (
                          <tr key={index}>
                            <td>{country.flag}</td>

                            <td>{country.name}</td>
                            <td>{country.percentage}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="card card-primary ">
                  <div className="card_header">
                    <h3 className="pl-4 pt-4">Top Countries</h3>
                  </div>

                  <div className="map_section">
                    <Worldmap />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardStats;
