import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Sidebar({ sidebar }) {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };
  return (
    <>
      <ul
        className={`navbar-nav bg-gradient-primary ${
          sidebar ? "sidebar_hidden" : "sidebar"
        } sidebar-dark accordion ${toggle ? "toggled" : null} `}
        id="accordionSidebar"
      >
        {/* <!-- Sidebar - Brand --> */}
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href="#0"
        >
          <div className="sidebar-brand-icon rotate-n-15">
            <i className="fab fa-github"></i>
          </div>
          {!toggle && (
            <div className="d-none d-md-block sidebar-brand-text mx-3">
              Niva<sup>v2.0</sup>
            </div>
          )}
        </a>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider my-0" />

        {/* <!-- Nav Item - Dashboard --> */}
        <li className="nav-item mobile_sidebar">
          <Link
            className={`nav-link  ${toggle ? "toggled" : null}`}
            to="/dashboard"
          >
            <i className="fas fa-fw fa-tachometer-alt "></i>
            <span>Dashboard</span>
          </Link>
        </li>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider" />

        {/* <!-- Nav Item - Pages Collapse Menu --> */}
        <li className="nav-item">
          <a
            className={`nav-link collapsed ${toggle ? "toggled" : null}`}
            data-bs-toggle="collapse"
            data-bs-target="#collapsePages"
            aria-expanded="true"
            aria-controls="collapsePages"
          >
            <i className="far fa-fw fa-file"></i>
            <span>Pages</span>
            {/* <div className="sidebar-dropdown">
            <i class="fa-solid fa-angle-down"></i>
            </div> */}
          </a>
          <div
            id="collapsePages"
            className={`collapse ${toggle ? "toggled" : ""} `}
            data-bs-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <Link to="/dashboard/all-pages" className="collapse-item">
                All pages
              </Link>
              <Link to="/dashboard/createPage" className="collapse-item">
                Create page
              </Link>

              <h6 className="collapse-header">Custom pages</h6>

              <Link to="/dashboard/customTemplate" className="collapse-item">
                Custom templates
              </Link>
            </div>
          </div>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${toggle ? "toggled" : null}`}
            data-bs-toggle="collapse"
            data-bs-target="#collapseProjects"
            aria-expanded="true"
            aria-controls="collapseProjects"
          >
            <i className="fas fa-fw fa-pencil-ruler"></i>
            <span>Projects</span>
          </a>
          <div
            id="collapseProjects"
            className={`collapse ${toggle ? "toggled" : ""} `}
            data-bs-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <Link to="/dashboard/all-project" className="collapse-item">
                All projects
              </Link>
              <Link to="/dashboard/createProject" className="collapse-item">
                Create project
              </Link>
              <h6 className="collapse-header">Categories</h6>
              <Link to="/dashboard/allCategories" className="collapse-item">
                All Categories
              </Link>
            </div>
          </div>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${toggle ? "toggled" : null}`}
            data-bs-toggle="collapse"
            data-bs-target="#collapsePosts"
            aria-expanded="true"
            aria-controls="collapsePosts"
          >
            <i className="fas fa-fw fa-file-signature"></i>
            <span>Posts</span>
          </a>
          <div
            id="collapsePosts"
            className={`collapse ${toggle ? "toggled" : ""} `}
            data-bs-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <Link to="/dashboard/allPosts" className="collapse-item">
                All posts
              </Link>
              <Link to="/dashboard/createPost" className="collapse-item">
                Create post
              </Link>
              <h6 className="collapse-header">Categories</h6>
              <Link to="/dashboard/postCategory" className="collapse-item">
                All Categories
              </Link>
            </div>
          </div>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${toggle ? "toggled" : null}`}
            data-bs-toggle="collapse"
            data-bs-target="#collapseMedia"
            aria-expanded="true"
            aria-controls="collapseMedia"
          >
            <i className="fas fa-fw fa-images"></i>
            <span>Media</span>
          </a>
          <div
            id="collapseMedia"
            className={`collapse ${toggle ? "toggled" : ""} `}
            data-bs-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <Link to="/dashboard/allMedia" className="collapse-item">
                All media
              </Link>
              <Link to="/dashboard/upload" className="collapse-item">
                Upload image
              </Link>
            </div>
          </div>
        </li>

        {/* all users pages Menu */}
        <li className="nav-item">
          <a
            className={`nav-link collapsed ${toggle ? "toggled" : null}`}
            data-bs-toggle="collapse"
            data-bs-target="#collapseUsers"
            aria-expanded="true"
            aria-controls="collapseUsers"
          >
            <i
              class="fa fa-user"
              aria-hidden="true"
              style={{ fontSize: "1.5rem", marginLeft: "5px" }}
            ></i>
            <span>Users</span>
          </a>
          <div
            id="collapseUsers"
            className={`collapse ${toggle ? "toggled" : ""} `}
            data-bs-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <Link to="/dashboard/allUsers" className="collapse-item">
                All Users
              </Link>
              <Link to="/dashboard/createUsers" className="collapse-item">
                Create Users
              </Link>
            </div>
          </div>
        </li>

        {/* <!-- Nav Item - Pages Collapse Menu --> */}
        <li className="nav-item">
          <a
            className={`nav-link collapsed ${toggle ? "toggled" : null}`}
            data-bs-toggle="collapse"
            data-bs-target="#collapseElements"
            aria-expanded="true"
            aria-controls="collapseElements"
          >
            <i className="fas fa-fw fa-layer-group"></i>
            <span>Elements</span>
          </a>
          <div
            id="collapseElements"
            className={`collapse ${toggle ? "toggled" : ""} `}
            data-bs-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <Link to="/dashboard/manageslider" className="collapse-item">
                Manage slider{" "}
              </Link>
              <Link to="/dashboard/manageservices" className="collapse-item">
                Manage services
              </Link>
              <Link
                to="/dashboard/managetestimonials"
                className="collapse-item"
              >
                Manage testimonials
              </Link>

              <Link to="/dashboard/manageclients" className="collapse-item">
                Manage clients
              </Link>
              <Link to="/dashboard/managepricing" className="collapse-item">
                Pricing tables
              </Link>
            </div>
          </div>
        </li>

        {/* <!-- Nav Item - Pages Collapse Menu --> */}
        <li className="nav-item">
          <a
            className={`nav-link collapsed ${toggle ? "toggled" : null}`}
            data-bs-toggle="collapse"
            data-bs-target="#collapseSEO"
            aria-expanded="true"
            aria-controls="collapseSEO"
          >
            <i className="fas fa-fw fa-cogs"></i>
            <span>Settings</span>
          </a>
          <div
            id="collapseSEO"
            className={`collapse ${toggle ? "toggled" : ""} `}
            data-bs-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <Link to="/dashboard/websiteSetting" className="collapse-item">
                Website Settings
              </Link>
              <Link
                to="/dashboard/websitenavigation"
                className="collapse-item"
                href="https://niva4.lucian.host/admin/menu?language=en"
              >
                Main menu - Navigation
              </Link>
              <Link to="/dashboard/websitefooter" className="collapse-item">
                Header and footer
              </Link>
              <Link
                to="/dashboard/websitelanguages"
                className="collapse-item"
                style={{ display: "none" }}
              >
                All languages
              </Link>
              <Link to="/dashboard/googleAnalytics" className="collapse-item">
                Google Analytics
              </Link>
              <Link to="/dashboard/websiteTracking" className="collapse-item">
                Website Tracking
              </Link>
              <Link to="/dashboard/logoSeo" className="collapse-item">
                Logo & SEO
              </Link>
              <Link to="/dashboard/captcha" className="collapse-item">
                Captcha
              </Link>
              <Link to="/dashboard/gdprSettings" className="collapse-item">
                GDPR Compliant Cookies Settings
              </Link>
            </div>
          </div>
        </li>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider d-none d-md-block" />

        {/* <!-- Sidebar Toggler (Sidebar) --> */}
        <div className="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id={`${!toggle ? "sidebarToggle" : "sidebarToggled"}`}
            onClick={handleToggle}
          ></button>
        </div>
      </ul>
    </>
  );
}
