import React, { useState, useEffect } from "react";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import "../Pages/EditPage/editpage.css";

function WebsiteSettting() {
  const [websitesettings, setWebsiteSettings] = useState({
    website_title: "",
    author: "",
    contact_address: "",
    phone_whatsapp: "",

    address: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const websitesettingsData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${ngrokUrl.url}/api/readSettings/3`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        });

        const data = await response.data;

        setWebsiteSettings(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    websitesettingsData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWebsiteSettings((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${ngrokUrl.url}/api/updateSettings/3`,
        websitesettings,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setWebsiteSettings(response.data);

      setSuccess("updated successfully.");
      window.location.reload();
    } catch (error) {
      console.error("Failed to update website settings.", error);
      setError("Failed to update website settings.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Website Settings</h1>
        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              Website Settings
            </h6>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <form onSubmit={handlesubmit}>
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group">
                        <strong className="label_dimension">
                          Website title
                        </strong>
                        <input
                          type="text"
                          name="website_title"
                          value={websitesettings.website_title}
                          className="form-control anchor_font"
                          placeholder="Name"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div
                      className="col-xs-12 col-sm-12 col-md-12"
                      style={{ display: "none" }}
                    >
                      <div className="form-group">
                        <strong className="label_dimension">
                          Enable or disable OGgraph
                        </strong>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="OGgraph_switch"
                            id="OGgraph_switch1"
                            value="1"
                            checked
                          />
                          <label
                            className="form-check-label"
                            for="OGgraph_switch1"
                          >
                            {" "}
                            On{" "}
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="OGgraph_switch"
                            id="OGgraph_switch0"
                            value="0"
                          />
                          <label
                            className="form-check-label"
                            for="OGgraph_switch0"
                          >
                            {" "}
                            Off{" "}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-xs-12 col-sm-12 col-md-12"
                      style={{ display: "none" }}
                    >
                      <div className="form-group">
                        <strong className="label_dimension">
                          Enable or disable analytics
                        </strong>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="analytics_switch"
                            id="analytics_switch1"
                            value="1"
                          />
                          <label
                            className="form-check-label"
                            for="analytics_switch1"
                          >
                            {" "}
                            On{" "}
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="analytics_switch"
                            id="analytics_switch0"
                            value="0"
                            checked
                          />
                          <label
                            className="form-check-label"
                            for="analytics_switch0"
                          >
                            {" "}
                            Off{" "}
                          </label>
                        </div>

                        <br />

                        <strong className="label_dimension">
                          Analytics Tracking Code
                        </strong>
                        <input
                          type="text"
                          name="analytics"
                          value="UA-CODE-12"
                          className="form-control label_dimension"
                        />
                      </div>
                    </div>

                    <div
                      className="col-xs-12 col-sm-12 col-md-12"
                      style={{ display: "none" }}
                    >
                      <div className="form-group">
                        <strong className="label_dimension">
                          Enable or disable Facebook Pixel
                        </strong>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="facebook_pixel_switch"
                            id="pixel_switch1"
                            value="1"
                          />
                          <label
                            className="form-check-label"
                            for="pixel_switch1"
                          >
                            {" "}
                            On{" "}
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="facebook_pixel_switch"
                            id="pixel_switch0"
                            value="0"
                            checked
                          />
                          <label
                            className="form-check-label"
                            for="pixel_switch0"
                          >
                            {" "}
                            Off{" "}
                          </label>
                        </div>

                        <br />

                        <strong className="label_dimension">Pixel code</strong>
                        <input
                          type="text"
                          name="facebook_pixel"
                          value="CODE-FACEBOOK"
                          className="form-control label_dimension"
                        />
                      </div>
                    </div>

                    <div
                      className="col-xs-12 col-sm-12 col-md-12"
                      style={{ display: "none" }}
                    >
                      <div className="form-group">
                        <strong className="label_dimension">
                          Enable or disable SchemaORG
                        </strong>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="SchmeaORG_switch"
                            id="schema_switch1"
                            value="1"
                            checked
                          />
                          <label
                            className="form-check-label"
                            for="schema_switch1"
                          >
                            {" "}
                            On{" "}
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="SchmeaORG_switch"
                            id="schema_switch0"
                            value="0"
                          />
                          <label
                            className="form-check-label"
                            for="schema_switch0"
                          >
                            {" "}
                            Off{" "}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <strong className="label_dimension">Author</strong>
                            <input
                              type="text"
                              name="author"
                              value={websitesettings.author}
                              className="form-control label_dimension"
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-md-6">
                            <strong className="label_dimension">
                              Email address
                            </strong>
                            <input
                              type="text"
                              name="contact_address"
                              value={websitesettings.contact_address}
                              className="form-control label_dimension"
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <br />

                        <div className="row">
                          <div className="col-md-6">
                            <strong className="label_dimension">
                              Phone / Whatsapp
                            </strong>
                            <input
                              type="text"
                              name="phone_whatsapp"
                              value={websitesettings.phone_whatsapp}
                              className="form-control label_dimension"
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-md-6">
                            <strong className="label_dimension">Address</strong>
                            <input
                              type="text"
                              name="address"
                              value={websitesettings.address}
                              className="form-control label_dimension"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button type="submit" className="cardBtn">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
                {error && <p style={{ color: "red" }}>Error: {error}</p>}
                {success && (
                  <p style={{ color: "green" }}>Success: {success}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebsiteSettting;
