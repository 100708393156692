import React, { useState, useEffect } from "react";
import "../Pages/EditPage/editpage.css";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import { useNavigate } from "react-router-dom";

const CreateMembers = () => {
  const [members, setMembers] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 3000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 3000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setMembers({
      ...members,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${ngrokUrl.url}/api/saveMember`,

        members,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setMembers(response.data);
      setSuccess("Member Create Successfully!");
      setLoading(false);
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to create page");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        navigate("/dashboard/members");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [success]);

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Create members</h1>
      <div className="card shadow mb-4">
        <div className="card-header templete py-3">
          <h6 className="m-0 font-weight-bold text-primary">Create members</h6>
        </div>
        <a href="/dashboard/members" className="btn-back cardBtn m-4 mb-2">
          Back to Members
        </a>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit}>
                <input
                  type="hidden"
                  name="_token"
                  value="mbJ1Ajj1Qp4fude5SJ4QADQlgn8AnE0HRL9aJzVf"
                />
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Name</h5>
                          <input
                            type="text"
                            name="name"
                            className="formControl"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formgroup">
                          <h5>Position</h5>
                          <input
                            type="text"
                            name="position"
                            className="formControl"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="formgroup">
                          <h5>facebook</h5>
                          <input
                            type="text"
                            name="facebook"
                            className="formControl"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="formgroup">
                          <h5>twitter</h5>
                          <input
                            type="text"
                            name="twitter"
                            className="formControl"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="formgroup">
                          <h5>linkedin</h5>
                          <input
                            type="text"
                            name="linkedin"
                            className="formControl"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="formgroup">
                      <p>
                        <h5>Photo</h5>
                      </p>
                      <input
                        type="file"
                        name="photo"
                        className="formControl-file"
                        id="photo_id"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary anchor_font"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </div>
              </form>
              {error && (
                <div style={{ color: "red", fontSize: "14px" }}>{error}</div>
              )}
              {success && (
                <div style={{ color: "green", fontSize: "14px" }}>
                  {success}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMembers;
