import React, { useState, useEffect } from "react";
import axios from "axios";
import { ngrokUrl } from "../Utility/data";

import "./Topbar.css";

function Topbar() {
  const [websitesettings, setWebsiteSettings] = useState({
    website_title: "",
    author: "",
    contact_address: "",
    phone_whatsapp: "",
    address: "",
  });
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);
  // const [success, setSuccess] = useState(null);

  useEffect(() => {
    const websitesettingsData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${ngrokUrl.url}/api/readSettings/3`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        });

        const data = await response.data;

        setWebsiteSettings(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    websitesettingsData();
  }, []);

  return (
    <header className="header-niva">
      <div
        id="ct-topbar"
        className="ct-topbar-layout1"
        style={{ padding: "10px 0px" }}
      >
        <div className="container">
          <div className="row">
            <div className="ct-topbar-meta justify-content-between">
              <div className="ct-topbar-item">
                {" "}
                <i className="fas fa-phone-square-alt"></i>
                <label>Call for help:</label>{" "}
                <a href={`tel:${websitesettings.phone_whatsapp}`}>
                  {websitesettings.phone_whatsapp}
                </a>
              </div>
              <div className="ct-topbar-item">
                {" "}
                <i className="fas fa-envelope-open"></i>{" "}
                <label>Mail to us:</label>{" "}
                <a href={`mailto:${websitesettings.contact_address}`}>
                  {websitesettings.contact_address}
                </a>
              </div>
              <div className="ct-topbar-item">
                {" "}
                <i className="fas fa-map-marker-alt"></i>{" "}
                <label>Our address:</label>{" "}
                <a
                  target="https://maps.app.goo.gl/4aFJoyEiP3Nc2Wdj7"
                  href="https://maps.app.goo.gl/4aFJoyEiP3Nc2Wdj7"
                >
                  <span>{websitesettings.address}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Topbar;
