import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import "../Pages/EditPage/editpage.css";
import { useNavigate } from "react-router-dom";

function CreatePage() {
  const [formData, setFormData] = useState({
    title: "",
    link: "",
    photo: "",
    body: "",
    meta_title: "",
    meta_desc: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 2000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 2000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const formDataObj = new FormData();
  Object.keys(formData).forEach((key) => {
    formDataObj.append(key, formData[key]);
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${ngrokUrl.url}/api/create-page`,
        formDataObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setSuccess("page added successfully!");
        setLoading(false);

        setFormData({
          title: "",
          link: "",
          photo: "",
          body: "",
          meta_title: "",
          meta_desc: "",
        });
      } else {
        throw new Error("Failed to create page");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);

      setError("Failed to create page");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        navigate("/dashboard/all-pages");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [success]);

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 2000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 2000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Create page</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6
              className="m-0 font-weight-bold text-primary"
              style={{ fontSize: "large" }}
            >
              Create page
            </h6>
          </div>
          <div className="card-body">
            <div className="row" style={{ marginBottom: "20px" }}>
              <div
                className="col-md-6  align-items-center mt-3"
                style={{ marginBottom: "15px" }}
              >
                <a className="dashboardBtn " href="/dashboard/all-pages">
                  Back to Pages
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <form onSubmit={handleSubmit}>
                  <input
                    type="hidden"
                    name="_token"
                    value="UR3fqHhYzshVBPnPFLGMIMUunLMDns7Fy4kOXtDg"
                  />
                  <input type="hidden" name="language_id" value="1" />

                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">Title</strong>
                            <input
                              type="text"
                              name="title"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">Link</strong>
                            <div className="slug-container d-flex flex-column flex-lg-row">
                              <span className="label_dimension">
                                https://niva-website.vercel.app/
                              </span>
                              <input
                                type="text"
                                name="link"
                                className="formControl input_height mobile_width w-100"
                                placeholder=""
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="formgroup">
                        <strong className="header_heady">Photo</strong>
                        <input
                          type="file"
                          name="photo"
                          className="formControl-file label_dimension"
                          id="photo_id"
                          onChange={handleChange}
                        />
                      </div>

                      <div className="formgroup">
                        <strong className="header_heady">Body</strong>
                        <textarea
                          name="body"
                          className="formControl"
                          id="body"
                          rows="5"
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">meta title</strong>
                            <input
                              type="text"
                              name="meta_title"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="formgroup">
                            <strong className="header_heady">
                              meta description
                            </strong>
                            <input
                              type="text"
                              name="meta_desc"
                              className="formControl input_height"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary cardBtn"
                        style={{ fontSize: "medium" }}
                        disabled={loading}
                      >
                        {loading ? "Submitting..." : "Create"}
                      </button>
                    </div>
                  </div>
                </form>
                {error && (
                  <div
                    className="alert alert-danger"
                    style={{ fontSize: "14px" }}
                  >
                    {error}
                  </div>
                )}
                {success && (
                  <div
                    className="alert alert-success"
                    style={{ fontSize: "14px" }}
                  >
                    {success}{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatePage;
