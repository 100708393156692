import React from "react";

function ContactSettings() {
  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Contact settings</h1>

        <div className="pb-2 text-right">
          <select
            name="language"
            className="form-control label_dimension language-control"
            onchange="window.location='https://niva4.lucian.host/admin/contact-settings?language='+this.value"
          >
            <option value="" selected disabled>
              Select a language
            </option>
            <option value="en">English</option>
            <option value="pt">Portuguese</option>
            <option value="عربى">عربى</option>
          </select>
        </div>

        <div className="row">
          <div className="col-md-12">
            {/* <!-- Contact --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark label_dimension">
                  Contact information
                </h6>
              </div>
              <div className="card-body">
                <form
                  action="https://niva4.lucian.host/admin/contact-settings/1/update"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <strong className="header_heady">
                          Box icon <i className="fas fa-phone-volume"></i>
                        </strong>
                        <input
                          type="text"
                          name="box_icon1"
                          className="form-control label_dimension"
                          value='&lt;i className="fas fa-phone-volume"&gt;&lt;/i&gt;'
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <strong className="header_heady">
                          Box icon <i className="fas fa-envelope"></i>
                        </strong>
                        <input
                          type="text"
                          name="box_icon2"
                          className="form-control label_dimension"
                          value='&lt;i className="fas fa-envelope"&gt;&lt;/i&gt;'
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <strong className="header_heady">
                          Box icon <i className="fas fa-map-marker-alt"></i>
                        </strong>
                        <input
                          type="text"
                          name="box_icon3"
                          className="form-control label_dimension"
                          value='&lt;i className="fas fa-map-marker-alt"&gt;&lt;/i&gt;'
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <strong className="header_heady">Box title</strong>
                        <input
                          type="text"
                          name="box_title1"
                          className="form-control label_dimension"
                          value="Call us today"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <strong className="header_heady">Box title</strong>
                        <input
                          type="text"
                          name="box_title2"
                          className="form-control label_dimension"
                          value="Our emails"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <strong className="header_heady">Box title</strong>
                        <input
                          type="text"
                          name="box_title3"
                          className="form-control label_dimension"
                          value="Our address"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <strong className="header_heady">
                          Box description
                        </strong>
                        <textarea
                          name="box_html1"
                          className="form-control label_dimension"
                          rows="6"
                        >
                          {/* &lt;p&gt;&lt;a
                          href=&quot;tel:+472543657456&quot;&gt;PS: +47 254 3657
                          456&lt;/a&gt; &lt;a
                          href=&quot;tel:+877390740223&quot;&gt;HO: +87 739 0740
                          223&lt;/a&gt;&lt;/p&gt; */}
                        </textarea>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <strong className="header_heady">
                          Box description
                        </strong>
                        <textarea
                          name="box_html2"
                          className="form-control label_dimension"
                          rows="6"
                        >
                          {/* &lt;p&gt;&lt;a
                          href=&quot;mailto:contact@niva.host&quot;&gt;contact@niva.host&lt;/a&gt;
                          &lt;a
                          href=&quot;mailto:office@niva.host&quot;&gt;office@niva.host&lt;/a&gt;&lt;/p&gt; */}
                        </textarea>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <strong className="header_heady">
                          Box description
                        </strong>
                        <textarea
                          name="box_html3"
                          className="form-control label_dimension"
                          rows="6"
                        >
                          {/* &lt;p&gt;&lt;a
                          href=&quot;https://goo.gl/maps/JwQdjL8S1MaJnQAv5&quot;&gt;Malet
                          St, Bloomsbury, London WC1E 7HU, United
                          Kingdom&lt;/a&gt;&lt;/p&gt; */}
                        </textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary anchor_font"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- Contact --> */}

            {/* <!-- Form information --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark label_dimension">
                  Form information
                </h6>
              </div>
              <div className="card-body">
                <form
                  action="https://niva4.lucian.host/admin/contact-settings/1/update"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="form-group">
                    <strong className="header_heady">Title form</strong>
                    <input
                      type="text"
                      name="form_title"
                      className="form-control label_dimension"
                      value="Send us a message"
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">
                          Form input text 1
                        </strong>
                        <input
                          type="text"
                          name="form_input_name"
                          className="form-control label_dimension"
                          value="Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">
                          Form input text 2
                        </strong>
                        <input
                          type="text"
                          name="form_input_email"
                          className="form-control label_dimension"
                          value="Email"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">
                          Form input text 3
                        </strong>
                        <input
                          type="text"
                          name="form_input_budget"
                          className="form-control label_dimension"
                          value="Phone"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">
                          Form input text 4
                        </strong>
                        <input
                          type="text"
                          name="form_input_phone"
                          className="form-control label_dimension"
                          value="Budget"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <strong className="header_heady">Form textarea text</strong>
                    <input
                      type="text"
                      name="form_message"
                      className="form-control label_dimension"
                      value="Message"
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Button text</strong>
                        <input
                          type="text"
                          name="button_text"
                          className="form-control label_dimension"
                          value="Submit"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Mail to @email</strong>
                        <input
                          type="text"
                          name="mailto"
                          className="form-control label_dimension"
                          value="contact@lucian.host"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary anchor_font"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- Form information --> */}

            {/* <!-- Sidebar information --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark label_dimension">
                  Map information
                </h6>
              </div>
              <div className="card-body">
                <form
                  action="https://niva4.lucian.host/admin/contact-settings/1/update"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="form-group">
                    <strong className="header_heady">Title</strong>
                    <input
                      type="text"
                      name="title"
                      className="form-control label_dimension"
                      value="Where we are"
                    />
                  </div>
                  <div className="form-group">
                    <strong className="header_heady">Map iframe</strong>
                    <textarea
                      name="iframe_txt"
                      className="form-control label_dimension"
                      rows="10"
                    >
                      {/* &lt;p&gt;&lt;iframe style=&quot;border: 0;&quot;
                      src=&quot;https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9929.880220551257!2d-0.1308206!3d51.5229378!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x35223832b9a7517a!2sUniversity%20of%20London!5e0!3m2!1sen!2sro!4v1615724797695!5m2!1sen!2sro&quot;
                      width=&quot;100%&quot; height=&quot;450&quot;
                      allowfullscreen=&quot;&quot;&gt;&lt;/iframe&gt;&lt;/p&gt; */}
                    </textarea>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary anchor_font"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- Form information --> */}

            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark label_dimension">
                  Section Clients
                </h6>
              </div>
              <div className="card-body">
                <a
                  className="btn btn-primary anchor_font mr-3"
                  href="https://niva4.lucian.host/admin/client?language="
                >
                  View All
                </a>
                <a
                  className="btn btn-primary anchor_font"
                  href="https://niva4.lucian.host/admin/client/create?language="
                >
                  Create
                </a>
              </div>
            </div>

            {/* <!-- SEO --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-dark label_dimension">
                  SEO
                </h6>
              </div>
              <div className="card-body">
                <form
                  action="https://niva4.lucian.host/admin/contact-settings/1/update"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="K7NHdVw50X49KU9TrR5qKXbAU29AU1tRnBWLIhHU"
                  />{" "}
                  <input type="hidden" name="_method" value="PUT" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">meta title</strong>
                        <input
                          type="text"
                          name="meta_title"
                          className="form-control label_dimension"
                          value="Contact us"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">
                          meta description
                        </strong>
                        <input
                          type="text"
                          name="meta_description"
                          className="form-control label_dimension"
                          value="Our Contact page"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Slug</strong>
                        <input
                          type="text"
                          name="slug"
                          className="form-control label_dimension"
                          value="contact"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <strong className="header_heady">Anchor text</strong>
                        <input
                          type="text"
                          name="breadcrumbs_anchor"
                          className="form-control label_dimension"
                          value="Home"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary anchor_font"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- SEO --> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactSettings;
