import React, { useState, useEffect } from "react";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

function OurProjects() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const navigate = useNavigate();
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div class="breadcrumb-area">
            <h1 class="breadcrumb-title">Our Portfolio</h1>
            <ul class="page-list">
              <li class="item-home">
                <a
                  class="bread-link"
                  onClick={() => navigate("/")}
                  title="Home"
                >
                  Home
                </a>
              </li>
              <li class="separator separator-home"></li>
              <li class="item-current">Our Portfolio</li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
export default OurProjects;
