import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import "../Pages/EditPage/editpage.css";

const AllPosts = () => {
  const [postData, setPostData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 3000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 3000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  useEffect(() => {
    const postValues = async () => {
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readAllPosts`);

        setPostData(response.data);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
      }
    };

    postValues();
  }, []);

  const tableData = Array.isArray(postData) ? postData : [];

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.delete(
        `${ngrokUrl.url}/api/deleteAllPosts/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      setSuccess("Projects deleted successfully.");
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete projects.", error);
      setError("Failed to delete projects.");
    }
  };

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Section - Blog</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              Section - Blog
            </h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="row align-items-center mb-3">
                <div className="col-lg-6 d-flex ">
                  <Link to="/dashboard/homepage" className="btn-back cardBtn">
                    Back to Home page
                  </Link>
                  <Link
                    to="/dashboard/blogSetting"
                    className="btn-back cardBtn ml-3"
                  >
                    Back to Blog page
                  </Link>
                  <Link
                    to="/dashboard/createPost"
                    className="btn-back cardBtn ml-3"
                  >
                    Create article
                  </Link>
                </div>
              </div>

              <form method="POST" className="form-inline">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th scope="col" className="header_heady">
                        ID
                      </th>
                      <th scope="col" className="header_heady">
                        Photo
                      </th>
                      <th scope="col" className="header_heady">
                        Owner
                      </th>
                      <th scope="col" className="header_heady">
                        Title
                      </th>
                      <th scope="col" className="header_heady">
                        Category
                      </th>
                      <th scope="col" className="header_heady">
                        Body
                      </th>
                      <th scope="col" className="header_heady" colSpan={2}>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th scope="col" className="header_heady">
                        ID
                      </th>
                      <th scope="col" className="header_heady">
                        Photo
                      </th>
                      <th scope="col" className="header_heady">
                        Owner
                      </th>
                      <th scope="col" className="header_heady">
                        Title
                      </th>
                      <th scope="col" className="header_heady">
                        Category
                      </th>
                      <th scope="col" className="header_heady">
                        Body
                      </th>
                      <th scope="col" className="header_heady" colSpan={2}>
                        Actions
                      </th>
                    </tr>
                  </tfoot>
                  <tbody>
                    {tableData.map((item) => (
                      <tr key={item.id}>
                        <td data-label="ID" className="label_dimension">
                          {item.id}
                        </td>
                        <td data-label="Photo">
                          <img height="50" src={item.photo_url} alt="" />
                        </td>
                        <td data-label="Owner" className="label_dimension">
                          {item.owner}
                        </td>
                        <td data-label="Title" className="label_dimension">
                          {item.title}
                        </td>
                        <td data-label="Category" className="label_dimension">
                          {item.categories}
                        </td>
                        <td data-label="Body" className="body-project">
                          <p className="label_dimension">{item.body}</p>
                        </td>
                        <td className="body-url" data-label="BODY">
                          <Link
                            to={`/dashboard/editPost/${item.id}/edit?language=en`}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Link>

                          <FontAwesomeIcon
                            icon={faTrash}
                            className="action-button"
                            onClick={() => handleDelete(item.id)}
                            style={{ marginLeft: "25px" }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {error && (
                  <div
                    className="alert alert-danger"
                    style={{ fontSize: "14px" }}
                  >
                    {error}
                  </div>
                )}
                {success && (
                  <div
                    className="alert alert-success"
                    style={{ fontSize: "14px" }}
                  >
                    {success}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllPosts;
