import React, { useState, useEffect } from "react";
import Footer from "../Home/Footer/Footer";
import NavBar from "../../Utility/NavBar";
import Whatsapp from "../TopupButtons/Whatsapp";
import Topbar from "../../Topbar/Topbar";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import Loader from "../Loader/Loader";

function PrivacyPolicy() {
  const [page, setPage] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDefaultValues = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/show`);
        setPage(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };
    fetchDefaultValues();
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : (
        <div>
          <Topbar />
          <Whatsapp />
          <NavBar />
          <div class="breadcrumb-area">
            <h1 class="breadcrumb-title">{page.title}</h1>
          </div>

          <div class="page-content privacy_policy">
            <div class="container">
              <p>{page.body}</p>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}
export default PrivacyPolicy;
