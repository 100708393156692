import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

function ManageTestimonials() {
  const [testimonials, setTestimonials] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    let errorTimeout, successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => setError(null), 2000);
    }

    if (success) {
      successTimeout = setTimeout(() => setSuccess(null), 2000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  useEffect(() => {
    const testimonialValues = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${ngrokUrl.url}/api/readTestimonial`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        setTestimonials(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    testimonialValues();
  }, []);

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      await axios.delete(`${ngrokUrl.url}/api/deleteTestimonial/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      });

      const response = await axios.get(`${ngrokUrl.url}/api/readTestimonial`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      });
      setTestimonials(response.data);
      setSuccess("Item deleted successfully.");
      setLoading(false);
    } catch (error) {
      console.error("Failed to delete item.", error);
      setError("Failed to delete item.");
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">All testimonials</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary anchor_font">
              All testimonials
            </h6>
          </div>
          <div className="card-body mt-3">
            <div className="table-responsive">
              <div className="row align-items-center mb-3">
                <div className="col-lg-6">
                  <Link
                    to="/dashboard/customtemplate/1/edit"
                    className="dashboardBtn"
                  >
                    Back to Home page
                  </Link>
                  <Link
                    to="/dashboard/createTestimonials"
                    className="dashboardBtn ml-4"
                  >
                    Create testimonial
                  </Link>
                </div>
              </div>

              <form
                action="https://niva4.lucian.host/delete/testimonial"
                method="POST"
                className="form-inline"
              >
                <input
                  type="hidden"
                  name="_token"
                  value="9kr4A0f0neruHdqd4e2UADuzDOw4VyxcaJSUzoO3"
                />{" "}
                {/* <div className="form-group">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary "
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        backgroundColor: "white",
                        color: "grey",
                        fontSize: "medium",
                      }}
                    >
                      Delete
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={handleDelete}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/* <div className="form-group">
                  <input
                    type="submit"
                    name="delete_all"
                    className="btn btn-primary anchor_font"
                  />
                </div> */}
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th className="header_heady">Name</th>
                      <th className="header_heady">Position</th>
                      <th className="header_heady">Subtitle</th>
                      <th className="header_heady">Title</th>
                      <th className="header_heady">Description</th>
                      <th scope="col" className="header_heady" colSpan={2}>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th className="header_heady">Name</th>
                      <th className="header_heady">Position</th>
                      <th className="header_heady">Subtitle</th>
                      <th className="header_heady">Title</th>
                      <th className="header_heady">Description</th>
                      <th scope="col" className="header_heady" colSpan={2}>
                        Actions
                      </th>
                    </tr>
                  </tfoot>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="3">Loading...</td>
                      </tr>
                    ) : error ? (
                      <tr>
                        <td colSpan="3">Error: {error}</td>
                      </tr>
                    ) : testimonials.length === 0 ? (
                      <tr>
                        <td colSpan="3">No data found.</td>
                      </tr>
                    ) : (
                      Array.isArray(testimonials) &&
                      testimonials.map((testimonial) => (
                        <tr key={testimonial.id}>
                          <td data-label="name" className="label_dimension">
                            {testimonial.name}
                          </td>
                          <td data-label="position" className="label_dimension">
                            {testimonial.position}
                          </td>
                          <td data-label="subtitle" className="label_dimension">
                            {testimonial.subtitle}
                          </td>
                          <td data-label="title" className="label_dimension">
                            {testimonial.title}
                          </td>
                          <td data-label="desc" className="label_dimension">
                            {testimonial.desc}
                          </td>

                          <td className="body-url" data-label="BODY">
                            <Link
                              to={`/dashboard/editTestimonials/${testimonial.id}/edit?language=en`}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Link>

                            <FontAwesomeIcon
                              icon={faTrash}
                              className="action-button"
                              onClick={() => handleDelete(testimonial.id)}
                              style={{ marginLeft: "25px" }}
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                {error && (
                  <div style={{ color: "red", fontSize: "14px" }}>{error}</div>
                )}
                {success && (
                  <div style={{ color: "green", fontSize: "14px" }}>
                    {success}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageTestimonials;
