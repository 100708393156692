import React, { useState, useEffect } from "react";
import "./Funfacts.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";
import Loader from "../../Loader/Loader";

function FunFacts() {
  const [funfacts, setFunFacts] = useState({});
  const [errorfun, setErrorfun] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFunFacts = async () => {
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readFunFacts/1`);
        setFunFacts(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorfun("Failed to fetch default values.");
        setLoading(false);
      }
    };

    fetchFunFacts();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : errorfun ? (
        <div className="error">{errorfun}</div>
      ) : (
        <div className="fun-facts-section" id="fun-facts">
          <div className="container fun-facts">
            <h3 className="fun-facts-heading1">{funfacts.title}</h3>
            <p>{funfacts.description}</p>

            <div className="row fun-facts-timer">
              <div className="col-md-3">
                <div className="radial">
                  <span className="timer">
                    <VisibilitySensor partialVisibility>
                      {({ isVisible }) => (
                        <div style={{ height: 50 }}>
                          {isVisible ? (
                            <CountUp
                              end={parseFloat(funfacts.count_num_one || 0)}
                              suffix={
                                funfacts.count_num_one &&
                                /[a-zA-Z+]/.test(funfacts.count_num_one)
                                  ? "+"
                                  : ""
                              }
                            />
                          ) : null}
                        </div>
                      )}
                    </VisibilitySensor>
                  </span>
                  <h4>{funfacts.count_text_one}</h4>
                </div>
              </div>
              <div className="col-md-3">
                <div className="radial">
                  <span className="timer">
                    <VisibilitySensor partialVisibility>
                      {({ isVisible }) => (
                        <div style={{ height: 50 }}>
                          {isVisible ? (
                            <CountUp
                              end={parseFloat(funfacts.count_num_two || 0)}
                              suffix={
                                funfacts.count_num_two &&
                                /[a-zA-Z+]/.test(funfacts.count_num_two)
                                  ? "+"
                                  : ""
                              }
                            />
                          ) : null}
                        </div>
                      )}
                    </VisibilitySensor>
                  </span>
                  <h4>{funfacts.count_text_two}</h4>
                </div>
              </div>
              <div className="col-md-3">
                <div className="radial">
                  <span className="timer">
                    <VisibilitySensor partialVisibility>
                      {({ isVisible }) => (
                        <div style={{ height: 50 }}>
                          {isVisible ? (
                            <CountUp
                              end={parseFloat(funfacts.count_num_three || 0)}
                              suffix={
                                funfacts.count_num_three &&
                                /[a-zA-Z+]/.test(funfacts.count_num_three)
                                  ? "+"
                                  : ""
                              }
                            />
                          ) : null}
                        </div>
                      )}
                    </VisibilitySensor>
                  </span>
                  <h4>{funfacts.count_text_three}</h4>
                </div>
              </div>
              <div className="col-md-3">
                <div className="radial">
                  <span className="timer">
                    <VisibilitySensor partialVisibility>
                      {({ isVisible }) => (
                        <div style={{ height: 50 }}>
                          {isVisible ? (
                            <CountUp
                              end={parseFloat(funfacts.count_num_four || 0)}
                              suffix={
                                funfacts.count_num_four &&
                                /[a-zA-Z+]/.test(funfacts.count_num_four)
                                  ? "+"
                                  : ""
                              }
                            />
                          ) : null}
                        </div>
                      )}
                    </VisibilitySensor>
                  </span>
                  <h4>{funfacts.count_text_four}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FunFacts;
